<template>
    <el-drawer custom-class="tasks-details-view" size="100%" :with-header="false" :destroy-on-close="true" v-model="drawer"
    :close-on-press-escape="false" @closed="afterFormClosed">
            <div class="tview-ctr flex--row">
                <transition name="slide" mode="out-in">

                    <div v-if="leftPanel" class="task-nav-bar txt14">
                        <div class="flex--row flex--ac hdr">
                            <av-task-cv-popover @OpenTasks="getOpenStatusTasks" @AllTasks="getAllTasks"></av-task-cv-popover>
                            <av-icon class="cp icon mlA" name="hide-menu-24" @click="leftPanel = false"></av-icon>
                        </div>
                        <av-tasks-preview-list :tasks="tasks" :selected-task-id="selectedTaskObj.taskId" :isTasks="true"></av-tasks-preview-list>
                    </div>

                    <div class="hide-bar" v-else style="width : 3%; background: rgb(233 233 233 / 62%)">
                        <div>
                            <av-icon @click="leftPanel = true" class="cp icon mlA pST " name="hide-menu-24"></av-icon>
                        </div>
                    </div>

                </transition>
                <!-- Middle -->
                <transition name="fade-router" mode="out-in">
                    <div v-if="selectedTaskObj.taskId" :v-model="selectedTaskObj" class="tview-bdy flex--col flex--one-oflw" :key="selectedTaskObj.taskId">
                        <div class="task-body flex--row flex--one-oflw">
                            <div class="task-body-head flex--one-oflw flex--col">
                                <div class="task-ttl">
                                    <div contenteditable ref="taskTitleText" class="title-text" @blur="updateTaskTitle"> {{ selectedTaskObj['taskTitle'] }}</div>
                                </div>
                                <div class="left-pnl flex--one-oflw">
                                    <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">Description</span>
                                        <span @click="taskdesc = true" class="desc-edit cp"></span>
                                    </div>
                                    <template v-if="taskdesc">
                                        <div class="desc-cont">
                                            <av-rich-text @getQuillEditor = setAttachmentInEditor :task-desc="selectedTaskObj.description" ref="richTextEditor"></av-rich-text>
                                            <div class="attach-file-list" v-if="selectedFile && selectedFile.length > 0">
                                                <template v-for="file in selectedFile" :key="file.name">
                                                    <div class="file-list-name">
                                                        <span>{{ file.name }}</span>
                                                        <span class="cp" @click="removeFile(file)">x</span>
                                                    </div>
                                                </template>
                                                <!-- <div v-if="file.type.startsWith('image/')">
                                                    <img :src="fileUrl(file)" />
                                                </div>
                                                <a v-else :href="fileUrl(file)" target="_blank">{{ file.name }}</a> -->
                                            </div>
                                        </div>
                                        <div class="desc-confirm">
                                            <el-button type="primary" @click="updateDesc(selectedTaskObj.taskId)">Save</el-button>
                                            <el-button @click="cancelDesc">Cancel</el-button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="selectedTaskObj.description && selectedTaskObj.description.length > 0">
                                             <el-skeleton v-if="loadingDescription" style="width: 75%" animated>
                                                <template #template>
                                                    <div style="padding: 14px">
                                                        <el-skeleton-item variant="p" style="width: 50%" />
                                                        <div>
                                                            <el-skeleton-item variant="text" style="margin-right: 16px" />
                                                            <el-skeleton-item variant="text" style="width: 30%" />
                                                        </div>
                                                        <el-skeleton-item variant="text" style="width: 70%" />
                                                        <el-skeleton-item variant="text" style="width: 50%" />
                                                    </div>
                                                </template>
                                            </el-skeleton>
                                            <span v-else class="descHtml" v-html="selectedTaskObj.description"></span>
                                        </template>
                                    </template>

                                    <el-dialog :fullscreen="true" custom-class="desc-img-preview" v-model="imagePreviewInDesc" @close="closePreviewDial" title="">
                                        <div class="preview-header flex--row flex--ac flex--sbw">
                                            <a :href="previewImageSrcInDesc" download>Download Image</a>
                                            <span class="cp" @click="closePreviewDial">X</span>
                                        </div>
                                        <div :style="[ zoomIn ? { 'width': '100%' } : { 'width': '70%' }]" class="pre-img-cont">
                                            <img @click="zoomIn = !zoomIn" :style="[ zoomIn ? { 'cursor': 'zoom-out' } : { 'cursor': 'zoom-in' }]" :src= previewImageSrcInDesc>
                                        </div>
                                    </el-dialog>
                                    
                                    <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">{{ $t("av.tasks.details.tag") }} {{selectedTaskObj.taskTags.length ? `(${selectedTaskObj.taskTags.length})` : ''}}</span>
                                        <av-icon class="cp icon medium" name="add-plus-24" @click="openTagPopup"></av-icon>
                                    </div>
                                    <div v-if="selectedTaskObj.taskTags && selectedTaskObj.taskTags.length > 0" class="flex--row flex--ac tag-chips-hldr">
                                        <el-tag  class="tag-chip" v-for="tag in selectedTaskObj.taskTags" :key="tag.taskTagId" round effect="dark" size="small" :style="{ backgroundColor: tag.color }">
                                            {{tag.tagName}}
                                        </el-tag>
                                    </div>
                                        <el-dialog custom-class="tag-dialogue" v-model="tagdialogue" title="Tags" width="30%" :close-on-click-modal="false">
                                            <div class="uxTagBox">
                                                <el-tag class="tag-chip"
                                                    v-for="tag in existsTaskTag" :key="tag" @close="closeTag(tag)" round effect="dark" closable size="small" >
                                                    {{tag.tagName}}
                                                </el-tag>
                                                <el-select ref="tagInput" no-data-text=" " v-model="tagTitle" @change="createNewTag" multiple filterable allow-create
                                                    default-first-option
                                                    :reserve-keyword="false"
                                                    placeholder="Add or Choose tags for your task">

                                                    <el-option v-for="tag in filteredTags" :key="tag.taskTagId" :label="tag.tagName" :value="tag.taskTagId"/>
                                                </el-select>
                                            </div>
                                            <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="tagdialogue = false">Cancel</el-button>
                                                <el-button type="primary" @click="updateUXTags">Save</el-button>
                                            </span>
                                            </template>
                                        </el-dialog>

                                    <div class="attach-tab sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">{{ $t("av.tasks.details.attachments") }} {{taskAttachments.length ? `(${taskAttachments.length})` : ''}}</span>
                                            <label class="attach-upload-label" for="file-input">
                                                <av-icon class="cp icon medium" name="add-plus-24"></av-icon>
                                            </label>
                                            <input class="attach-upload-input" id="file-input" type="file" name="Test" multiple @input="attachmentUpload"/>
                                    </div>
                                    <div class="attachInp txt14 font-med flex--row flex--ac flex--wrap">
                                        <template v-if="taskAttachments && taskAttachments.length > 0"  >
                                            <div class="attachInp-cont" v-for="attachment in taskAttachments" :key="attachment.attachmentId">
                                                    <template v-if="attachment.createdBy.user.profilePicURL === null" >
                                                        <span :style="{ 'background-color': attachment.createdBy.user.profilePicColor, 'color': checkColorluminance(attachment.createdBy.user.profilePicColor) }"
                                                                class = "profile-pic-attach flex--row flex--acjc cp">{{ currentAuthUser.firstName.charAt(0).toUpperCase() }}</span>
                                                    </template>
                                                    
                                                    <template v-else>
                                                        <img v-if="attachment.createdBy.user.provider !== 'local' && attachment.createdBy.user.profilePicName === null " class="profile-pic-attach cp" :src='attachment.createdBy.user.profilePicURL'/>
                                                        <img v-else class="profile-pic-attach" :src='origin + "/profilePic/" +  attachment.createdBy.user.profilePicURL'/>
                                                    </template>

                                                <div class="attachInp-data">
                                                    <span>{{ attachment.attachmentName }}</span>
                                                    <div class="flex--row">
                                                        <span>{{ bytesForAttachSize(attachment.size) }}.</span>
                                                        <span>{{ moment(attachment.createdTime).format('ll') }}</span>
                                                    </div>
                                                </div>
                                                <div class="attch-opt">
                                                    <img class="cp" src="../assets/delete.png" @click="deleteAttachment(attachment.attachmentId)"/>
                                                    <a @click="downloadAttachment(attachment.storedName)" :href='attachmentURL' target="_parent">
                                                        <img src="../assets/download.png"/>
                                                    </a>
                                                 </div>
                                               
                                            </div>
                                        </template>
                                    </div>
                                    <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">{{ $t("av.tasks.details.checklist") }} {{checklists.length ? `(${checklists.length})` : ''}}</span>
                                        <av-icon class="cp icon medium" name="add-plus-24" @click="addcheckListInput()"></av-icon>
                                    </div>
                                        <div class="flex--col" >
                                                <div class="checklist-input" v-if="checkListInput" >
                                                    <!-- <el-form :rules="addTaskFormRules" :model="checklistForm"> -->
                                                        <!-- <el-form-item prop="inlineAddChecklist" > -->
                                                            <el-input size="mini" @blur="outOfchecklistInput()"  :placeholder=" $t('av.taskscreation.checklistadd.placeholder.name')"
                                                                ref="AddChecklist"  v-model="inlineAddChecklist"
                                                                @keydown.enter="addChecklist"></el-input>
                                                            <span v-if="checklistRules">Length should be minimum 3 characters</span>
                                                        <!-- </el-form-item> -->
                                                    <!-- </el-form> -->
                                                </div>
                                                <!-- <el-input size="mini"  v-if="checkListInput" :placeholder=" $t('av.taskscreation.checklistadd.placeholder.name')"
                                                        ref="AddChecklist" @blur="outOfchecklistInput()" v-model="inlineAddChecklist"
                                                        @keydown.enter="addChecklist"
                                                    ></el-input> -->
                                                <div class="checklist-div" v-for="(checklist, index) in checklists" :key="index" @mouseleave="deleteChecklist = null"
                                                    @mouseover="deleteChecklist = index" >
                                                    <div class="check-box">
                                                        <input :checked ="checklist.completed == true"  type="checkbox" class="checkbox" @click="checked(index)" :v-model="'isChecklistTicked' + index" />

                                                        <div class="checklist-editable" v-if="editChecklist === index">
                                                            <el-input class="chec-edt-inp" type="textarea" :ref="'checkListText' + index" :autosize="{ minRows: 2, maxRows: 15 }" resize="none" v-model="checklist.checkListName" />
                                                            <el-button type="primary" size="small"  @click="updateChecklistText(index)"><i class="fa fa-check" aria-hidden="true"></i></el-button>
                                                            <el-button size="small" @click="editChecklist = false"><i class="fa fa-times" aria-hidden="true" ></i></el-button>
                                                        </div>
                                
                                                        <span v-else :class="[checklist.completed == true ? 'line' : '']"
                                                            class="checklistLabel clip-txt"  @click="checklistTitleEdit(index)"
                                                            >{{ checklist.checkListName }}
                                                        </span>
                                                    </div>
                                                    <!-- <div class="check-box-img">
                                                        <img class="checklist-pic" src="http://loremflickr.com/100/50?img=2222"
                                                        @click="checkBox(index)"
                                                        />
                                                    </div> -->
                                                    <div>
                                                        <img src="../assets/delete.png"  v-if="deleteChecklist === index" @click="delChecklist(checklist.checkListId)"
                                                        />
                                                    </div>
                                                
                                                    <div>
                                                        <el-dialog v-if="delcheckVisible === checklist.checkListId" v-model="delcheckVisible" title="Delete Checklist"
                                                            width="30%" :close-on-press-escape="false">
                                                            <span>Are you sure you want to delete this checklist?</span>
                                                            <template #footer>
                                                                <span class="dialog-footer">
                                                                <el-button  @click="cancelChecklist(index)">Cancel</el-button>
                                                                <el-button type="primary"  @click="checklistDelete(index)"
                                                                    >Confirm</el-button>
                                                                </span>
                                                            </template>
                                                        </el-dialog>
                                                    </div>
                                                </div>
                                        </div>

                                    <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">{{ $t("av.tasks.details.subtasks") }} {{subTasks.length ? `(${subTasks.length})` : ''}}</span>
                                        <av-icon class="cp icon medium" name="add-plus-24" @click="addsubTaskInput()" ></av-icon>
                                    </div>
                                    <el-input size="mini" :placeholder="$t('av.tasks.inlineaddsubtask.placeholder.name')" ref = "addsubTask"
                                        @blur="outOfsubtaskInput()" v-if="subTaskInput" v-model="inlineAddsubTask"
                                            @keydown.enter="addsubTask" >
                                    </el-input>
                                    <av-subtasks-preview-list :subtasks="subTasks"  :selected-parent-task="selectedTaskObj" >
                                    </av-subtasks-preview-list>

                                    <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                        <span class="lbl">{{ $t("av.tasks.details.comments") }} {{comments.length ? `(${comments.length})` : ''}}</span>
                                    </div>
                            
                                        <div class="flex--row" >
                                            <div class="flex--col commentSection">
                                                <div class="comment-chat-box flex--col">
                                                    <div class="flex--row comment-div" v-for="(comment, index) in comments" :key="index" >
                                                        <template v-if="comment.createdUser.user.profilePicURL === null" >
                                                                <span :style="{ 'background-color': comment.createdUser.user.profilePicColor, 'color' : checkColorluminance(comment.createdUser.user.profilePicColor) }"
                                                                    class = "profile-pic-comments flex--row flex--acjc cp">{{ comment.createdUser.user.firstName.charAt(0).toUpperCase() }}</span>
                                                        </template>
                                                        <template v-else>
                                                            <img v-if="comment.createdUser.user.provider !== 'local' && comment.createdUser.user.profilePicName === null " class="profile-pic-comments cp" :src='comment.createdUser.user.profilePicURL'/>
                                                            <img v-else class="profile-pic-comments" :src='origin + "/profilePic/" +  comment.createdUser.user.profilePicURL'/>
                                                        </template>

                                                        <div class="flex--col commentText">
                                                            <div class="flex--row flex--ac flex--jst">
                                                                <span class="comment-title">{{ comment.createdUser.user.firstName }}{{ comment.createdUser.user.lastName }}
                                                                </span>
                                                                <el-dropdown :show-arrow="false" class="cmt-time-val">
                                                                    <span class="el-dropdown-link">
                                                                        <span class="cmt-timeline" :ref='"commentTime" + index' >{{ this.time[this.comments[index].taskCommentId]  }}</span>
                                                                    </span>
                                                                    <template #dropdown>
                                                                        <el-dropdown-menu class="timeStamp-menu">
                                                                            <el-dropdown-item>{{ moment(comment.createdTime).format("MMM DD YYYY, h:mm a") }}</el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </template>
                                                                </el-dropdown>
                                                            </div>
                                                            <textarea  type="text" class="cmt-edit-input" :ref="'commentSpan' + index" v-if="editMode === index"
                                                                v-model="comment.comment"
                                                            ></textarea>
                                                            <span v-else class="comment-comments"> {{ comment.comment }}</span>
                                                            <div class="crud-tab flex--row flex--ac">
                                                                <template v-if="currentAuthUser.userId === comment.createdUser.user.userId">
                                                                    <el-button class="comment-btns" v-if="visible == index"  size="small" type="text" @click="updateComment(index)"
                                                                    >save</el-button >

                                                                    <el-button v-if="visible == index" class="comment-btns" @click="cancelComment" size="small" type="text"
                                                                    >cancel</el-button>

                                                                    <el-button v-else class="comment-btns"  size="small" type="text" @click="editComment(index)"
                                                                    >edit</el-button>

                                                                    <el-button class="comment-btns" size="small" type="text" @click="delPopup(comment.taskCommentId)">delete</el-button>
                                                                </template>
                                                                    <!-- <el-dropdown :show-arrow="false">
                                                                        <span class="el-dropdown-link">
                                                                            <span class="cmt-timeline" :ref='"commentTime" + index' >{{ this.time[this.comments[index].taskCommentId]  }}</span>
                                                                        </span>
                                                                        <template #dropdown>
                                                                            <el-dropdown-menu class="timeStamp-menu">
                                                                                <el-dropdown-item>{{ moment(comment.createdTime).format("MMM DD YYYY, h:mm a") }}</el-dropdown-item>
                                                                            </el-dropdown-menu>
                                                                        </template>
                                                                    </el-dropdown> -->

                                                                    <el-dialog v-if="dialogVisible === comment.taskCommentId" v-model="dialogVisible" title="Delete Comment"
                                                                    width="30%">
                                                                        <span>Are you sure you want to delete this comment?</span>
                                                                        <template #footer>
                                                                            <span class="dialog-footer">
                                                                                <el-button @click="dialogVisible = false">Cancel</el-button>
                                                                                <el-button  type="primary" @click="deleteComment(index)"
                                                                                    >Confirm</el-button
                                                                                >
                                                                            </span>
                                                                        </template>
                                                                    </el-dialog>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="add-commt-tab flex--row flex--ac" >
                                                    <div>
                                                        <template v-if="currentAuthUser.profilePicURL === null" >
                                                                <span :style="{ 'background-color': currentAuthUser.profilePicColor, 'color' : checkColorluminance(currentAuthUser.profilePicColor) }"
                                                                    class = "profile-pic-comments flex--row flex--acjc cp">{{ currentAuthUser.firstName.charAt(0).toUpperCase() }}
                                                                </span>
                                                        </template>
                                                        <template v-else>
                                                            <img v-if="currentAuthUser.provider !== 'local' && currentAuthUser.profilePicName === null " class="profile-pic-comments cp" :src='currentAuthUser.profilePicURL'/>
                                                            <img v-else class="profile-pic-comments" :src='origin + "/profilePic/" +  currentAuthUser.profilePicURL'/>
                                                        </template>
                                                    </div>
                                                    <el-input class="commentText" type="text" size = "mini" :placeholder="$t('av.tasks.inlineaddcomment.placeholder.name')"
                                                        v-model="inlineAddComment"
                                                        @keydown.enter="addComment"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="right-panel" >
                                <div class="popup-div flex--row">
                                    <!-- <div class="date-div" >
                                        <el-date-picker  format="DD MMM" @change="dueDate"  v-model ="inlineDuedate" placeholder="select dueDate" type="date" ></el-date-picker>
                                    </div> -->
                                    
                                     <div class="priority-popup">
                                        <div class="flex--row flex--ac">
                                            <el-dropdown trigger="click">
                                                <div v-if ="selectedTaskObj.priority === null" class="dropdown-selfdefine cp flex--ac flex--row">
                                                    <span class="txt14 lbl"> <img class="flag" src="../icons/flag.png" />None</span>
                                                </div>
                                                <div v-else class="dropdown-selfdefine cp flex--ac flex--row">
                                                    <span class="txt14"> <img class="flag" src="../icons/flag.png" />{{ selectedTaskObj.priority.priorityLabel }}</span>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item class="flex--row flex--ac txt14 cp" v-for="(priority, index) in priorities" :key="index">
                                                            <div :ref="'priority' + index" @click="setPriority(priority.taskPriorityId)"> <img class="flag" src="../icons/flag.png" />{{ priority['priorityLabel'] }}</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </div>
                                    </div>
                                    <div class="flex--row">
                                        <div class="status-popup">
                                            <div class="flex--row flex--ac">
                                                <el-dropdown trigger="click">
                                                    <div class=" dropdown-selfdefine cp flex--ac flex--row">
                                                        <span class="status-icon dIB mr" :style="{ backgroundColor: selectedTaskObj.status.color }"></span>
                                                        <span class="txt14">{{ selectedTaskObj.status.statusName }}</span>
                                                        <!-- <div class="rght-arrow"><img src="../icons/right-arrow.png" /></div> -->
                                                    </div>
                                                    <template #dropdown>
                                                        <el-dropdown-menu>
                                                            <el-dropdown-item class="flex--row flex--ac txt14 cp" v-for="(status, index) in statuses" :key="index">
                                                                <span class="status-icon dIB mr" :style="{ backgroundColor: status.color }"></span>
                                                                <span :ref ="'status'+index" @click="setStatus(status.taskStatusId)">{{ status.statusName }}</span>
                                                            </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </template>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                        <div class="rght-arrow"><img src="../icons/right-arrow.png" /></div>
                                    </div>

                                    <span><av-icon class="cp rpnl-itms rpnl-icon regular" name="more-horizontal"></av-icon></span>
                                    <div class="close-btn">
                                        <button  @click ="closeTasks">X</button>
                                    </div>
                                </div>
                                <div class="right-panel-details">
                                <av-task-details-rightpanel :isTasks="true" :task-obj="selectedTaskObj" @taskStartDate="startdate" @taskDueDate="dueDate" @updateTaskCollab="updateCollaborator" @updateTaskFollower="updateFollowers"></av-task-details-rightpanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
    </el-drawer>
    
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { ElMessage } from 'element-plus';

const { mapState } = createNamespacedHelpers('tasks');
const taskTagsState = createNamespacedHelpers('tasktags');
const orgState = createNamespacedHelpers('organizations');
const usersState = createNamespacedHelpers('users');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const statusesState = createNamespacedHelpers('taskstatuses');

import moment from 'moment';
import avIcon from '@/components/av-icon.vue';
import avTaskCVPopover from '@/components/av-task-cv-popover.vue';
import AvTasksPreviewList from '@/components/av-tasks-preview-list.vue';
import AvTasksDetailsRightPanel from '@/components/av-task-details-rightpanel.vue';
import AvSubTasksPreviewList from '@/components/av-subtask-preview-list.vue';
import avRichTextEditor from '@/components/av-richtexteditor.vue';
// import { io } from "socket.io-client";
// import SockJS from "sockjs-client";
// import Stomp from "webstomp-client";

export default {
	components : {
        // Quill,
		'av-icon' : avIcon,
		'av-task-cv-popover' : avTaskCVPopover,
		'av-tasks-preview-list' : AvTasksPreviewList,
        'av-subtasks-preview-list' : AvSubTasksPreviewList,
		'av-task-details-rightpanel' : AvTasksDetailsRightPanel,
        'av-rich-text' : avRichTextEditor
	},

	data: function () {
		return {
            // colorList = ['#001f3f', '#0074D9', '#7FDBFF', '#39CCCC', '#3D9970', '#2ECC40', '#01FF70', '#FFDC00', '#FF851B', '#FF4136', '#85144b', '#F012BE', '#B10DC9', '#111111', '#AAAAAA', '#DDDDDD', '#FFFFFF'],
            origin : window.location.origin,
            loadingDescription : false,
            imagePreviewInDesc : false,
            previewImageSrcInDesc : '',
            componentKey: 0,
            leftPanel : true,
            drawer : true,
            editimg : false,
            taskdesc : false,
			selectedTaskObj : {
                description:""
            },
			currentSubTasks : {},
            commentsObj : {},
            checkListInput: false,
            editChecklist : null,
            isChecklistPresent: false,
            checkListObj: {},
            deleteChecklist: null,
            action: null,
            select: null,
            delcheckVisible: null,
            subTaskInput: false,
            inlineAddsubTask: "",
            subtaskObj: {},
            editMode: null,
            visible: null,
            commentObj: {},
            dialogVisible: null,
            inlineAddComment: "",
            inlineDuedate:'',
            checklistLength: false,
            inlineAddChecklist: '',
            checklistRules : false,
            taginputVisible : false,
            tagdialogue : false,
            tagInputValue : '',
            taskTag : {},
            tagTabHeight : '',
            taskTagName: [],
            tagName : [],
            duplicateTag : '',
            existsTaskTag : [],
            statusQryParams: {},
            tagTitle: [],
            demo:'',
            time:{},
            taskAttach : {},
            attachmentURL : '',
            newTagObj : {},
            newTags : [],
            connection: null,
            socket : null,
            stompClient : null,
            messages : [],
            selectedFile : [],
            setPreUrl : '',
            zoomIn : false
		};
	},

	mounted(){

        // 	$$avQS('.el-drawer.tasks-details-view > .el-drawer__header').remove();
        // $$avQS('.el-date-picker.date-picker > .el-input >  .el-input__inner').remove();
        // const socket = new WebSocket(`ws://${window.location.host}/websocket`);
        // console.log(`ws://${window.location.host}/websocket`);
        // socket.addEventListener('open', (event) => {
        //     console.log('WebSocket connection established', event);
        // });

        // socket.addEventListener('message', (event) => {
        //     console.log(`Received message: ${event.data}`);
        // });

        // socket.addEventListener('close', (event) => {
        //     console.log('WebSocket connection closed', event);
        // });
	},

	created() {
		this.$watch(
			() => this.$route.params,
			(toParams /*, previousParams */) => {
                if(toParams.taskId){
                    this.getSelectedTaskObj(toParams.taskId);
                }
			}
		);
		this.getSelectedTaskObj(this.$route.params.taskId);
        this.moment = moment;
        //  console.log(moment.tz.guess());

	},

    computed: {
		...mapState([
			'tasks',
            'subTasks',
            'checklists',
            'comments',
            'taskAttachments'
		]),
        ...taskTagsState.mapState([
			'taskTags'
		]),
        ...orgState.mapState([
			'currentOrg'
		]),
		...usersState.mapState([
			'currentAuthUser'
		]),
        ...prioritiesState.mapState([
			'priorities'
		]),
        ...statusesState.mapState([
			'statuses'
		]),

        filteredTags(){

            // Tags
            // let taskTag = this.existsTaskTag.filter((tag) => { return tag; });

            // taskTags
            let existsUxTag = this.taskTags.filter((tag) => { return tag; });

            let iterateTag = existsUxTag.filter(uxTag => !this.existsTaskTag.some(tag => uxTag.taskTagId === tag.taskTagId));
            return iterateTag;
        }
	},
	
	methods : {

        checkColorluminance(color){
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
    
            const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;

            if (relativeLuminance > 0.5) {
                return '#000000';
            }
                return '#FFFFFF';

        },

        handleMessageReceipt: function (messageOutput) {
            this.messages.push(messageOutput);
        },

        sendMessage: function(message) {
            console.log(this.connection);
            this.connection.send(message);
        },

        async getSelectedTaskObj(taskId){

			this.selectedTaskObj = await this.$store.dispatch('tasks/getTask', {taskId});
            this.$store.dispatch('tasks/getTaskAttachment', taskId);
            this.$store.dispatch('tasks/getChecklists', taskId);
            this.$store.dispatch('tasks/getsubTask', taskId);
            this.$store.dispatch('tasks/getComments', taskId);

            this.realtimeStamp();
            this.$nextTick( () => {
                this.setFullImageView();
            });
		},

        downloadAttachment(storedName){
            console.log(window.location);
            this.attachmentURL = window.location.origin + "/attachments/" + storedName;
        },

        async deleteAttachment(attachmentId){
            this.taskAttach.taskId = this.selectedTaskObj.taskId;
            this.taskAttach.attachmentId = attachmentId;
            setTimeout(() => {
                this.taskAttachments.splice(this.taskAttachments.findIndex(a => a.id === attachmentId), 1);
            }, 500);
            await this.$store.dispatch('tasks/deleteAttachments', this.taskAttach);
        },

        bytesForAttachSize(bytes) {
            let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
            let i = 0;
            
            for (i; bytes > 1024; i++) {
                bytes /= 1024;
            }

            return bytes.toFixed(1) + ' ' + units[i];
        },

        realtimeStamp(){

            setInterval(() =>  {
                for(let i = 0; i < this.comments.length; i++){
                    this.time[this.comments[i].taskCommentId] = moment(this.comments[i].createdTime).fromNow();
                }
             }, 100);
               
        },

        openTagPopup(){
            this.tagdialogue = true;
            this.existsTaskTag = this.selectedTaskObj.taskTags.filter((tag) => { return tag; });
            this.$nextTick(() => {
                this.$refs.tagInput.focus();
            });
        },


        createNewTag(value){

            if(this.tagTitle !== [] && typeof value[0] !== 'number' ){

                    let val = value.toString().replace(/\s+/g, "").toLowerCase();
                    this.tagTitle = [];

                    if(!this.existsTaskTag.some(item => item.tagName === val)){
                        this.newTagObj.tagName = val.toString();
                        this.newTagObj.color = "#9013fe";
                        this.existsTaskTag.push(this.newTagObj);
                        this.newTagObj = {};
                        
                    }
                    else{
                        ElMessage({
                            message: `The Tag ${val} already exists.`,
                            type: 'warning'
                        });
                    }

            }

            else{
                this.existsTaskTag.push(this.taskTags.find( tag => tag.taskTagId === value[0]));
                this.tagTitle = [];
            }

            this.createTagsForTask();
        },

        createTagsForTask(){
            this.newTags = this.existsTaskTag.filter(a => !this.taskTags.some(tag => tag.tagName === a.tagName));
        },

        async updateUXTags(){
            
            let existsTag = this.existsTaskTag.filter(a => !this.newTags.some(tag => tag.tagName === a.tagName));
            for(let i = 0; i < this.newTags.length; i++){
                let newTagRes = await this.$store.dispatch("tasktags/createTaskTag", this.newTags[i]);
                console.log(newTagRes);
                existsTag.push(newTagRes);
            }
            this.tagdialogue = false;
            this.selectedTaskObj.taskTags = existsTag;
            let res = await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            this.selectedTaskObj = res;

        },

        closeTag(tag){
            this.existsTaskTag.splice(this.existsTaskTag.indexOf(tag), 1 );
            this.createTagsForTask();
            this.$nextTick(() => {
                this.$refs.tagInput.focus();
            });

        },

        getOpenStatusTasks(){
			let status = '{"statusIds" : [1]}';
			this.statusQryParams.searchCriteria = decodeURI(status);
			this.$store.dispatch('tasks/getAll', {queryParams : this.statusQryParams});
			
		},
        
		getAllTasks(){
			this.$store.dispatch('tasks/getAll');
		},

        async attachmentUpload(event){

            this.taskAttach.taskId = this.selectedTaskObj.taskId;
            for(let i = 0; i < event.target.files.length; i++){
                if(!this.taskAttachments.some(attach => attach.attachmentName === event.target.files[i].name)){
                        this.taskAttach.file = event.target.files[i];
                        await this.$store.dispatch('tasks/uploadFile', this.taskAttach);
                }
                else{
                      ElMessage({
                        message: `The file ${event.target.files[i].name} already exists.`,
                        type: 'warning'
                    });
                }
            }
        },

        async startdate(newValue){
            let bfUpdStDate = this.selectedTaskObj.startDate;
            try{
                if(newValue !== null){
                    this.selectedTaskObj.startDate = new Date(newValue).getTime();
                }
                else{
                    this.selectedTaskObj.startDate = newValue;
                }
                await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            }
            catch(error){
                this.$message("cannot to update startdate. please check the duedate");
                this.selectedTaskObj.startDate = bfUpdStDate;
            }
        },

        async dueDate(newValue){
            let bfUpdDueDate = this.selectedTaskObj.dueDate;
            try{
                if(newValue !== null){
                    this.selectedTaskObj.dueDate = new Date(newValue).getTime();
                }
                else{
                    this.selectedTaskObj.dueDate = newValue;
                }
                await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            }
            catch(error){
                this.$message("cannot to update duedate. please check the startdate");
                this.selectedTaskObj.dueDate = bfUpdDueDate;
            }
        },

        async updateCollaborator(newValue){
            this.selectedTaskObj.owners = newValue;
            this.selectedTaskObj = await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
        },

        async updateFollowers(newValue){
            this.selectedTaskObj.followers = newValue;
            this.selectedTaskObj = await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
        },

		dialogClosed (){
			$$avQS('.el-drawer.tasks-details-view').parentElement.remove();
		},
        
        closeTasks(){
            this.drawer = false;
        },

        afterFormClosed(){
            this.$router.push('/tasks');
            // this.$router.back();

        },

        setPriority(priorityId) {
            // let taskId =  this.selectedTaskObj.taskId;
            this.selectedTaskObj.priority = this.priorities.find(e => e.taskPriorityId === priorityId);
            this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            // this.selectedTaskObj = await this.$store.dispatch('tasks/getTask', {taskId});
        },

        setStatus(statusId){
            // let taskId =  this.selectedTaskObj.taskId;
            this.selectedTaskObj.status = this.statuses.find(e => e.taskStatusId === statusId);
            this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            // this.selectedTaskObj = await this.$store.dispatch('tasks/getTask', {taskId});
        },

        updateTaskTitle() {
            let editedTaskTitle = this.$refs.taskTitleText.innerText;
            this.selectedTaskObj.taskTitle = editedTaskTitle;
            this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
        },
        
        setFullImageView(){
            const spanElement = document.querySelectorAll('.descHtml img');
            spanElement.forEach((image) => {
				image.addEventListener('click', this.onImageClick);
			});
        },

        onImageClick(event){
            this.imagePreviewInDesc = true;
            this.previewImageSrcInDesc = event.target.src;
        },

        closePreviewDial(){
            this.imagePreviewInDesc = false;
            this.previewImageSrcInDesc = '';
            this.zoomIn = false;
        },

        async updateDesc(){
            this.loadingDescription = true;
            let quill = this.$refs.richTextEditor.$refs.quillEditor.getQuill();

            if(quill.root.innerHTML === '<p><br></p>' ){
                this.selectedTaskObj.description = null;
            }
            else{
                this.selectedTaskObj.description = quill.root.innerHTML;
                this.addFile(this.selectedFile);
                this.selectedFile = [];
            }

            this.taskdesc = false;
            await this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
            this.loadingDescription = false;
            this.$nextTick(() => {
                this.setFullImageView();
            });

        },

        addFile(files){
            let taskAttach = {};
            taskAttach.taskId = this.selectedTaskObj.taskId;
            files.forEach( async (file) => {
                taskAttach.file = file;
                await this.$store.dispatch('tasks/uploadFile', taskAttach);
            });
        },

        removeFile(file){
            this.selectedFile.splice(this.selectedFile.findIndex(f => f.name.trim() === file.name.trim()), 1);
        },

        cancelDesc(){
            this.taskdesc = false;
            this.selectedFile = [];
            this.$nextTick(() => {
                this.setFullImageView();
            });
        },

        fileUrl(file){
            console.log(file);
                let reader = new FileReader();
                reader.onload = (e) => {
                    console.log(e.target.result);
                    this.setPreUrl = e.target.result;
                };
                reader.readAsDataURL(file);
                return this.setPreUrl;
        },

        setAttachmentInEditor(event){
          event.target.files.forEach( (file) => {
            this.selectedFile.push(file);
          });
        },

        addTags(){
            this.taginputVisible = true;
            setTimeout(() => {
                this.$refs.addTagInput.focus();
            }, 0);
            
        },

        async addNewTag(){

            for(let i = 0; i < this.selectedTaskObj.taskTags.length; i++) {
                this.tagNameArr.push(this.selectedTaskObj.taskTags[i].tagName.toUpperCase());
            }
            if(this.tagNameArr.includes(this.tagInputValue.toUpperCase().trim())){
                this.duplicateTag = this.tagInputValue.toUpperCase().trim();
                ElMessage.error('the tag' + this.tagInputValue.toUpperCase().trim() + 'is already exists');
                this.tagInputValue = '';
                this.tagNameArr = [];
            }
            else{
                this.taskTag.tagName = this.tagInputValue.trim();
                this.taskTag.color = "#9013fe";
                let uxtag = await this.$store.dispatch("tasks/addTaskTag", this.taskTag);
                this.selectedTaskObj.taskTags.push(uxtag.data);
                this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
                this.tagTabHeight = this.$refs.tagContainer.clientHeight + 90;
                this.tagInputValue = '';
                this.tagNameArr = [];
            }

        },

        deleteTag(tag){
            this.selectedTaskObj.taskTags.splice(this.selectedTaskObj.taskTags.indexOf(tag), 1);
            this.$store.dispatch("tasks/updateTaskDetails", this.selectedTaskObj);
        },

        // Checklist

        async addcheckListInput(){
            await (this.checkListInput = true);
            await (this.inlineAddChecklist = "");
            await this.$refs.AddChecklist.focus();
        
        },

        outOfchecklistInput(){
            let emptyChecklist =  this.inlineAddChecklist.trim();
            if(emptyChecklist === ""){
                this.checkListInput = false;
                this.checklistRules = false;
            }
            else if(this.$refs.AddChecklist.modelValue.length <= 2){
                this.checklistRules = true;
            }
        },

        async addChecklist() {
            let content =  this.inlineAddChecklist.trim();
            if(content === ""){
                this.checkListInput = false;
            }
    
            else{

                if(this.$refs.AddChecklist.modelValue.length <= 2){
                    this.checklistRules = true;
                }
                else{
                    this.checklistRules = false;
                    this.inlineAddChecklist = "";
                    this.checkListObj.checkListName = content;
                    this.checkListObj.parentTaskId = this.selectedTaskObj.taskId;
                    await this.$store.dispatch("tasks/createChecklist", this.checkListObj);
                    this.checkListInput = false;
                }

            }
        },

        checklistTitleEdit(index){
            this.editChecklist = index;
            let refVal = "checkListText";
            refVal += index;
            setTimeout(() => {
                this.$refs[refVal].focus();
            }, 0);
        },

        updateChecklistText(index) {
            let refVal = "checkListText";
            refVal += index;
            let checklistValues = this.$refs[refVal].modelValue.trim();
            this.checklists[index].checkListName = checklistValues;
            this.checklists[index].parentTaskId = this.selectedTaskObj.taskId;
            this.checkListObj.checkListId = this.checklists[index].checkListId;
            this.$store.dispatch("tasks/updateChecklist", this.checklists[index]);
            this.editChecklist = false;
        },

        delChecklist(checkListId){
            this.delcheckVisible = checkListId;
        },

        cancelChecklist(){
            this.delcheckVisible = false;
            this.action = null;

        },

        async checklistDelete(index) {

            this.checkListObj.parentTaskId = this.selectedTaskObj.taskId;
            this.checkListObj.checkListId = this.checklists[index].checkListId;
            this.checklists.splice(this.checklists.findIndex(a => a.id === this.checklists[index].checkListId), 1);
            await this.$store.dispatch("tasks/deleteChecklist", this.checkListObj);
            this.delcheckVisible = false;
        },

        checked(index){
            if( this.checklists[index].completed === false ){
                this.checklists[index].completed = true ;
                this.checklists[index].parentTaskId = this.selectedTaskObj.taskId;
                this.$store.dispatch("tasks/updateChecklist", this.checklists[index]);
            }
            else{
                this.checklists[index].completed = false ;
                this.checklists[index].parentTaskId = this.selectedTaskObj.taskId;
                this.$store.dispatch("tasks/updateChecklist", this.checklists[index]);
            }
        },

        // Subtasks

        async addsubTaskInput(){
            await (this.subTaskInput = true);
            await (this.inlineAddsubTask = "");
            await this.$refs.addsubTask.focus();

         },

        outOfsubtaskInput(){
            let emptySubtask = this.inlineAddsubTask.trim();
            if( emptySubtask === ""){
                this.subTaskInput = false;
            }
        },

        addsubTask() {
            let content = this.inlineAddsubTask.trim();
            if( content === ""){
                this.subTaskInput = false;
            }else{
            this.subTaskInput = false;
            this.subtaskObj.taskTitle = this.inlineAddsubTask.trim();
            this.subtaskObj.parentId = this.selectedTaskObj.taskId;

            this.subtaskObj.owners = this.currentOrg.members.filter( (member) => { return member.user.userId === this.currentAuthUser.userId; });
            this.subtaskObj.status = this.statuses.find(p => p.statusCategory === 'Open');
            this.$store.dispatch("tasks/createsubTask", this.subtaskObj);
            this.inlineAddsubTask = "";

            }
        },

        // async subTaskDelete(index){
        //     this.subtaskObj.parentTaskId = this.selectedTaskObj.taskId;
        //     this.subtaskObj.subtaskId = this.subTasks[index].taskId;
        //     this.subTasks.splice(this.subTasks.findIndex(a => a.id === this.subTasks[index].taskId), 1);
        //     await this.$store.dispatch("tasks/deleteSubtask", this.subtaskObj);
        //     this.delSubVisible = false;

        // },

        // Comments

        async addComment() {
            let content =  this.inlineAddComment.trim();
            this.inlineAddComment = '';
            if(content){
                this.commentObj.comment = content.trim();
                this.commentObj.parentTaskId = this.selectedTaskObj.taskId;
                await this.$store.dispatch("tasks/createComment", this.commentObj);
                this.inlineAddComment = "";
            }
        },

        delPopup(commentId){
            this.dialogVisible = commentId;
        },

        async deleteComment(index) {

            this.commentObj.parentTaskId = this.selectedTaskObj.taskId;
            this.commentObj.taskCommentId = this.comments[index].taskCommentId;
            this.comments.splice(this.comments.findIndex(a => a.id === this.comments[index].taskCommentId), 1);
            await this.$store.dispatch("tasks/deleteComment", this.commentObj);
            this.dialogVisible = false;
            
        },

        editComment(index) {
            let refVal = 'commentSpan';
            refVal += index;
            // this.$refs[refVal].contentEditable = true;
            // this.edited = index;
            this.editMode = index;
            this.visible = index;
            setTimeout(() => {
                this.$refs[refVal].focus();
            }, 100);
        },
        cancelComment(){
            this.editMode = null;
            this.visible = null;
        },

        async updateComment(index) {
            this.editMode = null;
            this.visible = null;
            let refVal = "commentSpan";
            refVal += index;
            this.comments[index].comment = this.$refs[refVal].value;
            this.comments[index].parentTaskId = this.selectedTaskObj.taskId;
            await this.$store.dispatch("tasks/updateComment", this.comments[index]);
        }
	}
};
</script>
<style>
.desc-input{
    margin-bottom: 5px;
}
.timeStamp-menu{
    background-color: transparent;
    padding: 1px 0;
}
.timeStamp-menu > .el-dropdown-menu__item{
    font-size: 13px;
    padding: 0 7px;
    line-height: 26px;
    color: var(--color-2);
}
.timeStamp-menu > .el-dropdown-menu__item:hover {
    color: var(--color-2);
}

.left-pnl .tag-dialogue .uxTagBox{
    border: 1px #eee solid;
    max-width: 80%;
    padding: 5px;
}
.left-pnl .desc-confirm{
    text-align: end;
}
.left-pnl .desc-confirm .el-button{
    padding: 10px 15px;
}
.left-pnl .tag-dialogue .uxTagBox .el-select {
    width: 100%;
}
.left-pnl .tag-dialogue .uxTagBox .el-input--suffix .el-input__inner,
.left-pnl .tag-dialogue .uxTagBox .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
.left-pnl .tag-dialogue .uxTagBox .el-input__suffix {
    display: none;
}
.slide-enter-from,
.slide-leave-to{
	opacity: 0;
	transform: translateX(-30px);
}

.slide-enter-active{
	transition: all 0.2s ease-out;
}
.slide-leave-active{
	transition: all 0.2s ease-in;
}
.slide-enter-to, .slide-leave-from{
	opacity: 1;
	transform: translateY(0);
}

.checklist-input span{
    color: var(--el-color-danger);
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;

}
.mr{
    margin-right: 5px;
}

/* @keyframes slide-scale {
    0%{
        transform: translateX(0) scale(1);
    }
    70%{
        transform: translateX(-120px) scale(1.1);
    }
    100%{
        transform: translateX(-150px) scale(1);
    }
    
} */
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.duplicate{
    color: red;
    /* animation: shake 1s; */
}
.tag-container{
    height: 90px;
    position: relative;
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    padding: 0 10px;
}
.tag-container > input{
    width: 100%;
    padding-left: 280px;
    /* font-size: 0.9em; */
}
.tag-container > .el-tag-contanier{
    position: absolute;
    top: 0;
    bottom: 0;
    height: fit-content;
    padding: 7px 0;
}
.fade-router-enter-from,
.fade-router-leave-to
 {
    opacity: 0;
}
.fade-router-enter-active{
    transition: opacity 0.2s ease-out;
}
.fade-router-leave-active{
    transition: opacity 0.2s ease-in;
}
.fade-router-enter-to,
.fade-router-leave-from{
    opacity: 1;
}
.text-editor-pnl{
    /* display: flex; */
    flex-flow: wrap;
    justify-content: flex-end;
}

.ml{
    margin-left: 10px;
    cursor: pointer;
}
.close-btn > button:hover{
    padding: 2px 8px;
    border-radius: 5px;
    border: none;
    background-color: #ededed;
}
.close-btn > button{
    background: none;
    cursor: pointer;
    padding: 2px 8px;
}
.tasks-details-view .task-body > .task-body-head .task-ttl{
    padding: 0.5rem 0;
    padding-right: 1.5rem;
}
.tasks-details-view .task-body > .task-body-head .title-text{
    word-break: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    border: solid 0.1rem transparent;
    padding: 0.4rem 2px;

}
.tasks-details-view .task-body > .task-body-head .title-text:hover{
    border: solid 0.1rem #3d403f59;
}

.tag-chips-hldr > .tag-chip{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
}
.taskTextContainer {
  margin-top: 1rem;
}
.taskTextContainer span {
  margin-bottom: 1rem;
  border: 0.1rem solid rgb(189, 189, 189);
  border-radius: 0.2rem;
  padding-left: 0.5rem;
}
.taskTextContainer span:hover {
  background-color: var(--color-8);
}
.status-icon {
    height: 1rem;
    width: 1rem;
    border-radius: 0.225rem;
}

.tasks-details-view .tview-bdy .task-body > .right-panel > .popup-div .dropdown-selfdefine{
    border: 1px solid #5964DD;
    border-radius: 1px;
    padding: 5px 15px;
    /* height: 25px; */
}
.lbl{
    padding-right: 6px;
}
.popup-div .date-div > .el-input > .el-input__inner{
    border-radius: 1px;
    border: 1px solid #5964DD;
    height: 25px;
    padding-right: 2px;
    text-overflow: ellipsis;
    
}
.popup-div .date-div > .el-input > .el-input__inner::placeholder{
    font-size: 13px;
    
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
}

.popup-div .date-div {
   width: 23%;
}

.left-pnl > .attachInp > .attachInp-cont > .icon{
    color: var(--color-6);
    margin-right: 7px;
}

.left-pnl > .attachInp > .attachInp-cont{
    display: flex;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.left-pnl > .attachInp > .attachInp-cont > .attachInp-data{
    margin-right: 5px;
    max-width: 175px;
}
.left-pnl > .attachInp > .attachInp-cont > .attch-opt > img{
    margin-right: 7px;
    width: 15px;
}
.left-pnl > .attachInp > .attachInp-cont > .attch-opt a > img{
    width: 13px;
}

.left-pnl > .attachInp > .attachInp-cont > .attachInp-data span{
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    word-spacing: 2px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #373737;
}

.left-pnl > .attach-tab .attach-upload-label{
    display: flex;
    margin-bottom: 0;
}

.left-pnl > .attach-tab .attach-upload-label > .icon{
    color: var(--color-6);
}
.left-pnl > .attach-tab .attach-upload-input{
    display: none;
}
.checklist-div{
    /* height: 3.25rem; */
    display: flex;
    width: 100%;
    padding: 7px 0;
    border-bottom: 0.0625rem solid #e9e9e99c;
}
.check-box{
    width: 85%;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}
.check-box-img{
    margin-right: 1.5rem;
}
.checklist-div:hover {
  background: var(--color-9);
  opacity: 1;
  box-shadow: inset 0px -1px 0px #f3f3f3;
  cursor: pointer;
  color: #0056b3;
}
.check-box > .checklistLabel{
    word-spacing: 2px;
    margin-left: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 60%;
    color: #373737;
}
.check-box > .checklist-editable{
    margin-left: 1rem;
    width: 75%;
}
.checklist-editable > .chec-edt-inp{
    margin-bottom: 12px;
}

.editableText{
   margin-left: 1rem;
}
.checkbox{
    margin-left: 0.5rem;
}

.line {
  text-decoration: line-through;
  text-decoration-color: grey;
}
.checklist-pic {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection {
  width: 100%;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont{
    border : 1px solid #d1d5db;
    margin: 10px 0;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont .attach-file-list span:nth-child(2n + 1){
    font-size: 14px;
    color: #6d6e6f;
    margin-right: 10px;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont .attach-file-list span:nth-child(2n){
    color: #e93838a3;

}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont .attach-file-list .file-list-name{
    padding: 0 11px;

}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont .ql-toolbar.ql-snow{
    border-top: none;
    border-left: none;
    border-right: none;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .desc-cont .ql-container.ql-snow{
    border: none;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div{
    padding: 5px 0.5rem;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div:hover{
    background: var(--color-9);
    opacity: 1;
    box-shadow: inset 0px -1px 0px #f3f3f3;
    cursor: pointer;
    /* color: #0056b3; */
}

.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection .profile-pic-comments, .left-pnl > .attachInp > .attachInp-cont .profile-pic-attach {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 13px;
  color: #fff;
}

.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText {
  /* margin-left: 14px; */
    width: 100%;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText .crud-tab{
    padding: 5px 0;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText .el-button--small{
    padding: 0;
    min-height : 0;
    margin-right: 10px;
    margin-left: 0;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText .comment-title{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3F3F3F;
    margin-bottom: 5px;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText > .comment-comments{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    word-spacing: 2px;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText .comment-btns > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6F6F6F;
}

.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div .cmt-edit-input{
    border: 1px solid lightblue;
    border-radius: 4px;
    padding: 3px 5px;
    height: 80px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
}

.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText .cmt-timeline{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #6F6F6F;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText .cmt-time-val{
    margin-left: auto;
}
.tasks-details-view .tview-bdy > .task-body .left-pnl .commentSection > .add-commt-tab{
    padding: 10px 0;
}
.flag {
    margin-right: 10px;
    width: 16px;
}
.tasks-details-view .tview-bdy .task-body > .right-panel > .popup-div{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1.5rem;
    padding-top: 0.5rem;
}

.tasks-details-view .tview-bdy .task-body > .right-panel > .popup-div .rght-arrow{
    padding: 1px 5px;
    height: 100%;
    border: 1px solid #545CA5;
    border-left: none;
}
.tasks-details-view .tview-bdy .task-body > .right-panel > .popup-div .rght-arrow > img{
    height: 12px;
    width: 7px;

}
.tasks-details-view .tview-bdy .task-body > .right-panel > .popup-div svg.av-icon.regular{
    height: 1.55rem;
    width: 1.55rem;
}

.el-drawer.tasks-details-view > .el-drawer{
    padding: 0;
}

.el-drawer.tasks-details-view > .el-drawer__body,
.tasks-details-view .tview-ctr{
    height: 100%;
    width: 100%;
}

.tasks-details-view .task-nav-bar{
    width: 18%;
    height: 100%;
    overflow: auto;
}
.tasks-details-view .task-nav-bar > .hdr{
    height: 4rem;
    padding: 0 1.5rem;
}
.hide-bar{
    position: relative;
}
.hide-bar > div{
    position: absolute;
    left: 25px;
    top: 33px;
    transform: rotate(180deg);
    background: #7e7e7e3b;
    border-radius: 27px;
    height: 30px;
    padding: 3px;
}
.hide-bar > .pST{
    
}
.tasks-details-view .task-nav-bar > .hdr > .icon,
.hide-bar > div

{
    color: var(--color-6);
}

.tasks-details-view .tview-bdy{
    padding: 0 1.5rem;
    border: 0.0625rem solid var(--color-9);
    width: 80%;
}
.tasks-details-view .tview-bdy .task-body{
    width: 100%;
}
.tasks-details-view .task-body > .task-body-head{
    width: 70%;
}
.tasks-details-view .tview-bdy > .task-hdr{
    height: 3.375rem;
}
.tasks-details-view .task-body > .task-body-head > .left-pnl{
    padding-right: 2rem;
    border-right: 0.0625rem solid var(--color-9);
}
.tasks-details-view .task-body > .task-body-head > .left-pnl > .taskDesc img{
    width: 20%;
}
.tasks-details-view .task-body > .task-body-head > .left-pnl > .sub-hdrs .desc-edit::after{
    content:"\e3c9";
    font-family: "Material Icons";
	font-size : 0.9rem;
    color : #7e7e7ec2
}
.tasks-details-view .task-body > .task-body-head > .left-pnl > .desc{
    color: var(--color-11);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor:default;
}

.tasks-details-view .task-body > .task-body-head > .left-pnl > .sub-hdrs{
    height: 3rem;
}
.tasks-details-view .task-body > .task-body-head > .left-pnl > .sub-hdrs > .lbl{
    padding-right: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.tasks-details-view .task-body > .task-body-head > .left-pnl > .sub-hdrs > .icon{
    color: var(--color-6);
}
.tasks-details-view .task-body > .task-body-head > .left-pnl .descHtml img{
    width: 100%;
}
/* .tasks-details-view .tview-bdy > .task-body .left-pnl{
    padding: 5px 28px;
} */
.tasks-details-view .tview-bdy .task-body > .right-panel{
    overflow: auto;
    width: 35%;
}
.tasks-details-view .tview-bdy > .task-body > .right-panel .right-panel-details{
    padding: 1rem 1.5rem;
}
.ql-emojiblot{
	vertical-align: middle;
}
.left-pnl .el-dialog.desc-img-preview{
	background-color: #252628;
}
.left-pnl .el-dialog.desc-img-preview .el-dialog__header {
    display: none;
}
.left-pnl .el-dialog.desc-img-preview .preview-header{
    color: #ffffff;
    margin-bottom: 20px;
}
.left-pnl .desc-img-preview img{
	max-width: 100%;
}
.pre-img-cont{
    overflow: auto;
    margin: 0 auto;
}
/* WcpZLJEuTScpQm8EdjxG */
/* eNMLLfEtD7WHPjBJvpeZ astravue */
/* Git App password / Beta Update / Astravue-branch - password : VyaXH5K6MN3UCbEHaEmU */
</style>