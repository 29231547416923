<template>
<div class="av-home-container flex--col flex--one">
	<div class="hdr flex--row flex--ac">
		<span>{{ greet }}, {{ this.currentAuthUser.firstName }}</span>
            <div class="right-pnl flex--row flex--ac mlA">
				<el-button-group>
					<el-button type="primary" size="medium" @click="openAddTaskForm = true">Create</el-button>
				</el-button-group>
            </div>
	</div>
	<div class="flex--row flex--ac">
		<av-home-task-view></av-home-task-view>
		<div class="agendaContainer">
		</div>
	</div>
	<av-task-createform v-if="openAddTaskForm" @onClose="openAddTaskForm = false"></av-task-createform>
	<!-- <el-dialog v-model="workSpaceDialog" title="Create Your Workspace" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape ="false">
		<span>Change your Username :</span>
		<el-input type="text" v-model="userNameInp" placeholder="enter user name"/>
		<span>Enter your Workspace name :</span>
		<el-input type="text" v-model="workSpaceName" placeholder="enter workspace name"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="createOrg">
          Create Workspace
        </el-button>
      </span>
    </template>
  </el-dialog> -->

	<el-dialog v-model="workSpaceDialog" title="Create Your Workspace" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape ="false">
		<!-- <span>Change your Username :</span>
		<el-input type="text" v-model="userNameInp" placeholder="enter user name"/> -->
		<span>Set your Workspace name :</span>
			<el-input type="text" v-model="workSpaceName" placeholder="enter workspace name"/>
		<template #footer>
		<span class="dialog-footer">
			<el-button type="primary" @click="createOrg">Create Workspace</el-button>
		</span>
		</template>
  </el-dialog>

</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avHomeTaskView from '@/components/av-home-task-view.vue';
import avTaskCreateForm from '@/components/av-task-createform.vue';
const eventState = createNamespacedHelpers('events');
const usersState = createNamespacedHelpers('users');
const orgState = createNamespacedHelpers('organizations');
const { mapState, mapGetters } = createNamespacedHelpers('tasks');

export default {
  components: {
	
	'av-home-task-view' : avHomeTaskView,
	'av-task-createform' : avTaskCreateForm

	},
	data () {
		return {
			greet : '',
			openAddTaskForm : false,
			value : new Date(),
			workSpaceDialog : false,
			userNameInp : '',
			workSpaceName : ''

		};
	},
    created() {
		let hrs = new Date().getHours();
		if (hrs < 12) this.greet = 'Good Morning';
		else if (hrs >= 12 && hrs <= 16) this.greet = 'Good Afternoon';
		else if (hrs >= 16 && hrs <= 24) this.greet = 'Good Evening';
		
		// await this.$store.dispatch('organizations/getCurrentOrg');
		this.checkOrg();

    },
    computed: {
		...mapState([
			'fields',
			'tasks'
		]),
		...mapGetters([
			'displayFields',
			'statusFieldObj'
		]),
		...eventState.mapState([
			'events'
		]),
		...usersState.mapState([
			'currentAuthUser'
		]),
		...orgState.mapState([
			'allOrganization',
			'currentOrg'
		])
	},


	// beforeRouteEnter(to){
	// 	// const data = loadData();
	// 	// next((vm) => {
	// 	// 	vm.data = data;
	// 	// });

	// 	console.log(to);
	// },

	methods : {

		async checkOrg(){
			if(this.currentOrg.orgName === null){
				this.workSpaceDialog = true;
			}
			else{
				this.workSpaceDialog = false;
				this.getAllTasks();
				await this.$store.dispatch('events/getAllEvents');
			}
		},
		async createOrg(){
			if(this.workSpaceName && this.workSpaceName.length > 0 ){
				this.currentOrg.orgName = this.workSpaceName.trim();
				await this.$store.dispatch('organizations/updateOrg', this.currentOrg);
				this.checkOrg();
				this.workSpaceName = '';
			}
		},

		splitdateEvents(){
			// console.log(this.events[0].start.slice(0, 10));
			// this.date = this.events[0].start.slice(0, 10);
			// console.log(this.date);

		},

        getAllTasks (){
			//this.$store.dispatch('tasks/getAll', {queryParams : this.sortQryParams});
			this.$store.dispatch('tasks/getAll');
		},
		testButton(){
			this.isClicked = true;
		}
	}
};
</script>
<style>
.is-selected {
  color: red;
}

.av-home-container > .hdr{
	height: 4rem;
	padding: 0.5rem 2rem;
}
.rightPnl{
	margin-left: 2rem;
}

.agendaContainer{
	width: 40vw;
	height: 40vw;
}
/* collaborators
:
Proxy {}
dueDate
:
NaN
priority
:
null
status
:
undefined
taskTitle
:
"Task" */
</style>