<template>
    <ul class="rightpnl-tabs flex--row flex--ac txt14">
        <li @click="layout = 'task'" :class="{ selected: layout === 'task' }" class="tb-itms font-med">
            <span v-if="isTasks">{{ $t("av.tasks.details") }}</span>
            <span v-else>{{ $t("av.subTasks.details") }}</span>
            </li>
        <li @click="layout = 'activity'" :class="{ selected: layout === 'activity' }" class="tb-itms font-med">{{ $t("av.tasks.details.activity") }}</li>
    </ul>
    <div v-if="layout === 'task'">
    <div class="assigned-to txt14">
        <div>
            <span class="cp task-dtl-title">{{ $t("av.tasks.details.owners") }}</span>
            <img @click="editowner" class="edt cp"  src="../icons/edit-checklist.png"/>
        </div>
        <el-select class="users-ctr" v-if="selectowner" multiple v-model="existsCollab" ref="collabTab"  @visible-change="updateowner" @change="selectMembers" collapse-tags collapse-tags-tooltip
            filterable placeholder="Search owner">
                <el-option
                v-for="orgMembers in filteredOwnMembers"
                :key="orgMembers.user.userId"
                :label="orgMembers.user.firstName"
                :value="orgMembers.user.userId"
                />
            </el-select>
        <template v-else>
            <av-task-assignee v-if="taskObj.owners && taskObj.owners.length" :owners="taskObj.owners" :taskId="taskObj.taskId"></av-task-assignee>
            <av-icon v-else class = "own-icon cp icon large" name="account-circle-24"></av-icon>
        </template>
    </div>
    
    <div class="assigned-to txt14">
        <div class="">
            <span class="task-dtl-title">{{ $t("av.tasks.details.followers") }}</span>
            <img @click="editFollower" class="edt cp"  src="../icons/edit-checklist.png"/>
        </div>
        <el-select  v-if="selectFollower" multiple v-model="existsFollow" ref="follTab" @change="selectMembers" @visible-change="updateFollowers" collapse-tags collapse-tags-tooltip
            filterable placeholder="Search Follower">
                <el-option
                v-for="orgMembers in filteredOwnMembers"
                :key="orgMembers.user.userId"
                :label="orgMembers.user.firstName"
                :value="orgMembers.user.userId"
                />
        </el-select>
        <template v-else>
            <av-task-assignee v-if="taskObj.followers && taskObj.followers.length" :owners="taskObj.followers" :taskId="taskObj.taskId" :isFollower="true"></av-task-assignee>
            <av-icon v-else class = "own-icon cp icon large" name="account-circle-24"></av-icon>
        </template>
        
    </div>

    <table class="task-main-data txt14">
        <!-- <tr class="task-data-row">
            <td class="task-data-lbl">{{ $t("av.tasks.details.data.taskid") }}</td>
            <td class="task-data">{{ taskObj.taskId }}</td>
        </tr> -->
        <tr class="task-data-row" >
            <td class="task-data-lbl">{{ $t("av.tasks.details.data.startdate") }}</td>
            <td v-if="editStartDate" @blur="editStartDate = false" class="start-date-edt" >
                <el-date-picker ref="startDateInp" class="start-date-inp" format="DD/MM/YYYY" v-model="taskStartDate" @change="setStartDate" ></el-date-picker>
            </td>
            <td v-else>
                <span class="st-dt-fld" v-if="taskObj.startDate !== null" @click="editStDate" >{{$lxnFormatDate(taskObj.startDate)}}</span>
                <span v-else class="st-dt-fld cp flex--row" @click="editStDate">
                    <av-icon  class ="calIcon cp icon medium" name="calendar-icon"></av-icon>
                </span>
            </td>
        </tr>
        <tr class="task-data-row">
            <td class="task-data-lbl">{{ $t("av.tasks.details.data.duedate") }}</td>
            <td v-if="editDueDate" class="start-date-edt" >
                <el-date-picker ref="dueDateInp"  class="start-date-inp" format="DD/MM/YYYY" v-model="taskDueDate" @change="updateDueDate"></el-date-picker>
            </td>
            <td v-else>
                <span class="st-dt-fld" v-if="taskObj.dueDate !== null" @click="editDue" >{{$lxnFormatDate(taskObj.dueDate)}}</span>
                <span v-else class="st-dt-fld cp flex--row" @click="editDue">
                    <av-icon  class ="calIcon cp icon medium" name="calendar-icon"></av-icon>
                </span>
            </td>
        </tr>
        <!-- <tr class="task-data-row">
            <td class="task-data-lbl">{{ $t("av.tasks.details.data.createdon") }}</td>
            <td>{{$lxnFormatDate(taskObj.createdTime)}}</td>
        </tr> -->
    </table>
    </div>
    <div  v-if="layout === 'activity'">
        <el-input placeholder="Search" v-model="searchHistory" type="text"/>
        <div tabindex="1" @blur="focusOut">
            <p>Prev Val :</p>
            <p>Current Val :</p>
        </div>
        <!-- <div :style="{color : checkColorluminance('#000000')}" style="height : 2rem; width : 2rem; border-radius : 50%; background-color : red; text-align : center">
            A
        </div> -->
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const taskHistoryState = createNamespacedHelpers('taskHistory');
const orgState = createNamespacedHelpers('organizations');
import avTaskAssignee from '@/components/av-task-assignee.vue';
import avIcon from '@/components/av-icon.vue';
// import Cropper from 'cropperjs';
// import { ElMessage } from 'element-plus';

export default {
    emits: ["taskStartDate", "taskDueDate", "updateTaskCollab", "updateTaskFollower",
                "subtaskStDate", "subtaskDueDate"],
	components: {
		'av-task-assignee' : avTaskAssignee,
        'av-icon' : avIcon
	},

    created(){
        this.taskStartDate = this.taskObj.startDate;
        this.taskDueDate = this.taskObj.dueDate;
        this.existsCollab = this.taskObj.owners.map((col) => { return col.user.userId ;});
        this.existsFollow = this.taskObj.followers.map((col) => { return col.user.userId ;});
        
    },

    computed: {
        ...taskHistoryState.mapState([
			'taskHistories'
		]),
        ...orgState.mapState([
			'currentOrg'
		]),
        filteredTaskHistory(){

			let filtTaskHistory = this.taskHistories;

					filtTaskHistory = filtTaskHistory.sort((a, b) => {
							let fa = a.taskHistoryId;
							let fb = b.taskHistoryId;
						
							if (fa > fb) {
								return -1;
							}
							if (fa < fb) {
								return 1;
							}
							return 0;
							
						});

					return filtTaskHistory;
        },
        filteredOwnMembers(){
            return this.currentOrg.members.filter((member) => {
                if(member.user.firstName !== null){
                    return member;
                }
            });
        }
    },

    data(){
        return{
            layout: "task",
            showStartDate: false,
            taskStartDate: '',
            editStartDate : false,
            editDueDate : false,
            showDueDate : false,
            taskDueDate : '',
            selectowner : false,
            existsCollab: [],
            selectMembersArr : [],
            selectFollower :false,
            existsFollow: [],
            searchHistory : '',
            cropper : {},
            destination : {},
            img : {}
        };
    },

	props : {
		taskObj : Object,
        isTasks : {
			type : Boolean,
			'default' : false
		}

	},
    methods : {
        focusOut(){
            console.log("focus out");
        },

        checkColorluminance(color){
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
    
            const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;

            if (relativeLuminance > 0.5) {
                return '#000000';
            }
                return '#FFFFFF';
        },

        // crop(){
        //         this.img = document.querySelector('.image');
        //         console.log(this.img);
        //         this.cropper = new Cropper(this.img, {
        //             zommable : false,
        //             scalable : false,
        //             aspectRatio : 1,
        //             crop:() => {
        //                 let canvas = this.cropper.getCroppedCanvas();
        //                 this.destination = canvas.toDataURL("image/png");
        //             }
        //         });
        // },

        editowner(){
            this.selectowner = true;
            setTimeout(() => {
                this.$refs.collabTab.focus();
            }, 100);
        },
        selectMembers(newValueArr){
            this.selectMembersArr = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
        },
        updateowner(val){
            if(val === false && this.selectMembersArr.length > 0){
               this.$emit('updateTaskCollab', this.selectMembersArr);
               this.selectowner = false;
               this.selectMembersArr = [];
            }
            else if(val === false){
                this.selectowner = false;
            }
            
        },

        editFollower(){
            this.selectFollower = true;
            setTimeout(() => {
                this.$refs.follTab.focus();
            }, 100);
        },

        updateFollowers(val){
            if(val === false && this.selectMembersArr.length > 0){
               this.$emit('updateTaskFollower', this.selectMembersArr);
               this.selectFollower = false;
               this.selectMembersArr = [];
            }
            else if(val === false){
                this.selectFollower = false;
            }
            
        },

        editStDate(){
            this.editStartDate = true;
            setTimeout(() => {
                this.$refs.startDateInp.focus();
            }, 100);

        },

        setStartDate(newValue){

            if(this.isTasks){
                this.$emit('taskStartDate', newValue);
            }
            else{
                this.$emit('subtaskStDate', newValue);
            }
             this.editStartDate = false;
        },

        editDue(){
            this.editDueDate = true;
            setTimeout(() => {
                this.$refs.dueDateInp.focus();
            }, 100);
        },

        updateDueDate(newValue){
            
            if(this.isTasks){
                this.$emit('taskDueDate', newValue);
            }
            else{
                this.$emit('subtaskDueDate', newValue);
            }
            this.editDueDate = false;
        }
    }
};
</script>
<style scoped>

.image {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
.rightpnl-tabs{
    height: 2.5rem;
}
.rightpnl-tabs > .tb-itms{
    max-width: 7rem;
    margin: 0 0.75rem;
    padding-bottom: 0.125rem;
    border-bottom: 0.1875rem solid transparent;
    cursor: pointer;
}
.rightpnl-tabs > .tb-itms:first-of-type{
    margin-left: 0;
}
.rightpnl-tabs > .tb-itms.selected{
    border-bottom: 0.1875rem solid var(--color-2);
    /* background: var(--color-9); */
    opacity: 1;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.rightpnl-tabs > .tb-itms:not(.selected){
    color: var(--color-7);
}

.subtask-right-pnl-content .assigned-to,.right-panel-details .assigned-to {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding: 0.5rem 0;

}
.subtask-right-pnl-content .assigned-to .task-dtl-title,.right-panel-details .assigned-to .task-dtl-title{
    margin-right: 5px;
}

.right-panel-details .assigned-to div:first-of-type{
    margin-bottom: 5px;
}
.right-panel-details .assigned-to .own-icon{
    color:var(--color-8);
}

.assigned-to > .users-ctr{
    justify-content: inherit;
    padding-top: 0.5rem;
}

.task-main-data{
    padding: 1.5rem 0;
    width: 100%;
}
.task-main-data > .task-data-row > td{
    padding: 0.5rem 0;
}
.task-main-data > .task-data-row > td .st-dt-fld{
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 8rem;
}
.task-main-data > .task-data-row > td .calIcon{
    color: #acafb3;
}
.task-main-data > .task-data-row > td .st-dt-fld:hover{
    border: 1px solid #acafb3;
}
.task-main-data > .task-data-row > .task-data-lbl{
    color:var(--color-12);
    width : 8rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

}
.task-data-row > .task-data{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.task-data-row .edt{
    margin-left: 15px;
    cursor: pointer;
}
.start-date-edt > .start-date-inp .el-date-editor {
    width: 3.5rem;
}
</style>