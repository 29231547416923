<template>
    <el-drawer custom-class="add-task-form" size="50%" :model-value="openAddTaskForm" :with-header="false" @closed="afterFormClosed">
			<div class="hdr">
				{{$t('av.tasks.add')}}
			</div>
			<el-form ref="addTaskForm" :model="addTaskForm" :rules="addTaskFormRules" :inline="true" size="medium"
            label-width="120px" label-position="top">
				<el-form-item label="Task Title" prop="taskTitle" class="fullwidth">
                    <el-input v-model="addTaskForm.taskTitle"></el-input>
				</el-form-item>
				<el-form-item label="Description" prop="description" class="task-desc fullwidth">
                    <!-- <el-input v-model="addTaskForm.description" :rows="2" type="textarea" placeholder="Enter description..."/> -->
					<av-rich-text :taskCreateForm="true" v-model="addTaskForm.description" ref="richTextEditor"></av-rich-text>
				</el-form-item>
                <el-form-item label="Owners" prop="assigneedto">
                    <el-select multiple v-model="addTaskForm.collaboratorIds" placeholder="Select owners" @change="onownersChange">
                        <el-option v-for="(orgMembers,index) in filteredOwnMembers" :key="index" :label="orgMembers.user.firstName" :value="orgMembers.user.userId"></el-option>
                    </el-select>
				</el-form-item>
                <el-form-item label="Priority" prop="priority">
                    <el-select v-model="addTaskForm.taskPriorityId"  placeholder="Select Priority" @change="onPriorityChange">
                        <el-option v-for="(priority,index) in priorities" :key="index" :label="priority.priorityLabel" :value="priority.taskPriorityId"></el-option>
                    </el-select>
				</el-form-item>
                <el-form-item label="Due Date" prop="dueDate">
                    <el-date-picker v-model="addTaskForm.dueDate" type="date" ></el-date-picker>
				</el-form-item>
				<el-form-item class="fullwidth">
					<!-- <div class="attachment-container flex--row flex--acjc">
						{{$t('av.global.attachments.placeholder')}}
					</div> -->
					<el-upload class="attachment-container flex--col flex--acjc" action="#" :show-file-list="false" multiple :auto-upload="false"
						:on-remove="removeFile" @input="uploadFileList">
					<div class="attachment-container-text">
						Drop file here or <em>click to upload</em>
					</div>
					<template #tip>
						<span class="file-list" v-for="(file, index) in fileListArr" :key="file">
							<span class="cp" v-if="showFullFileList">{{ file.name }}</span>
							<template v-else>
								<span class="cp" v-if="index <= '2'">{{ file.name }}</span>
							</template>
						</span>
						<span class="cp" v-if="showFullFileList" @click="showFullFileList = false">Less</span>
						<template v-else>
							<span class="cp" v-if="fileListArr.length >= '4' " @click="showFullFileList = true">+ {{ fileListArr.length - 3 }} More</span>
						</template>
					</template>
				</el-upload>
				</el-form-item>
                <el-form-item label="Followers" prop="followers" >
                    <el-select multiple v-model="addTaskForm.followerIds" placeholder="Select Followers" @change="onFollowersChange">
                        <el-option v-for="(orgMembers,index) in filteredOwnMembers" :key="index" :label="orgMembers.user.firstName" :value="orgMembers.user.userId"></el-option>
                    </el-select>
				</el-form-item>
				<el-form-item label="Status" prop="status" >
                    <el-select v-model="addTaskForm.taskStatusId" placeholder="Select Status" @change="onStatusChange">
                        <el-option v-for="(status,index) in statuses" :key="index" :label="status.statusName" :value="status.taskStatusId"></el-option>
                    </el-select>
				</el-form-item>
			</el-form>
			<div class="footer pA flex--row flex--ac">
				<el-button size="small" @click="closeForm">{{$t('av.global.cancel')}}</el-button>
				<el-button type="primary" size="small" @click="submitForm('addTaskForm')">{{$t('av.tasks.add')}}</el-button>
			</div>
		</el-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avRichTextEditor from '@/components/av-richtexteditor.vue';

const orgState = createNamespacedHelpers('organizations');
const usersState = createNamespacedHelpers('users');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const statusesState = createNamespacedHelpers('taskstatuses');


export default {
	emits: ["onClose", "createTask"],
	components : {
		'av-rich-text' : avRichTextEditor
	},
	data() {
		return {
			richTextVal : '',
			openAddTaskForm : true,
			fileListArr : [],
			showFullFileList : false,
			addTaskForm: {
				taskTitle: '',
				description : '',
				collaboratorIds : [],
				owners : [],
				taskPriorityId : '',
				priority : null,
				dueDate : '',
				followerIds : [],
				followers : [],
				status : {},
				taskStatusId : ''
			},
			addTaskFormRules : {
				taskTitle: [
					{
						required: true,
						message: 'Please input Task Name',
						trigger: 'blur'
					},
					{
						min: 3,
						message: 'Length should be minimum 3 characters',
						trigger: 'blur'
					}
				]
			}
		};
	},
	created (){
		this.$store.dispatch('users/getAll');
		this.addTaskForm.status = this.statuses.find(s => s.statusName === "Open");
		this.addTaskForm.owners = this.currentOrg.members.filter( (member) => { return member.user.userId === this.currentAuthUser.userId; });
		
	},
	mounted(){

	},
	computed: {
		...orgState.mapState([
			'allOrganization',
			'currentOrg'
		]),
		...usersState.mapState([
			'currentAuthUser'
		]),
		...prioritiesState.mapState([
			'priorities'
		]),
		...statusesState.mapState([
			'statuses'
		]),
		filteredOwnMembers(){
            return this.currentOrg.members.filter((member) => {
                if(member.user.firstName !== null){
                    return member;
                }
            });
        }
	},
	methods : {

		uploadFileList(file){
			this.fileListArr = file.target.files;
			console.log(this.fileListArr);
		},
		removeFile(file){
			console.log(file);
		},

		onownersChange(newValueArr){
			this.addTaskForm.owners = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
		},

		onPriorityChange(newValue){
			this.addTaskForm.priority = this.priorities.find(p => p.taskPriorityId === newValue);
		},

		onFollowersChange(newValueArr){
			this.addTaskForm.followers = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
			// this.addTaskForm.followers = newValueArr.map((nv) => { return {orgUserId : this.users.find(u => u.userId === nv).userId};});
		},

		onStatusChange(newValue){
			this.addTaskForm.status = this.statuses.find(s => s.taskStatusId === newValue);
		},

		getFilteredDataForSubmit(){
			let tempAttrs = ['collaboratorIds', 'taskPriorityId', 'followerIds', 'taskStatusId'];
			let clonedData = Object.assign({}, this.addTaskForm);
			tempAttrs.forEach(ta => delete clonedData[ta]);
			clonedData.dueDate = new Date(clonedData.dueDate).getTime();
			let quill = this.$refs.richTextEditor.$refs.quillEditor.getQuill();
			let descContent = encodeURI(quill.root.innerHTML);
			clonedData.description = descContent;
			return clonedData;
		},

		submitForm(formName){
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					
					let newTask = await this.$store.dispatch('tasks/create', this.getFilteredDataForSubmit());
					this.fileListArr.forEach(async (file) => {
						let taskAttach = [];
						taskAttach.taskId = newTask.data.taskId;
						taskAttach.file = file;
						await this.$store.dispatch('tasks/uploadFile', taskAttach);
					});

					this.$refs[formName].resetFields();
					this.openAddTaskForm = false;
					this.fileListArr = [];
					this.$emit('createTask');

				} else {
					return false;
				}
			});
			
		},

		afterFormClosed(){
			this.$emit('onClose');
		},

		closeForm(){
			this.openAddTaskForm = false;
		}
	}
};
</script>
<style>
.add-task-form.el-drawer{
	display: flex;
	flex-direction: column;
}

.add-task-form > section.el-drawer__body{
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
	padding-bottom: 3.5rem;
	position: relative;
}
.add-task-form > section > .hdr{
	padding: 1rem 1.5rem;
}
.add-task-form > section > .footer{
	width: 100%;
    padding: 0 1.5rem;
    bottom: 0;
    background: var(--color-14);
    height: 3.5rem;
}
.add-task-form > section > form{
	padding: 0 1rem;
	overflow: auto;
}
.add-task-form .el-form > .el-form-item{
	width : 50%;
	padding : 0.5rem 1%;
	margin : 0;
}
.add-task-form .el-form > .el-form-item .el-select,
.add-task-form .el-form > .el-form-item .el-date-editor.el-input,
.add-task-form .el-form > .el-form-item.fullwidth{
	width: 100%;
}
.add-task-form .el-form > .el-form-item.fullwidth .file-list{
	width: 50%;
	text-align: center;
}
.add-task-form .el-form > .el-form-item.task-desc .el-form-item__content{
	line-height : 10px;
}
.add-task-form .el-form > .el-form-item.fullwidth .file-list span{
	white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}
.add-task-form .attachment-container{
	/* height: 5rem; */
	width: 100%;
	background: var(--color-14);
	border: 0.0625rem dashed var(--color-15);
	border-radius: 0.25rem;
	color: var(--color-16);
	padding: 10px;
}
/* .add-task-form .attachment-container .attachment-container-text{
	text-align: center;
} */
.add-task-form .el-form > .el-form-item > .el-form-item__label{
	color: inherit;
	padding-bottom: 0;
}
</style>