<template>
    <ul id="taskprvw-nav" class="taskprvw-nav txt14">
        <template v-for="task in tasks" :key="task.taskId">
            <li class="task-itms flex--row flex--ac cp clip-txt" :class="{'selected' :selectedTaskId === task.taskId}">
                <router-link :to="'/tasks/'+task.taskId" class="flex--one">
                    <span class="status dIB" :style="{ backgroundColor: task.status.color }"></span>
                    <span class="key-fld clip-txt"> {{ task.taskTitle }} </span>
                </router-link>
            </li>
        </template>
        
    </ul>
</template>
<script>

export default {

    data(){
        return{
            subtaskDelete: null,
            delSubVisible: null,
            subtaskObj: {}
        };
    },
	props : {
		tasks : Array,
		selectedTaskId : String


	}
};
</script>
<style scoped>
.taskprvw-nav > li.task-itms{
    /* height: 3.25rem; */
    /* padding-right: 1.5rem; */
    width: 100%;
}
.taskprvw-nav:not(.subtasks) > li.task-itms{
    opacity: 0.6;
}
/* .taskprvw-nav.subtasks > li{
    border-bottom: 0.0625rem solid var(--color-9);
} */
.taskprvw-nav.subtasks > li.add-sub-task{
    height: 2.25rem;
    padding-left: 1.5rem;
    color: var(--color-7);
}
.taskprvw-nav > li.task-itms.selected,
.taskprvw-nav > li.task-itms:hover{
    background : var(--color-9);
    opacity: 1;
}
.taskprvw-nav > li.task-itms > a[href]{
    padding: 1rem 1.5rem;
    width: 82%;
    display: flex;
    text-decoration: none;
}
.taskprvw-nav > li.task-itms > a[href] > .status{
    height: 0.875rem;
    width: 0.875rem;
    border-radius : 0.125rem;
}
.taskprvw-nav > li.task-itms > a[href] > .key-fld {
    padding-left: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    width: 95%;
}

</style>