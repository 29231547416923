<template>
    <div class="avtasks-kanban flex--row flex--one-oflw">

		<!-- Priority Group -->
		<template v-if="isPriority" >
			<div class="grpby-ctnr flex--col" v-for="(priority, index) in priorities" :key="index">
				<template v-if="tasksForPriorityMap[priority.taskPriorityId]">
					<div class="grpby-hdr flex--ac" :class="[isCollapsed(priority.taskPriorityId) ? 'flex--col grp-empty' : 'flex--row' ]">
						<span class="status-indictr dIB" :style="{ backgroundColor: priority.color }"></span>
						<span class="grpby-lbl font-med">
							{{priority.priorityLabel}}
						</span>
						<div class="grpby-rpnl" :class="[isCollapsed(priority.taskPriorityId) ? 'flex--col flex--ac' : 'flex--ac flex--row mlA' ]">
							<span class="rpnl-itms grpby-count flex--row flex--acjc txt12">{{tasksForPriorityMap[priority.taskPriorityId].length}}</span>
							<av-icon class="cp rpnl-itms rpnl-icon regular" name="more-horizontal"></av-icon>
							<av-icon class="cp rpnl-itms rpnl-icon regular" name="add-plus-24" @click="openInlineAddTask(priority.taskPriorityId)" ></av-icon>
						</div>
					</div>
					<div class="kanban-task-list flex--one-oflw" :ref="'kanbanList'+priority.taskPriorityId">
						<draggable tag="ul" v-if="!isCollapsed(priority.taskPriorityId)"
							v-model="tasksForPriorityMap[priority.taskPriorityId]"
							group="task"
							@start="drag=true"
							@end="end"
							:move="checkMove"
							item-key="group"
							animation="200"
							class="grpby-list txt14 flex--one-oflw"
							>
								<template #item="{element}">
									<li class="grpby-list-itm flex--col flex-sbw">
										<kanban-card :taskInput="element"></kanban-card>
									</li>
								</template>
						</draggable>

						<template v-if="AddInlineTaskKanban === priority.taskPriorityId">
							<div class="InlineKanbanAddTask">
								<label class="ttl txt14">TaskTitle :</label><br>
								<el-input ref="inlineAddtaskKbn"  v-model="inlineTaskTitle" size="small" autosize resize="none" type="textarea" placeholder="Please input"/>
								<div class="confirm-box">
									<el-button class="cp" type="primary" @click="AddInlineTask(priority.taskPriorityId)">Save</el-button>
									<span class="cp" @click="cancelInlineAddTask">X</span>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="add-task txt14 flex--row flex--ac cp" @click="openInlineAddTask(priority.taskPriorityId)">
								+ {{ $t("av.tasks.add") }}
							</div>
						</template>
					</div>

				</template>
			</div>
		</template>

		<!-- Status Group -->
		<div v-else class="grpby-ctnr flex--col" v-for="(status, index) in statuses" :key="index">
			<template v-if="taskForBoardView[status.taskStatusId]">
				<div class="grpby-hdr flex--ac" :class="[isCollapsed(status.taskStatusId) ? 'flex--col grp-empty' : 'flex--row' ]">
					<span class="status-indictr dIB" :style="{ backgroundColor: status.color }"></span>
					<span class="grpby-lbl font-med">
						{{status.statusName}}
					</span>
					<div class="grpby-rpnl" :class="[isCollapsed(status.taskStatusId) ? 'flex--col flex--ac' : 'flex--ac flex--row mlA' ]">
						<span class="rpnl-itms grpby-count flex--row flex--acjc txt12">{{taskForBoardView[status.taskStatusId].length}}</span>
						<av-icon class="cp rpnl-itms rpnl-icon regular" name="more-horizontal"></av-icon>
						<av-icon class="cp rpnl-itms rpnl-icon regular" name="add-plus-24" @click="openInlineAddTask(status.taskStatusId)" ></av-icon>
					</div>
				</div>
				<div class="kanban-task-list flex--one-oflw" :ref="'kanbanList'+status.taskStatusId">
					<draggable tag="ul" v-if="!isCollapsed(status.taskStatusId)"  :disabled="false"
						v-model="taskForBoardView[status.taskStatusId]" group="task" @start="drag=true" @drop="dropTask(status.taskStatusId)" @end="end"
						:move="checkMove" item-key="group" animation="200"
						class="grpby-list txt14">
							<template #item="{element}">
								<li class="grpby-list-itm flex--col flex-sbw">
									<kanban-card :taskInput="element"></kanban-card>
								</li>
							</template>
							<template #footer>
					<template v-if="AddInlineTaskKanban === status.taskStatusId">
						<div class="InlineKanbanAddTask" ref="inlineFocusTab" tabindex="1">
								<label class="ttl txt14">TaskTitle :</label><br>
								<el-input type="textarea" ref="inlineAddtaskKbn" @keypress.enter="AddInlineTask(status.taskStatusId)"
									v-model="inlineTaskTitle" :autosize="{minRows: 2}" size="small" resize="none" placeholder="Enter task title.."/>

								<!-- <label class="txt14" for="duedate">Duedate :</label><br>
								<el-date-picker v-model="inlineTaskDuedate" type="date" placeholder="Pick a date" size="small"/><br>

								<label class="txt14" for="assignee">owners :</label><br>
								<el-select multiple v-model="setCollaboratorIds"  @change="onownersChange" placeholder="Select" size="small">
												<el-option  v-for="orgMembers in currentOrg.members"
												:key="orgMembers.user.userId"
												:label="orgMembers.user.firstName"
												:value="orgMembers.user.userId" />
								</el-select><br> -->
								<div class="confirm-box">
									<el-button class="cp" type="primary" @click="AddInlineTask(status.taskStatusId)">Save</el-button>
									<span class="cp" @click="cancelInlineAddTask">X</span>
								</div>
							
						</div>
					</template>
					<template v-else>
						<div class="add-task txt14 flex--row flex--ac cp" @click="openInlineAddTask(status.taskStatusId)">
							+ {{ $t("av.tasks.add") }}
						</div>
					</template>
					</template>
					</draggable>
					
				</div>

			</template>
		</div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('tasks');
const taskStatusState = createNamespacedHelpers('taskstatuses');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const orgState = createNamespacedHelpers('organizations');
const usersState = createNamespacedHelpers('users');

import avIcon from '@/components/av-icon.vue';
import draggable from 'vuedraggable';
import kanban_card from '@/components/kanban_card.vue';
// import avTaskAssignee from '@/components/av-task-assignee.vue';

export default {
	components : {
		'av-icon' : avIcon,
		draggable,
		'kanban-card': kanban_card
		// 'av-task-assignee' : avTaskAssignee
	},
	data() {
		return {
			drag : false,
			tasksForStatusMap : [],
			tasksForPriorityMap : [],
			isPriority : false,
			taskVsSubtaskCount : {
				1 : 12,
				2 : 37
			},
			setCollaboratorIds : [],
			draggedTask : {},
			changedStatus : '',
			AddInlineTaskKanban : null,
			inlineTaskTitle : '',
			inlineTaskDuedate : '',
			createTaskArr : {},
			owners : []
		};
	},
	watch : {
		CTProp(){
			this.getAllTasks(this.Queryparams);
		},
		changedGroupBy(){
			this.isPriority = !this.isPriority;
		}
	},
	props: {
		CTProp: {
			type: Boolean
		},
		Queryparams: {
			type: Object
		},
		changedGroupBy : {
			type: Boolean
		}
	},
	async created (){
		await this.getAllTasks();
		this.owners = this.currentOrg.members.filter( (member) => { return member.user.userId === this.currentAuthUser.userId; });
	},

	mounted(){
		
	},

	computed: {
		...mapState([
			'tasks',
			'fields'
		]),
		...mapGetters([
			'statusFieldObj',
			'taskForBoardView'
		]),
		...taskStatusState.mapState([
			'statuses'
		]),
		...prioritiesState.mapState([
			'priorities'
		]),
		...orgState.mapState([
			'currentOrg'
		]),
		...usersState.mapState([
			'currentAuthUser'
		])
	},
	methods : {

		async getAllTasks(sortQryparams){

			let queryParams = {};

			if(sortQryparams){
				queryParams = { sort : sortQryparams.sort, property : sortQryparams.property };
			}
			await this.$store.dispatch('tasks/getAll', {queryParams});
			// this.getAllTaskStatuses();
			// this.getAllTaskPriorities();

		},

		// getAllTaskStatuses (){
		// 	this.statuses.forEach((status) => {
		// 		this.tasksForStatusMap[status.taskStatusId] = this.tasks.filter(t => t.status.taskStatusId === status.taskStatusId);
		// 	});
		// 	console.log(this.taskForBoardView);
		// },

		getAllTaskPriorities(){
			this.priorities.forEach((priority) => {
				this.tasksForPriorityMap[priority.taskPriorityId] = this.tasks.filter(t => t.priority !== null && t.priority.taskPriorityId === priority.taskPriorityId);
				this.tasksForPriorityMap[4] = this.tasks.filter(t => t.priority === null);
			});

		},
	
		checkMove(evt){
			this.draggedTask = evt.draggedContext.element;
		},
		dropTask(id){

			if(this.isPriority === true){
				this.changedStatus = this.priorities.find(s => s.taskPriorityId === id);
				this.draggedTask.priority = this.changedStatus;
			}
			else{
				this.changedStatus = this.statuses.find(s => s.taskStatusId === id);
				this.draggedTask.status = this.changedStatus;
			}
			
		},
		end(){

			if(Object.entries(this.draggedTask).length){
				this.drag = false;
				this.$store.dispatch("tasks/updateTaskDetails", this.draggedTask);
				this.draggedTask = {};
			}
		},
		isCollapsed(){
			// return !this.tasksForStatusMap[statusId].length;
			// console.log(!this.tasksForStatusMap[statusId].length);
			// return false;
		},
		openInlineAddTask(statusId){

			this.AddInlineTaskKanban = statusId;
			setTimeout(()=>{
				let refVal = "kanbanList";
				refVal += statusId;
				this.$refs.inlineAddtaskKbn.focus();
				let mainScrollContainer = this.$refs[refVal];
				mainScrollContainer.scroll({top: mainScrollContainer.scrollHeight, behavior: 'smooth'});
				let element = document.querySelectorAll('.InlineKanbanAddTask');
				element.forEach((image) => {
					image.addEventListener('blur', this.blurEvent);
				});
				console.log(element);

			}, 0);

		},

		blurEvent(){
			console.log('blue event');
		},

		cancelInlineAddTask(){
			this.AddInlineTaskKanban = null;
			this.inlineTaskTitle = '';
			this.inlineTaskDuedate = '';
			
		},

		onownersChange(newValueArr){
			this.owners = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
		},

		async AddInlineTask(statusId){
			if(this.inlineTaskTitle.trim()){

				this.createTaskArr.taskTitle = this.inlineTaskTitle.trim();
				// this.createTaskArr.dueDate = new Date(this.inlineTaskDuedate).getTime();
				this.createTaskArr.status = this.statuses.find(s => s.taskStatusId === statusId);
				this.createTaskArr.owners = this.owners;

				this.AddInlineTaskKanban = null;
				await this.$store.dispatch('tasks/create', this.createTaskArr);
				// this.getAllTaskStatuses();

				this.inlineTaskTitle = '';
				// this.inlineTaskDuedate = '';
				// this.owners = [];
			}
			else{
				this.$refs.inlineAddtaskKbn.focus();
			}
			
		}
	}
};
</script>
<style scoped>
.avtasks-kanban > .grpby-ctnr{
	padding: 0 0.625rem;
	min-width: 18.75rem;
}
.avtasks-kanban > .grpby-ctnr > .grpby-hdr{
	padding: 0 1rem;
	background-color: var(--color-white);
}
.avtasks-kanban > .grpby-ctnr > .grpby-hdr:not(.grp-empty){
	height: 3rem;
	/* width: 18.75rem; */
	margin-bottom: 5px;
	padding: 0 1rem;
	background-color: var(--color-white);
	box-shadow: 0px 1px 3px rgb(165 165 165 / 50%);
}
.avtasks-kanban > .grpby-ctnr > .grpby-hdr.grp-empty{
	height: 100%;
	padding: 1rem;
}
.grpby-ctnr > .kanban-task-list{
	overflow: auto;
	flex: 1;
}
.grpby-ctnr > .kanban-task-list .add-task {
	border-radius: 4px;
	margin: 0.25rem 0;
	background-color: var(--color-white);
	padding: 1rem 0.625rem;
	box-shadow : 0px 1px 3px rgba(165, 165, 165, 0.5);
	color: var(--color-7);
	padding-left: 1rem;
}
.avtasks-kanban .grpby-hdr > .status-indictr{
	height: 0.875rem;
	width: 0.875rem;
	border-radius: 0.125rem;
}
.avtasks-kanban .grpby-hdr:not(.grp-empty) > .grpby-lbl{
	padding-left: 1rem;
}
.avtasks-kanban .grpby-hdr.grp-empty > .grpby-lbl{
	writing-mode: vertical-lr;
	padding: 1rem 0;
}
.avtasks-kanban .grpby-hdr:not(.grp-empty) > .grpby-rpnl > .rpnl-itms:not(:first-child){
	margin-left: 0.5rem;
}
.avtasks-kanban .grpby-hdr > .grpby-rpnl > .rpnl-icon{
	color: var(--color-6);
}
.avtasks-kanban .grpby-hdr > .grpby-rpnl > .grpby-count {
	height: 1rem;
	width: 1.625rem;
	background-color: var(--color-13);
	border-radius: 0.375rem;
	color: var(--color-white);
}

.avtasks-kanban > .grpby-ctnr .grpby-list{
	/* width: 18.75rem; */
	padding-top: 10px;
	height: 100%;
}

.avtasks-kanban > .grpby-ctnr .grpby-list > li.grpby-list-itm{
	width: 100%;
	min-height: 5.75rem;
	margin: 0.25rem 0;
	background-color: var(--color-white);
	padding: 1rem 0.625rem;
	box-shadow : 0px 1px 3px rgba(165, 165, 165, 0.5);
}

.avtasks-kanban > .grpby-ctnr li.grpby-list-itm > .list-itm-misc{
	color: var(--color-7);
}
.avtasks-kanban li.grpby-list-itm > .list-itm-misc .av-icon{
	margin-right: 0.25rem;
	color: inherit;
}

.grpby-ctnr > .kanban-task-list .InlineKanbanAddTask{
	width: 100%;
    margin: 0.25rem 0;
    background-color: var(--color-white);
    padding: 1rem 0.625rem;
    box-shadow: 0px 1px 3px rgb(165 165 165 / 50%);
	border-radius: 5px;
}

.grpby-ctnr > .kanban-task-list .InlineKanbanAddTask .el-textarea{
	/* width: 75%; */
}
.grpby-ctnr > .kanban-task-list .InlineKanbanAddTask .el-date-editor.el-input{
	width: 75%;
}
.grpby-ctnr > .kanban-task-list .InlineKanbanAddTask .confirm-box{
	margin-top: 15px;
    display: flex;
    align-items: center;
}
.grpby-ctnr > .kanban-task-list .InlineKanbanAddTask .confirm-box button.el-button--primary{
	margin-right: 30px;
}
.ttl:after{
	content:" *";
	color: red;
}
</style>