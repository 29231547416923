<template>
    <el-dropdown trigger="click" @command="onTaskCategoryChange">
         <div class="cp flex--ac flex--row">
            <span class="txt13">{{ message }}</span>
            <av-icon class="cp icon" name="triangle-arrow-down"></av-icon>
        </div>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item :command="{category: 'AllTask', message: 'All Tasks'}" class="flex--row flex--ac txt14 cp">
                    <span>All Tasks</span>
                </el-dropdown-item>
                <el-dropdown-item :command="{category: 'Open', message: 'Open Tasks'}" class="flex--row flex--ac txt14 cp">
                    <span>Open Tasks</span>
                </el-dropdown-item>
                <el-dropdown-item :command="{category: 'Closed', message: 'Completed Tasks'}" class="flex--row flex--ac txt14 cp">
                    <span>Completed Tasks</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';

const statusesState = createNamespacedHelpers('taskstatuses');
const usersState = createNamespacedHelpers('users');

export default {
    emits: ["OpenTasks", "AllTasks"],

    created(){
        this.message = this.preferenceForUser.userPreferences.taskPreference.taskCategory;
        // let due = 1678991400000;
        // let decodeStatus = `{"dueDate" : ${due}}`;
        // this.statusQryParams.searchCriteria = encodeURI(decodeStatus);
        // console.log(encodeURI(decodeStatus));
    },

    data(){
        return {
            message : '',
            statusQryParams : {},
            userpreData : []

        };
    },
	components : {
		'av-icon' : avIcon
	},

    computed : {
        ...usersState.mapState([
			'currentAuthUser',
			'preferenceForUser'
		]),
        ...statusesState.mapState([
			'statuses'
		])
    },

    methods : {

    async onTaskCategoryChange(command){

        // this.$emit('OpenTasks');
        // this.$emit('AllTasks');
        this.message = command.message;
        if(command.category === 'AllTask'){
            this.$store.dispatch('tasks/getAll');

            this.preferenceForUser.userPreferences.taskPreference.taskCategory = command.message;
            this.preferenceForUser.userPreferences.taskPreference.searchCriteria = '';
            this.userpreData.userId = this.currentAuthUser.userId;
            this.userpreData.preference = this.preferenceForUser;
            this.$store.dispatch('users/updateUserPreference', this.userpreData);
            return;
        }

        let status = this.statuses.find((status) => { return status.statusCategory === command.category; });
        let decodeStatus = `{"statusIds" : [${status.taskStatusId}]}`;
        this.statusQryParams.searchCriteria = decodeURI(decodeStatus);
        await this.$store.dispatch('tasks/getAll', {queryParams : this.statusQryParams});
        this.statusQryParams = {};

        this.preferenceForUser.userPreferences.taskPreference.taskCategory = command.message;
        this.preferenceForUser.userPreferences.taskPreference.searchCriteria = command.category;
        this.userpreData.userId = this.currentAuthUser.userId;
        this.userpreData.preference = this.preferenceForUser;
        await this.$store.dispatch('users/updateUserPreference', this.userpreData);
        this.userpreData = [];

    },

    showOpenTasks() {
        
    },

    showAllTasks(){
        this.$emit('AllTasks');
        this.message = 'All Tasks';
    }

    }
};
</script>
<style>
.txt13{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
</style>