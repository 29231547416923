<template>
  <div class="filter-view">
    <div class="filt-head flex--row">
        <av-icon class="filtIcon cp" name="icon-filter-24"></av-icon>
        <span>Apply Filter</span>
    </div>
    <div class="filt-detail">
        <el-form :model="createFilter" label-position="top" size="medium">
            <el-form-item label="Owners" prop="owners">
                <el-select v-model="createFilter.ownerIds" placeholder="Select owners">
                    <el-option v-for="(orgMembers,index) in filteredOwnMembers" :key="index" :label="orgMembers.user.firstName" :value="orgMembers.user.userId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Priority" prop="priority">
                <el-select v-model="createFilter.priorityId"  placeholder="Select Priority">
                    <el-option v-for="(priority,index) in priorities" :key="index" :label="priority.priorityLabel" :value="priority.taskPriorityId"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';
const orgState = createNamespacedHelpers('organizations');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const statusesState = createNamespacedHelpers('taskstatuses');

export default {
    components : {
        'av-icon' : avIcon
    },
    
    data(){
        return {
            createFilter: {
                ownerIds : []
			}
        };
    },

    computed : {

        ...orgState.mapState([
			'allOrganization',
			'currentOrg'
		]),
        ...prioritiesState.mapState([
			'priorities'
		]),
		...statusesState.mapState([
			'statuses'
		]),

        filteredOwnMembers(){
            return this.currentOrg.members.filter((member) => {
                if(member.user.firstName !== null){
                    return member;
                }
            });
        }
    }

};
</script>

<style>
.filter-view > .filt-head > .filtIcon{

}
.filter-view > .filt-head{
    padding: 15px 25px;
    border-bottom: 1px solid #ada9a9ab;
}
.filter-view > .filt-detail{
    padding: 15px 25px;
}
.filter-view > .filt-detail .el-form > .el-form-item{
    margin-bottom: 12px;
}
.filter-view > .filt-detail .el-form > .el-form-item > .el-form-item__label{
	color: inherit;
	padding-bottom: 0;
}
.filter-view > .filt-detail .el-form > .el-form-item  .el-select{
	width: 100%;
}
</style>