<template>
    <div class="av-task-list-view flex--one-oflw">
            <div v-if="bulkList" class="flex--row flex--ac bulk-list-view">
                    <div class="checkall"><input style="margin-right : 10px" type="checkbox" @click="checkAll()" v-model="selectAll">
                    <span> Select all</span>
                    </div>
                        <div class="blklist-arr">
                            <template v-for="list in bulkListArr" :key="list">
                                <span @click="check(list)" >{{ list.type }}</span>
                            </template>
                                <el-dialog
                                    v-if="bulkdltpopup === 1"
                                    v-model="bulkdltpopup"
                                    title="Delete Tasks"
                                    width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                        <span>Are you sure you want to delete this tasks?</span>
                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="bulkdltpopup = false">Cancel</el-button>
                                                <el-button  type="primary" @click="deletebulktask">Confirm</el-button>
                                            </span>
                                        </template>
                                    </el-dialog>

                                <el-dialog @close="closeStatusDial"
                                    v-if="bulkdltpopup === 2"
                                    v-model="bulkdltpopup"
                                    title="Update Status"
                                    width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                    <span class="sts-txt">Change the status :</span>
                                    <el-select class="status-slt-popup" v-model="statusValue" size="small" clearable placeholder="Select the Status">
                                            <el-option
                                            v-for="status in statuses"
                                            :key="status.statusName"
                                            :label="status.statusName"
                                            :value="status.taskStatusId"
                                            />
                                        </el-select>
                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="closeStatusDial">Cancel</el-button>
                                                <el-button  type="primary" @click="changeBulkStatus"
                                                    >Confirm changes</el-button
                                                >
                                            </span>
                                        </template>
                                    </el-dialog>

                                <el-dialog @close="closePriorityDial"
                                    v-if="bulkdltpopup === 3"
                                    v-model="bulkdltpopup"
                                    title="Update Priority"
                                    width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                    <span class="sts-txt">Change the priority :</span>
                                    <el-select class="status-slt-popup" v-model="priorityValue" size="small" clearable placeholder="Select the Priority">
                                            <el-option
                                            v-for="priority in priorities"
                                            :key="priority.priorityLabel"
                                            :label="priority.priorityLabel"
                                            :value="priority.taskPriorityId"
                                            />
                                        </el-select>
                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="closePriorityDial">Cancel</el-button>
                                                <el-button  type="primary" @click="changeBulkPriority"
                                                    >Confirm changes</el-button
                                                >
                                            </span>
                                        </template>
                                    </el-dialog>

                                <el-dialog @close ="closeOwnerDial"
                                    v-if="bulkdltpopup === 4" v-model="bulkdltpopup" title="Owners" width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                        <span class="sts-txt">Assign owners :</span>
                                        <el-select class="status-slt-popup" v-model="ownersValue" multiple @change="onOwnersChange" size="small" clearable placeholder="Select the Owners">
                                            <el-option v-for="orgMembers in filteredOwnMembers" :key="orgMembers.user.userId" :label="orgMembers.user.firstName"
                                                    :value="orgMembers.user.userId">
                                            </el-option>
                                        </el-select>
                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="closeOwnerDial">Cancel</el-button>
                                                <el-button  type="primary" @click="setBulkOwners"
                                                    >Confirm</el-button
                                                >
                                            </span>
                                        </template>
                                    </el-dialog>

                                <el-dialog @close ="closeDueDateDial"
                                    v-if="bulkdltpopup === 5"
                                    v-model="bulkdltpopup"
                                    title="Update Duedate"
                                    width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                    <span>Change the dueDate :</span>
                                            <el-date-picker class="due-date-elmt" v-model="dueDateValue" type="date"
                                                placeholder="Select due date"
                                                size="medium"
                                                format="DD-MMM-YY"
                                            />
                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="closeDueDateDial">Cancel</el-button>
                                                <el-button  type="primary" @click="setBulkDuedate"
                                                    >Confirm changes</el-button
                                                >
                                            </span>
                                        </template>
                                    </el-dialog>

                                <el-dialog v-if="bulkdltpopup === 6" v-model="bulkdltpopup"
                                    @closed="afterTagclose"
                                    title="Add the tags"
                                    width="30%"
                                    :close-on-click-modal="false"
                                    :close-on-press-escape="false"
                                    >
                                    <div class="uxTagBox">
                                        <el-tag  class="tag-chip"
                                            v-for="tag in addTag" :key="tag" @close="deleteTag(tag)" round effect="dark" closable size="small" >
                                            {{tag}}
                                        </el-tag>

                                        <el-select ref="trim" @change="tagValue" no-data-text=" " multiple filterable allow-create default-first-option
                                                :reserve-keyword="false"
                                                placeholder="Add tags for your bulk tasks"
                                            >
                                                <el-option
                                                v-for="tag in filteredTagsOption"
                                                :key="tag.tagName"
                                                :label="tag.tagName"
                                                :value="tag.tagName"
                                                />
                                            </el-select>
                                    </div>

                                        <template #footer>
                                            <span class="dialog-footer">
                                                <el-button @click="afterTagclose">Cancel</el-button>
                                                <el-button  type="primary" @click="updateTags"
                                                    >Confirm changes</el-button
                                                >
                                            </span>
                                        </template>
                                    </el-dialog>

                                    <el-dialog @close="closeFollowerDial"
                                        v-if="bulkdltpopup === 7" v-model="bulkdltpopup" title="Add task followers"
                                        width="30%"
                                        :close-on-click-modal="false"
                                        :close-on-press-escape="false"
                                        >
                                        <span style="margin-right : 5px;" >Select the follower :</span>
                                                  <el-select multiple v-model="followerId" size="small" @change="setFollowers" clearable placeholder="Select">
                                                    <el-option v-for="(orgMembers,index) in filteredOwnMembers" :key="index"
                                                        :label="orgMembers.user.firstName" :value="orgMembers.user.userId"></el-option>
                                                  </el-select>
                                            <template #footer>
                                                <span class="dialog-footer">
                                                    <el-button @click="closeFollowerDial">Cancel</el-button>
                                                    <el-button  type="primary" @click="setBulkUpdate" >Confirm changes</el-button>
                                                </span>
                                            </template>
                                    </el-dialog>

                                    <el-dialog @close ="this.setParentId = ''"
                                        v-if="bulkdltpopup === 8"
                                        v-model="bulkdltpopup"
                                        title="Convert task to subtask"
                                        width="30%"
                                        :close-on-click-modal="false"
                                        :close-on-press-escape="false"
                                        >
                                        <span style="margin-right : 5px;" >Select the Parent Task :</span>
                                                  <el-select v-model="setParentId" size="small" clearable placeholder="Select">
                                                        <el-option
                                                            v-for="task in tasks"
                                                            :key="task.taskId"
                                                            :label="task.taskTitle"
                                                            :value="task.taskId"
                                                        />
                                                  </el-select>
                                            <template #footer>
                                                <span class="dialog-footer">
                                                    <el-button @click="bulkdltpopup = false">Cancel</el-button>
                                                    <el-button  type="primary" @click="convertSubtask"
                                                        >Confirm changes</el-button
                                                    >
                                                </span>
                                            </template>
                                    </el-dialog>
                        </div>
            </div>
            <div class="flex--oflw scrollBar-table">
                <table class="task-list" :class="[inlineAddEnabled ? 'tip-enable' : '']">
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="field in displayFields" :key="field.key" :class="{ 'user' : field['uiType'] === 2,'key-fld' : field.isDisplayKeyField }"
                                @click="onSortClick($event,field.sortable, field.key)">

                                <div class="flex--row flex--ac" :class="{ 'flex--acjc' : field['uiType'] === 2,'cp' : field.sortable }">
                                    <!-- <template v-for="taskCol in preferenceForUser.userPreferences.taskColumns" :key="taskCol"> -->
                                        <span :class="[field.isDisplayKeyField ? 'key-fld' : '']">
                                            {{field.text}}
                                        </span>
                                    <!-- </template> -->
                                    
                                    <template v-if="field.sortable">
                                            <div v-if="sortQryParams.property !== field.key" class="sort-dflt-icon flex--col flex--acjc">
                                                <av-icon class="cp srt-icon medium rot-180" name="arrow-style2-down"></av-icon>
                                                <av-icon class="cp srt-icon medium" name="arrow-style2-down"></av-icon>
                                            </div>
                                            <av-icon v-else class="cp srt-icon medium" name="arrow-right" :class="[sortQryParams.sort==='asc' ? 'rot-270' : 'rot-90']" ></av-icon>
                                    </template>
                                </div>

                            </th>
                        </tr>
                    </thead>
                    <tbody class="txt14" >
                        <template v-for="task in tasks" :key="task.taskId" >
                            <tr v-if="preferenceForUser.userPreferences.taskPreference.searchCriteria.length ?
                            task.status.statusCategory === preferenceForUser.userPreferences.taskPreference.searchCriteria : task.status.statusCategory !== preferenceForUser.userPreferences.taskPreference.searchCriteria ">
                                <td class="icon-tab">
                                    <av-icon @click="taskClosed(task)" class="regular tick-icn cp" :style="[task.status.statusName === 'Closed' ? 'color: #8EB65F' : '']" name="done-outline"></av-icon>
                                    <input class="cp" type="checkbox" @change="updateCheckAll(task.taskId)" v-model="selected" :value="task.taskId" number>
                                </td>
                                <td v-for="field in displayFields" :key="field.key" :class="{ 'user' : field['uiType'] === 2,'key-fld' : field.isDisplayKeyField }">
                                    <div v-if="field.uiType === 3 " class="tag-chips-hldr">
                                        <template v-if="task[field.key] && task[field.key].length > 0">
                                            <el-tag v-for="tag in task[field.key]" :key="tag.taskTagId" effect="dark" size="mini" class="tag-chip"
                                                :style="{ backgroundColor: tag.color }">
                                                {{tag.tagName}}
                                            </el-tag>
                                        </template>
                                    </div>

                                    <div v-else-if="field.isDisplayKeyField" class="flex--row flex--ac">
                                        <router-link :to="'/tasks/'+task.taskId">
                                            <span class="task-lst-ttl flex--row flex--absl">
                                                <span class="status dIB" :style="{ backgroundColor: task[statusFieldObj.key].color }"></span>
                                                <span :title="task[field.key]" class="key-fld dIB clip-txt">{{task[field.key]}}</span>
                                            </span>
                                        </router-link>
                                    </div>
                                    
                                    <template v-else-if="field.uiType === 6">
                                        <el-date-picker v-if="editStartDateInp == task.taskId ? true : false " :editable="false" popper-class="stDateClass" class="datePicker cp" ref="startDateInp"
                                            @blur="updateStartDateForTask(task.taskId)" @change="this.$refs.startDateInp.focus()" format="DD/MM/YYYY" v-model="startDatePicker" type="date" />

                                        <template v-else>
                                            <div class="calIcon-div cp flex--row" v-if="task[field.key] === null" @click="openStartDateInp(task.taskId)">
                                                <av-icon  class ="calIcon cp icon medium" name="calendar-icon"></av-icon>
                                            </div>
                                            <div v-else class="date-div cp" @click="openStartDateInp(task.taskId)">
                                                <span class="date-time">{{$lxnFormatDate(task[field.key])}}</span>
                                            </div>
                                        </template>
                                    </template>
                                    
                                    <template v-else-if="field.uiType === 4">
                                        <el-date-picker v-if="editDueDateInp == task.taskId ? true : false " :editable="false" popper-class="dueDateClass" class="datePicker cp" ref="dueDateInp"
                                            @blur="updatedueDateForTask(task.taskId)" @change="this.$refs.dueDateInp.focus()" format="DD/MM/YYYY" v-model="dueDatePicker" type="date" />

                                        <template v-else>
                                            <div class="calIcon-div cp flex--row" v-if="task[field.key] === null" @click="openDueDateInp(task.taskId)">
                                                <av-icon  class ="calIcon cp icon medium" name="calendar-icon"></av-icon>
                                            </div>
                                            <div class="date-div cp" v-else @click="openDueDateInp(task.taskId)">
                                                <span class="date-time">{{$lxnFormatDate(task[field.key])}}</span>
                                            </div>
                                        </template>
                                    </template>

                                    <template v-else-if="field.uiType === 2">
                                        <el-select ref="ownerInpList" v-if="task.taskId === updOwnInp" popper-class="own-pop" v-model="existOwnArr" @change="ownersChange" @visible-change="updateOwnerInList" multiple filterable collapse-tags collapse-tags-tooltip>
                                            <el-option v-for="(orgMembers,index) in currentOrg.members" :key="index" :label="orgMembers.user.firstName" :value="orgMembers.user.userId"
                                            />
                                        </el-select>
                                        <template v-else>
                                            <span v-if="task[field.key].length === 0" class="own-icon flex--row flex--jc">
                                                <av-icon  @click="openOwnerList(task)" class = "cp icon large" name="account-circle-24"></av-icon>
                                            </span>
                                            <av-task-assignee v-else  @click="openOwnerList(task)" class="owner-fld" :taskId="task.taskId" :owners="task[field.key]"></av-task-assignee>
                                        </template>
                                    </template>

                                    <template v-else-if="field.uiType === 7">
                                        <span class="date-time">{{$lxnFormatDate(task[field.key])}}</span>
                                    </template>
                                    <template v-else-if="field.uiType === 5">
                                        <span>
                                            <span class="status status-key dIB" :style="{ backgroundColor: task[statusFieldObj.key].color }"></span>
                                            {{ task[field.key].statusName }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        {{task[field.key]}}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <!-- <tr class="inline-add" @blur="blurTab" :class="[inlineAddEnabled ? 'add-enabled' : '']" ref= "inlineTab" tabindex="1">
                            <template v-if="inlineAddEnabled" >
                                <td></td>
                                <td class="name pR pA-aftr key-fld" :data-tips="$t('av.tasks.inlineadd.tips')" @keydown.enter="addTask">
                                    <el-input :placeholder="$t('av.tasks.inlineadd.placeholder.name')" ref= "inlineInput" v-model="inlineAddName" ></el-input>
                                </td>
                                <td class="assignee">
                                        <el-select  v-if="openCollab" ref="selectCollab" multiple v-model="setOwnerInp" @visible-change="selectowner" collapse-tags collapse-tags-tooltip
                                            filterable placeholder="Search owner">
                                                <el-option v-for="orgMembers in currentOrg.members"
                                                    :key="orgMembers.user.userId"
                                                    :label="orgMembers.user.firstName"
                                                    :value="orgMembers.user.userId"
                                                />
                                        </el-select>
                                        <el-tooltip v-else :show-after=800 effect="dark" content="Owner" popper-class="tool-tip">
                                            <span class="flex--row flex--acjc">
                                                <av-icon  @click="openownerDD" class = "cp icon large" name="account-circle-24"></av-icon>
                                                <span class="own-len">{{this.owner.length ? `(${this.owner.length})` : ''}}</span>
                                            </span>
                                        </el-tooltip>
                                </td>
                                <td class="others" :colspan="displayFields.length - 2" >
                                    <div class="flex--row flex--ac flex--spa">
                                        <el-popover trigger="click" v-model:visible="priorityDropdown">
                                            <template #reference>
                                                <div class="flex--row flex--ac ">
                                                    <el-tooltip :show-after=800 effect="dark" content="Priority" popper-class="tool-tip">
                                                        <av-icon class="cp icon" name="flag-24"></av-icon>
                                                    </el-tooltip>
                                                    <span class="plb">{{ priorityName }}</span>
                                                </div>
                                            </template>
                                            <ul class="inlineadd-flag-pop" >
                                                <li class="flex--row flex--ac txt14 cp" v-for="priority in priorities"  @click="priorityDropdown = !priorityDropdown" :key="priority.taskPriorityId">
                                                    <av-icon class="cp icon" name="flag-24"></av-icon>
                                                    <span class="lbl" @click="inlineSetPriority(priority.taskPriorityId)">{{ priority.priorityLabel }}</span>
                                                </li>
                                            </ul>
                                        </el-popover>

                                        <el-tooltip :show-after=800 effect="dark" content="Calendar" popper-class="tool-tip">
                                            <el-date-picker class="inlinedatePicker icon cp" format="DD MMM" size="medium"  @change="this.$refs.inlineInput.focus()" v-model="inlineAddDue" type="date" ></el-date-picker>
                                        </el-tooltip>

                                        <el-upload ref="uploadRef" action="#" multiple :auto-upload="false"
                                            @input ="changeAttachFile"
                                            :show-file-list="false">
                                            <span class="flex--row flex--ac" >
                                            <el-tooltip :show-after=800 effect="dark" content="Drop files here" popper-class="tool-tip">
                                                <av-icon class="cp icon" name="attachment-24"></av-icon>
                                            </el-tooltip>
                                            <span class="own-len">{{this.fileList.length ? `(${this.fileList.length})` : ''}}</span>
                                            </span>
                                        </el-upload>

                                        <div class="close-icon" @click="openDeletepopup = true">
                                            <el-tooltip :show-after=800 effect="dark" content="Close" popper-class="tool-tip">
                                                <i class="fa fa-times-circle-o" aria-hidden="true" ></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </td>
                            </template>
                            <template v-else >
                                <td :colspan="displayFields.length + 1">
                                    <div class="add-task txt14 flex--row flex--ac cp" @click="enableInlineAdd">
                                        + {{ $t("av.tasks.add") }}
                                    </div>
                                </td>
                            </template>
                        </tr> -->
                    </tbody>
                </table>
            </div>
             <div class="ot-table inline-add flex--row flex--ac" @blur="blurTab" :class="[inlineAddEnabled ? 'add-enabled' : '']" ref= "inlineTab" tabindex="1">
                        <template v-if="inlineAddEnabled" >
                            <div class="name pR pA-aftr key-fld" :data-tips="$t('av.tasks.inlineadd.tips')" @keydown.enter="addTask">
                                <el-input :placeholder="$t('av.tasks.inlineadd.placeholder.name')" ref= "inlineInput" v-model="inlineAddName" ></el-input>
                            </div>

                            <div class="assignee">
                                    <el-select  v-if="openCollab" ref="selectCollab" multiple v-model="setOwnerInp" @visible-change="selectowner" collapse-tags collapse-tags-tooltip
                                        filterable placeholder="Search owner">
                                            <el-option v-for="orgMembers in filteredOwnMembers"
                                                :key="orgMembers.user.userId"
                                                :label="orgMembers.user.firstName"
                                                :value="orgMembers.user.userId"
                                            />
                                    </el-select>
                                    
                                    <span v-else class="flex--row flex--ac">
                                        <span v-if="owner && owner.length" class="flex--row own-len" @click="openownerDD">
                                            <template v-for="selectedOwner in owner" :key="selectedOwner">
                                                <template v-if="selectedOwner.user.profilePicURL === null" >
                                                        <span :style="{ 'background-color': selectedOwner.user.profilePicColor, 'color' : checkColorluminance(selectedOwner.user.profilePicColor) }"
                                                            class = "selectedOwnIcon flex--row flex--acjc cp">{{ selectedOwner.user.firstName.charAt(0).toUpperCase() }}</span>
                                                </template>

                                                <template v-else>
                                                    <span>
                                                        <img v-if="selectedOwner.user.provider !== 'local' && selectedOwner.user.profilePicName === null " class="selectedOwnIcon cp" :src='selectedOwner.user.profilePicURL'/>
                                                        <img v-else class="selectedOwnIcon" :src='locationOrigin + "/profilePic/" +  selectedOwner.user.profilePicURL'/>
                                                    </span>
                                                </template>
                                            </template>
                                        </span>
                                        <template v-else>
                                            <el-tooltip :show-after=800 effect="dark" content="Owner" popper-class="tool-tip">
                                                <av-icon  @click="openownerDD" class = "cp icon large" name="account-circle-24"></av-icon>
                                            </el-tooltip>
                                        </template>
                                    </span>
                            </div>

                            <div class="others" :colspan="displayFields.length - 2" >
                                <div class="flex--row flex--ac flex--sbw">
                                    <el-popover trigger="click" v-model:visible="priorityDropdown">
                                        <template #reference>
                                            <div class="flex--row flex--ac add-task-pri">
                                                <el-tooltip :show-after=800 effect="dark" content="Priority" popper-class="tool-tip">
                                                    <av-icon class="cp icon" name="flag-24"></av-icon>
                                                </el-tooltip>
                                                <span class="plb">{{ priorityName }}</span>
                                            </div>
                                        </template>
                                        <ul class="inlineadd-flag-pop" >
                                            <li class="flex--row flex--ac txt14 cp" v-for="priority in priorities"  @click="priorityDropdown = !priorityDropdown" :key="priority.taskPriorityId">
                                                <av-icon class="cp icon" name="flag-24"></av-icon>
                                                <span class="flex--row flex--one lbl" @click="inlineSetPriority(priority.taskPriorityId)">{{ priority.priorityLabel }}</span>
                                            </li>
                                        </ul>
                                    </el-popover>

                                    <el-tooltip :show-after=800 effect="dark" content="Calendar" popper-class="tool-tip">
                                        <el-date-picker class="inlinedatePicker icon cp" format="DD MMM" size="medium"  @change="this.$refs.inlineInput.focus()" v-model="inlineAddDue" type="date" ></el-date-picker>
                                    </el-tooltip>

                                    <el-upload class="flex--row attach-field" ref="uploadRef" action="#" multiple :auto-upload="false"
                                        @input ="changeAttachFile"
                                        :show-file-list="false">
                                        <span class="flex--row flex--ac" >
                                        <el-tooltip :show-after=800 effect="dark" content="Drop files here" popper-class="tool-tip">
                                            <av-icon class="cp icon" name="attachment-24"></av-icon>
                                        </el-tooltip>
                                        <span class="own-len">{{this.fileList.length ? `(${this.fileList.length})` : ''}}</span>
                                        </span>
                                    </el-upload>

                                    <div class="close-icon" @click="openDeletepopup = true">
                                        <el-tooltip :show-after=800 effect="dark" content="Close" popper-class="tool-tip">
                                            <i class="fa fa-times-circle-o" aria-hidden="true" ></i>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>

                        </template>

                        <template v-else >
                            <div :colspan="displayFields.length + 1" class="flex--row flex--one">
                                <div class="add-task txt14 flex--row flex--ac flex--one cp" @click="enableInlineAdd">
                                    + {{ $t("av.tasks.add") }}
                                </div>
                            </div>
                        </template>

            </div>
            <el-dialog
                v-model="openDeletepopup"
                title="Close the task"
                width="30%"
                :close-on-press-escape ="false"
                :close-on-click-modal ="false"
                :show-close ="false"
                >
                    <span>Are you sure want to close this inline add task?</span>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="openDeletepopup = false">Cancel</el-button>
                            <el-button  type="primary" @click="confirmCloseInlineTask"
                                >Confirm</el-button
                            >
                        </span>
                    </template>
            </el-dialog>
            <!-- <av-task-details v-if="drawer"></av-task-details> -->
        </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('tasks');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const statusesState = createNamespacedHelpers('taskstatuses');
const taskTagsState = createNamespacedHelpers('tasktags');
const orgState = createNamespacedHelpers('organizations');
const usersState = createNamespacedHelpers('users');
import avIcon from '@/components/av-icon.vue';
import avTaskAssignee from '@/components/av-task-assignee.vue';
// import avTaskdetails from '@/views/av-tasks-details-view.vue';
import { ElMessage } from 'element-plus';


export default {
	components : {
		'av-icon' : avIcon,
		'av-task-assignee' : avTaskAssignee
        // 'av-task-details' : avTaskdetails
	},
	data () {
		return {
            assigneeDropdown : false,
            priorityDropdown : false,
			inlineAddEnabled : false,
			inlineAddName : '',
			inlineAddDue : '',
            setpriority : null,
            priorityName : '',
			sortQryParams : {},
            openDeletepopup : false,
            statusparams : {
                status : {
                    taskStatusId : 1
                }
            },
            bulkListArr : [
                {
                    id : 1,
                    type : "Delete"
                },
                {
                    id : 2,
                    type : "Status"
                },
                {
                    id : 3,
                    type : "Priority"
                },
                {
                    id : 4,
                    type : "Owners"
                },
                {
                    id : 5,
                    type : "Due date"
                },
                {
                    id : 6,
                    type : "Add Tags"
                },
                {
                    id : 7,
                    type : "Add Followers"
                }
                // {
                //     id : 8,
                //     type : "Make as Subtask"
                // }
                // {
                //     id : 9,
                //     type : "Add Dependency"
                // }


            ],
            bulkList : false,
            bulkdltpopup : false,
            selected: [],
            selectAll : false,
            statusValue : '',
            priorityValue : '',
            ownersValue : [],
            dueDateValue : '',
            setParentId : '',
            updateBulkValue: {},
            updateBulkArr : {
                patch : [],
                tasks : []
            },
            deleteObj : {
                tasks : []
            },
            addTag : [],
            existsTagName : [],
            setTag : {},
            setOwners : [],
            setOwnerInp : [],
            owner : [],
            followerId : [],
            followersData : [],
            openCollab : false,
            changedStatus : {},
            fileList : [],
            taskAttach : {},
            editStartDateInp : null,
            editDueDateInp : null,
            startDatePicker : '',
            dueDatePicker : '',
            selectedTask : {},
            existOwnArr : [],
            updOwnInp :  null,
            changeOwn : [],
            locationOrigin : window.location.origin

		};
	},
	created (){
		this.$watch(
			() => this.$route.query,
			(toParams /*, previousParams */) => {
				this.sortQryParams = Object.assign(toParams);
				this.getAllTasks();
			}
		);
		this.sortQryParams = Object.assign(this.$route.query);
		this.getAllTasks();
        this.$store.dispatch("tasktags/getAll");

	},

	computed: {
		...mapState([
			'fields',
			'tasks'
		]),
        ...mapGetters([
			'displayFields',
			'statusFieldObj',
            'taskForBoardView'
		]),
        ...prioritiesState.mapState([
			'priorities'
		]),
        ...statusesState.mapState([
			'statuses'
		]),
        ...taskTagsState.mapState([
			'taskTags'
		]),
        ...orgState.mapState([
			'currentOrg'
		]),
        ...usersState.mapState([
			'currentAuthUser',
            'preferenceForUser',
            'colorObj'
		]),

        filteredOwnMembers(){
            return this.currentOrg.members.filter((member) => {
                if(member.user.firstName !== null){
                    return member;
                }
            });
        },

        filteredTagsOption(){

            // Tags
            let UXTag = [];
            for(let i = 0; i < this.taskTags.length; i++) {
                UXTag.push(this.taskTags[i].tagName);
            }
            
            // taskTags
            let taskUXTag = [];

            for(let i = 0; i < this.addTag.length; i++) {
                taskUXTag.push(this.addTag[i]);
            }

            let iterate =  UXTag.filter(d => !taskUXTag.includes(d));
                let iteValue = [];
                for(let i = 0; i < iterate.length; i++){
                    iteValue.push(this.taskTags.find(p => p.tagName === iterate[i]));
                }
                return iteValue;
        }
        

	},
	methods : {


        checkColorluminance(color){
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
    
            const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;

            if (relativeLuminance > 0.5) {
                return '#000000';
            }
                return '#FFFFFF';

        },

        openOwnerList(task){
            this.selectedTask = task;
            this.updOwnInp = task.taskId;
            this.existOwnArr = task.owners.map((own) => { return own.user.userId ;});
            this.$nextTick(() => {
                this.$refs.ownerInpList.focus();
            });
        },

        ownersChange(newValueArr){
            this.changeOwn = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
        },

        updateOwnerInList(val){

            if(val === false){
                this.updOwnInp = null;
                if(this.changeOwn.length){
                    this.selectedTask.owners = this.changeOwn;
                    this.$store.dispatch("tasks/updateTaskDetails", this.selectedTask);
                }
                this.changeOwn = [];
                this.selectedTask = {};
                this.existOwnArr = [];
            }

        },

		getAllTasks (){
            let taskCategory = this.preferenceForUser.userPreferences.taskPreference.searchCriteria;
            if(taskCategory.length > 0){
                let status = this.statuses.find((status) => { return status.statusCategory === taskCategory; });
                let decodeStatus = `{"statusIds" : [${status.taskStatusId}]}`;
                this.sortQryParams.searchCriteria = decodeURI(decodeStatus);
            }
			this.$store.dispatch('tasks/getAll', {queryParams : this.sortQryParams});
            
		},

        openownerDD(){
            this.openCollab = true;
            setTimeout(() =>{
                this.$refs.selectCollab.focus();
            }, 100);
            
        },

        selectowner(val){

            if(val === false){
                this.$refs.inlineInput.focus();
                this.openCollab = false;
            }
            this.owner = this.setOwnerInp.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
        },

        changeAttachFile(file){
            this.fileList = file.target.files;
            this.$refs.inlineInput.focus();
        },

        changeBulkStatus(){

            if(this.statusValue){
                this.updateBulkValue.op = "replace";
                this.updateBulkValue.path = "/status";
                this.updateBulkValue.value = this.statuses.find(s => s.taskStatusId === this.statusValue);
                this.updateBulkArr.patch.push(this.updateBulkValue);
                this.updateBulkArr.tasks = this.selected;
                this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
                this.bulkdltpopup = false;
                this.statusValue = '';
                setTimeout(() => {
                    this.selected = [];
                    this.updateCheckAll();
                    this.updateBulkValue = {};
                    this.updateBulkArr.patch = [];
                }, 0);

            }
            
        },

        closeStatusDial(){
            this.statusValue = '';
            this.bulkdltpopup = false;
        },

        changeBulkPriority(){

            if(this.priorityValue){
                this.updateBulkValue.op = "replace";
                this.updateBulkValue.path = "/priority";
                this.updateBulkValue.value = this.priorities.find(s => s.taskPriorityId === this.priorityValue);
                this.updateBulkArr.patch.push(this.updateBulkValue);
                this.updateBulkArr.tasks = this.selected;
                this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
                this.bulkdltpopup = false;
                this.priorityValue = '';
                setTimeout(() => {
                    this.selected = [];
                    this.updateCheckAll();
                    this.updateBulkValue = {};
                    this.updateBulkArr.patch = [];
                }, 0);
            }
            
        },

        closePriorityDial(){
            this.priorityValue = '';
            this.bulkdltpopup = false;
        },

        async setBulkDuedate(){
            if(!this.dueDateValue){
                return ;
            }
            this.updateBulkValue.op = "replace";
            this.updateBulkValue.path = "/dueDate";
            this.updateBulkValue.value = new Date(this.dueDateValue).getTime();
            this.updateBulkArr.patch.push(this.updateBulkValue);
            this.updateBulkArr.tasks = this.selected;

            await this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
            this.bulkdltpopup = false;
            this.dueDateValue = '';
            setTimeout(() => {
                this.selected = [];
                this.updateCheckAll();
                this.updateBulkValue = {};
                this.updateBulkArr.patch = [];
            }, 0);
        },

        closeDueDateDial(){
            this.dueDateValue = '';
            this.bulkdltpopup = false;
        },

        tagValue(value){

            if(this.$refs.trim.input.value !== ''){
                    let val = this.$refs.trim.input.value.toString().toLowerCase();
                    this.$refs.trim.input.value = '';
                    let str = val.replace(/\s+/g, "");
                    let tagexistsValue = this.addTag.map((e) => {return e.toLowerCase();});
                    if(!tagexistsValue.includes(str)){
                        this.addTag.push(str);
                }

            }
            else{
                    let val = value.toString().toLowerCase();
                    let str = val.replace(/\s+/g, "");
                    let tagexistsValue = this.addTag.map((e) => {return e.toLowerCase();});
                    if(!tagexistsValue.includes(str)){
                        this.addTag.push(str);
                }
            }
        },

        async updateTags(){
            if(!this.addTag.length){
                return;
            }

            for(let i = 0; i < this.taskTags.length; i++) {
                this.existsTagName.push(this.taskTags[i].tagName);
            }

            for(let i = 0; i < this.addTag.length; i++) {

                if(this.existsTagName.includes(this.addTag[i])){
                    let tag = this.taskTags.find(p => p.tagName === this.addTag[i]);
                    tag.color = "#9013fe";
                    this.addTags(tag);

                }
                else {
                    this.setTag.tagName = this.addTag[i];
                    this.setTag.color = "#9013fe";
                    let uxtag = await this.$store.dispatch("tasktags/createTaskTag", this.setTag);
                    this.addTags(uxtag);
                }

            }
            this.existsTagName = [];
            this.addTag = [];
            setTimeout(() => {
                this.selected = [];
                this.updateCheckAll();
                this.updateBulkValue = {};
                this.updateBulkArr.patch = [];
            }, 0);

        },

        afterTagclose(){
            this.addTag = [];
            this.bulkdltpopup = false;

        },

        async addTags(tag){
            
            this.bulkdltpopup = false;
            this.updateBulkValue.op = "add";
            this.updateBulkValue.path = "/taskTags/-";
            this.updateBulkValue.value = tag;
            this.updateBulkArr.patch.push(this.updateBulkValue);
            this.updateBulkArr.tasks = this.selected;
            await this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
        },

        deleteTag(tag){
            this.addTag.splice(this.addTag.indexOf(tag), 1);

        },

        onOwnersChange(newValue){
			this.setOwners = newValue.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});
            // console.log(this.setOwners);
        },

        setBulkOwners(){

            if(!this.ownersValue.length){
                return ;
            }
            this.updateBulkValue.op = "replace";
            this.updateBulkValue.path = "/owners";
            this.updateBulkValue.value = this.setOwners;
            this.updateBulkArr.patch.push(this.updateBulkValue);
            this.updateBulkArr.tasks = this.selected;
            this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
            this.bulkdltpopup = false;
            setTimeout(() => {
                this.selected = [];
                this.updateCheckAll();
                this.updateBulkValue = {};
                this.updateBulkArr.patch = [];
            }, 0);

        },

        closeOwnerDial(){
            this.ownersValue = [];
            this.setOwners = [];
            this.bulkdltpopup = false;
        },

        setFollowers(newValueArr){
            this.followersData = newValueArr.map((nv) => { return this.currentOrg.members.find(u => u.user.userId === nv);});

        },

        async setBulkUpdate(){

           if(!this.followersData.length){
                 return ;
            }

            this.updateBulkValue.op = "replace";
            this.updateBulkValue.path = "/followers";
            this.updateBulkValue.value = this.followersData;
            this.updateBulkArr.patch.push(this.updateBulkValue);
            this.updateBulkArr.tasks = this.selected;
            
            await this.$store.dispatch("tasks/bulkUpdate", this.updateBulkArr);
            this.bulkdltpopup = false;
            setTimeout(() => {
                this.selected = [];
                this.updateCheckAll();
                this.updateBulkValue = {};
                this.updateBulkArr.patch = [];
            }, 0);
        
        },

        closeFollowerDial(){
            this.followerId = [];
            this.followersData = [];
            this.bulkdltpopup = false;
        },

        async convertSubtask(){
            if(!this.selected.includes(this.setParentId)){
                let subTotask = {};
                subTotask.convertTaskId = this.selected;
                subTotask.parentTaskId = this.setParentId;
                await this.$store.dispatch("tasks/taskToSubtask", subTotask);
                this.selected = [];
                this.bulkdltpopup = false;
                this.$store.dispatch('tasks/getAll');
            }
            else{
                ElMessage({
                    message: 'The task to be replaced is the same as the selected parent task.',
                    type: 'warning'
                });
            }
        },

        async taskClosed(task){
            if(task.status.statusName ===  "Complete"){
                this.changedStatus = this.statuses.find(e => e.statusName === "Open");
            }

            else{
                this.changedStatus = this.statuses.find(e => e.statusName === "Complete");
            }

            task.status = this.changedStatus;
            await this.$store.dispatch("tasks/updateTaskDetails", task);
            this.changedStatus = {};
            
        },

        check(list){
            this.bulkdltpopup = list.id;
           
        },

        checkAll(){
            this.selected = [];
            if(!this.selectAll){
                for(let i in this.tasks){
                    this.selected.push(this.tasks[i].taskId);
                }
            }
            else{
                this.bulkList = false;
            }
        },

        updateCheckAll(){

            if(this.selected.length > 0){
                this.bulkList = true;
                    if(this.tasks.length === this.selected.length){
                        this.selectAll = true;
                    }
                    else{
                        this.selectAll = false;
                    }
            }
            else{
                this.bulkList =  false;
            }

        },

        deletebulktask(){

            this.deleteObj.tasks = this.selected;
            this.selected.forEach((taskId) => {
                this.tasks.splice(this.tasks.findIndex(a => a.taskId === taskId), 1);
            });

            this.bulkdltpopup = false;
            this.$store.dispatch("tasks/bulkDeleteTasks", this.deleteObj);
            setTimeout(() => {
                this.deleteObj.tasks = [];
                this.selected = [];
                this.updateCheckAll();
            }, 0);

        },

        openStartDateInp(taskId){
            this.editStartDateInp = taskId;
                let selectedTask = this.tasks.find( (task) => { return task.taskId === taskId; });
                if(selectedTask.startDate === null){
                    this.startDatePicker = '';
                }
                else{
                    this.startDatePicker = selectedTask.startDate;
                }
                setTimeout(() => {
                    this.$refs.startDateInp.focus();
                }, 100);

        },

        updateStartDateForTask(taskId){
            let el = document.querySelector('.stDateClass');
                setTimeout( async () =>{
                    let childEl = el.querySelector(":scope > .el-date-picker");
                    let visible = childEl.getAttribute('actualvisible');

                    if(visible === 'false'){
                        let selectedTask = this.tasks.find( (task) => { return task.taskId === taskId; });
                        let bfUpdStDate = selectedTask.startDate;
                        try{
                            if(this.startDatePicker !== null){
                                selectedTask.startDate = new Date(this.startDatePicker).getTime();
                            }
                            else{
                                selectedTask.startDate = null;
                            }
                            await this.$store.dispatch("tasks/updateTaskDetails", selectedTask);
                        }
                        catch(error){
                            this.$message("cannot to update startDate.please check the dueDate");
                            selectedTask.startDate = bfUpdStDate;
                        }
                        this.editStartDateInp = false;
                        this.startDatePicker = '';
                    }
                }, 500);
        },

        openDueDateInp(taskId){
            this.editDueDateInp = taskId;
            let selectedTask = this.tasks.find( (task) => { return task.taskId === taskId; });
            if(selectedTask.dueDate === null){
                this.dueDatePicker = '';
            }
            else{
                this.dueDatePicker = selectedTask.dueDate;
            }
            setTimeout(() => {
                this.$refs.dueDateInp.focus();
            }, 100);

        },

        updatedueDateForTask(taskId){

            let el = document.querySelector('.dueDateClass');
            setTimeout( async  () =>{
                let childEl = el.querySelector(":scope > .el-date-picker");
                let visible = childEl.getAttribute('actualvisible');

                if(visible === 'false'){

                    let selectedTask = this.tasks.find( (task) => { return task.taskId === taskId; });
                    let bfUpdDueDate = selectedTask.dueDate;
                    try{
                        if(this.dueDatePicker !== null){
                            selectedTask.dueDate = new Date(this.dueDatePicker).getTime();
                        }
                        else{
                            selectedTask.dueDate = null;
                        }
                        await this.$store.dispatch("tasks/updateTaskDetails", selectedTask);
                    }

                    catch(error){
                        this.$message("cannot to update dueDate.please check the subtasks dueDate");
                        selectedTask.dueDate = bfUpdDueDate;
                        
                    }
                    this.editDueDateInp = false;
                    this.dueDatePicker = '';

                }
            }, 500);
        },

        deleteDueDate(){
            console.log("delete");
        },

		enableInlineAdd (){
			this.inlineAddEnabled = true;
            this.$nextTick(() => {
                this.$refs.inlineTab.focus();
            });
			setTimeout(()=>{
				let mainScrollContainer = $$avQS('.av-task-list-view');
				mainScrollContainer.scroll({top: mainScrollContainer.scrollHeight, behavior: 'smooth'});
                this.$refs.inlineInput.focus();
			}, 0);
		},


        inlineSetPriority(priorityId){
            this.setpriority = this.priorities.find(p => p.taskPriorityId === priorityId);
            this.priorityName = this.setpriority.priorityLabel;
            this.$refs.inlineInput.focus();
        },
        
		async addTask(event){
			if(event.metaKey || event.ctrlKey){
				// TODO: open created task
			}else{
				try {
					if(!this.inlineAddName.trim()){
						return;
					}

                    this.inlineAddEnabled = false;
                    if(this.owner.length === 0){
                        this.owner = this.currentOrg.members.filter( (member) => { return member.user.userId === this.currentAuthUser.userId; });
                    }

                    let statusValue = this.statuses.find(s => s.statusName === "Open");
					let newTask = await this.$store.dispatch('tasks/create', { taskTitle : this.inlineAddName, owners : this.owner, dueDate : new Date(this.inlineAddDue).getTime(), priority : this.setpriority, status : statusValue});
                    this.setTaskAttach(newTask.data);

					this.inlineAddName = '';
					this.inlineAddDue = '';
                    this.setpriority = null;
                    this.priorityName = '';
                    this.openCollab = false;
                    this.setOwnerInp = [];
                    this.owner = [];

				} catch (error) {
					this.$message(error.message);
				}
			}
		},

        setTaskAttach(task){

            if(Object.entries(this.fileList).length > 0){
                this.taskAttach.taskId = task.taskId;
                this.fileList.forEach(async (file) => {
                    this.taskAttach.file = file;
                    await this.$store.dispatch('tasks/uploadFile', this.taskAttach);
                });

                this.fileList = [];
                this.taskAttach = {};
            }
        },

        confirmCloseInlineTask(){

            this.openDeletepopup = false;
            this.inlineAddEnabled = false;
            this.inlineAddName = "";
            this.inlineAddDue = '';
            this.priorityName = '';
            this.openCollab = false;
            this.setOwnerInp = [];
            this.owner = [];
            this.fileList = [];
        },

		onSortClick(event, isSortable, sortBy){
			if(!isSortable){
				return;
			}
			let sortOrder = 'asc';
			if(this.sortQryParams.property === sortBy){
				sortOrder = this.sortQryParams.sort === 'desc' ? null : 'desc';
			}
			let transitionObj = {
				path : this.$router.currentRoute.value.fullPath
			};
			let query = Object.assign({}, this.$route.query);
			if(sortOrder){
				query.property = sortBy;
				query.sort = sortOrder;
			}else{
				delete query.property;
				delete query.sort;
			}

			transitionObj.query = query;
            // console.log(transitionObj.query);
			this.$router.push(transitionObj);
		}
	}
};
</script>
<style>
/* span:hover {
    position: relative;
}

span[data-title]:hover:after {
     content: attr(data-title);
     padding: 4px 8px;
     position: absolute;
     left: 0;
     top: 100%;
     white-space: nowrap;
     z-index: 20;
     background:red;
} */
.scrollBar-table{
    background-color: rgba(0,0,0,0);
    transition: background-color .3s;
}
.scrollBar-table:hover{
   background-color: #e3e4ed;;
}
.scrollBar-table::-webkit-scrollbar-track{
    background-color: #fff;
}

.scrollBar-table::-webkit-scrollbar{
	width: 8px;
    height: 7px;
}

.scrollBar-table::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: inherit;
}

.icon-tab > svg.av-icon.regular{
    height: 15px;
    width: 15px;
}
.icon-tab > .tick-icn{
    margin-right: 10px;
    color: #5964DD;
}

.av-task-list-view{
    margin-bottom: 3rem;
}
.av-task-list-view > .bulk-list-view{

}
.av-task-list-view table.task-list{
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    background-color: var(--color-white);
    white-space: nowrap;
}
.av-task-list-view table.task-list.tip-enable{
    /* margin-bottom: 70px; */
}
.av-task-list-view table.task-list > thead{
    height: 3rem;
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    box-shadow: 0rem 0.1875rem 0.1875rem var(--color-5);
}
.av-task-list-view table.task-list th{
    text-align: left;
    border-bottom: 0.1875rem solid transparent;
}
.av-task-list-view table.task-list tr{
    height: 3.5rem;
}
.av-task-list-view table.task-list th,
.av-task-list-view table.task-list td{
    padding: 0.5rem;
    width: 11rem;
}
/* .av-task-list-view table.task-list th.key-fld,
.av-task-list-view table.task-list td.key-fld{
    width: auto;
} */
.av-task-list-view table.task-list th:first-of-type,
.av-task-list-view table.task-list td:first-of-type{
    width: 3rem;
}
.av-task-list-view > table.task-list th.user{
    text-align: center;
    /* padding-left: 1.375rem ; */
}
.av-task-list-view table.task-list th .sort-dflt-icon{
    padding-left: 0.25rem;
}
.av-task-list-view table.task-list th .srt-icon{
    color: var(--color-black);
}
.av-task-list-view table.task-list th .sort-dflt-icon > .srt-icon.rot-180{
    margin-bottom: -0.5rem;
    transform: rotate(180deg);
}
.av-task-list-view table.task-list th  .srt-icon.rot-90{
    transform: rotate(90deg);
}
.av-task-list-view table.task-list th  .srt-icon.rot-270{
    transform: rotate(270deg);
}
.av-task-list-view table.task-list tr td .datePicker {
    width: 100%;
}
.av-task-list-view table.task-list tr td .datePicker .el-input__inner{
    padding-right: 0px;
    padding-left: 10px;
    height: 33px;
}
/* .av-task-list-view table.task-list tr td .datePicker:focus-within .el-input__inner{
    padding-left: 0px;
    padding-right: 0px;
} */
.av-task-list-view table.task-list tr td .datePicker .el-input__prefix {
    left: 0;
    display: none;
}
.av-task-list-view table.task-list tr td .datePicker .el-input__prefix{
    
}
.av-task-list-view table.task-list tr td .datePicker .el-input__suffix{
    /* display: none; */
    right: 0px;
}

.av-task-list-view table.task-list td .task-lst-ttl{
    display: flex !important;
}
.av-task-list-view table.task-list td .key-fld{
    max-width: 80%;
}

.av-task-list-view table.task-list td  .status{
    height: 0.875rem;
    width: 0.875rem;
    border-radius : 0.125rem;
    margin-right: 0.5rem ;
}
.av-task-list-view table.task-list td .date-time{
    color: var(--color-6);
}
.av-task-list-view table.task-list td .status-key{
    margin-right: 0.3rem;
}
.av-task-list-view table.task-list td .date-div:hover{
    border: 1px solid #acafb3;
    border-radius: 3px;
}
.av-task-list-view table.task-list td .date-div{
    border: 1px solid transparent;
    padding: 5px;
    width: 75%;
    height: 30px;
}
.av-task-list-view table.task-list td .tag-chips-hldr{
    max-width: 12rem;
    white-space: pre-wrap;
}
.av-task-list-view table.task-list td .tag-chips-hldr > .tag-chip{
    border-color: transparent;
    border-radius: 1rem;
    margin: .125rem;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 17px;
}
.av-task-list-view table.task-list td .user{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
}
.av-task-list-view table.task-list td .own-icon{
    color: var(--color-8);
    border: 1px solid transparent;
    padding: 2.5px 0;
}
.av-task-list-view table.task-list td .own-icon:hover{
    border: 1px solid #acafb3;
    border-radius: 3px;
}
.av-task-list-view table.task-list td .calIcon{
    color: #acafb3;
}
.av-task-list-view table.task-list td .calIcon-div:hover{
    border: 1px solid #acafb3;
    border-radius: 3px;
}
.av-task-list-view table.task-list td .calIcon-div{
    border: 1px solid transparent;
    padding: 5px;
    width: 75%;
}

.av-task-list-view table.task-list td .user:not(:first-of-type){
    margin-left: -0.5rem;
}
.av-task-list-view table.task-list .inline-add{
    background-color: var(--color-white);
    position: sticky;
    bottom: 0;
}
.av-task-list-view table.task-list .inline-add .add-task{
    width: 100%;
    height: 3rem;
    color: var(--color-7);
    padding-left: 3rem;
    border-top: 0.125rem solid var(--color-5);
}
.av-task-list-view table.task-list .inline-add.add-enabled{
    border: 0.0625rem solid var(--color-2);
}
.av-task-list-view table.task-list .inline-add > .name::after{
    content: attr(data-tips);
    left: 1.5rem;
    top: calc(100% + 1rem);
    color: var(--color-7);
}

.av-task-list-view table.task-list .inline-add > .assignee{
    text-align: center;
}
.av-task-list-view table.task-list .inline-add .own-len{
    color: #a39797;
    margin-left: 5px;
}
.assignee-box{
    padding: 12px;
}
.av-task-list-view table.task-list .inline-add svg{
    color : var(--color-8);
}
.av-task-list-view table.task-list .inline-add .others .icon:not(:first-of-type){
    margin-left: 3rem;
}

.av-task-list-view table.task-list .inline-add > .name input.el-input__inner{
    border: none;
}
.av-task-list-view table.task-list .inline-add .el-date-editor{
    width: 5.5rem;
    margin-left: 3rem;
    line-height: 1;
}
.av-task-list-view table.task-list .inline-add .el-date-editor .el-input__inner,
.av-task-list-view table.task-list .inline-add .el-date-editor .el-icon-circle-close{
    border: none;
    height: 30px;
    color: #bdb6b6;
    padding-right: 5px;

}
.inlinedatePicker .el-input__suffix{
    display: none;
}
.av-task-list-view >table.task-list .inline-add .el-date-editor .el-input__prefix{
    /* position: static; */
    height: 1.5rem;
    width: 1.5rem;
    color : var(--color-8)
}
.av-task-list-view table.task-list .inline-add .el-date-editor .el-input__icon.el-icon-date{
    font-size: 1.3rem;
    width: auto;
    line-height: 30px;
}
/* outside of table */

.ot-table.inline-add{
    background-color: var(--color-white);
    position: sticky;
    bottom: 0;
    padding : 0.5rem;
    margin-top : 5px;
}
.ot-table.inline-add .add-task{
    width: 100%;
    height: 3rem;
    color: var(--color-7);
    padding-left: 3rem;
    /* border-top: 0.125rem solid var(--color-5); */
}
.ot-table.inline-add.add-enabled{
    border: 0.0625rem solid var(--color-2);
}
.ot-table.inline-add > .name{
    width: 25rem;
    margin : 0 1.4rem;
}
.ot-table.inline-add > .name::after{
    content: attr(data-tips);
    left: 1.5rem;
    top: calc(100% + 1rem);
    color: var(--color-7);
    font-size: 15px;
}

.ot-table.inline-add > .assignee{
    text-align: center;
    margin-right: 1rem;
    width: 10rem;
}
.ot-table.inline-add > .assignee .el-select .el-input .el-input__suffix{
    display: none;
}
.ot-table.inline-add .own-len{
    color: #a39797;
    margin-left: 5px;
}
.ot-table.inline-add .own-len .selectedOwnIcon{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    color: #FFFFFF;
}
.ot-table.inline-add .own-len span:not(:first-of-type){
    margin-left: -0.5rem !important;
}
.ot-table.inline-add svg{
    color : var(--color-8);
}
.ot-table.inline-add .others{
    width: 30rem;
}
/* .inline-add .others .icon:not(:first-of-type){
    margin-left: 3rem;
} */

.ot-table.inline-add > .name input.el-input__inner{
    border: none;
}
.ot-table.inline-add .others .add-task-pri{
    width: 6rem;
}
.ot-table.inline-add .others .attach-field{
    width: 6rem;
}
.ot-table.inline-add .el-date-editor{
    width: 6rem;
    /* margin-left: 3rem; */
    line-height: 1;
}
.ot-table.inline-add .el-date-editor .el-input__inner,
.ot-table.inline-add .el-date-editor .el-icon-circle-close{
    border: none;
    height: 30px;
    color: #bdb6b6;
    padding-right: 5px;

}
.inlinedatePicker .el-input__suffix{
    display: none;
}
.ot-table.inline-add .el-date-editor .el-input__prefix{
    /* position: static; */
    height: 1.5rem;
    width: 1.5rem;
    color : var(--color-8)
}
.ot-table.inline-add .el-date-editor .el-input__icon.el-icon-date{
    font-size: 1.3rem;
    width: auto;
    line-height: 30px;
}

.el-popover .inlineadd-flag-pop{
    padding: 0.5rem 0;
}
.el-popover .inlineadd-flag-pop li{
    padding: 0.5rem;
}
.el-popover .inlineadd-flag-pop li:hover{
    background-color: #ecf5ff;
    color: #66b1ff;
}
.el-popover .inlineadd-flag-pop li .lbl{
    padding-left: 0.5rem;
}
.bulk-list-view .uxTagBox{
    border: 1px #eee solid;
    max-width: 80%;
    padding: 0px 5px;
}
.bulk-list-view .uxTagBox .el-select {
    width: 100%;
}
.bulk-list-view .uxTagBox .el-input--suffix .el-input__inner, .bulk-list-view .uxTagBox .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
.bulk-list-view .uxTagBox .el-select .el-input__suffix{
    display: none;
}
.ot-table.inline-add .close-icon{
    /* margin-left: 9rem; */
    cursor: pointer;
    /* width: 6rem; */
    text-align: end;
}
.ot-table.inline-add .close-icon > i{
    color: var(--color-8);
    font-size: 1rem;
}
.plb{
    color: #a39797;
    margin-left: 4px;
}
.checkall{
    padding: 0.5rem;
}
.blklist-arr{
    padding-left:15px;
}
.blklist-arr > span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #4B54BA;
    cursor: pointer;
    margin: 0 10px;
}
.checkall > span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.sts-txt{
    margin-right: 10px;
}
.status-slt-popup .el-input .el-input__suffix {
    display: block;
}
.due-date-elmt .el-range-separator{
    width: 10%;
}
.owner-fld{
   border:  1px solid transparent;
   padding: 2.5px 0;
}
.owner-fld:hover {
    border: 1px solid #acafb3;
    border-radius: 3px;
}
</style>