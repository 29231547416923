<template>
    <svg class="dIB av-icon">
        <use v-bind="{'xlink:href':'#'+ name}"></use>
    </svg>
</template>

<script>
export default {
	props : {
		name : String
	}
};
</script>

<style scoped>
svg{
    fill: currentColor;
}
svg.av-icon{
    height: 1.5rem;
    width: 1.5rem;
}
svg.av-icon.regular{
    height: 1.25rem;
    width: 1.25rem;
}
svg.av-icon.medium{
    height: 1rem;
    width: 1rem;
}
svg.av-icon.large{
    height: 2rem;
    width: 2rem;
}

svg.av-icon.small{
    height: .75rem;
    width: .75rem;
}
svg.av-icon.prfle-pic-user{
    height: 50px;
    width: 50px;
}
svg.av-icon.ex-large{
    height: 5rem;
    width: 5rem;
}

</style>