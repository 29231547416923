<template>
  <div class="av-home-task-view flex --col flex--one-oflow">
	<div class="flex--col">
		<el-menu default-active = "1" mode="horizontal" background-color="#F2F2F2" active-text-color="#5964DD">
			<el-menu-item index="1">
				<span class="txt16">My Tasks</span>
			</el-menu-item>
			<el-menu-item index="2">
				<span class="txt16">Mentions</span>
			</el-menu-item>
		</el-menu>
		
		<div class="dropdown">
			<el-dropdown trigger="click">
				<div class="cp flex--row flex--ac">
					<span class="txt16">{{ taskCategoryName }}</span>
					<av-icon class="cp icon" name="triangle-arrow-down"></av-icon>
				</div>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item class="flex--row flex--ac txt14 cp">
							<span @click="this.taskCategoryName = 'Today'" >Today</span>
						</el-dropdown-item>
						<el-dropdown-item  class="flex--row flex--ac txt14 cp">
							<span @click="this.taskCategoryName = 'Upcoming'" >Upcoming</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
		
		<table class="av-task-container">
			<thead class="txt16">
			</thead>
			<tbody class="txt14">
					<tr v-for="task in tasks" :key="task.taskId" >
						<template v-for="owner in task.owners" :key="owner">
							<template v-if="owner.user.userId === currentAuthUser.userId">
								<template v-for="field in displayFields" :key="field.key">

									<td v-if="field.isDisplayKeyField">
										<div class="taskpriorityContainer flex--row flex--sbw">
											<router-link :to="'/tasks/'+task.taskId" class="taskName">
												<span class="flex--one clip-txt">{{task[field.key]}}</span>
											</router-link>
											<div class="priority">
												<span v-if="task['priority'] === null">None</span>
												<span v-else>{{task['priority'].priorityLabel}}</span>
											</div>
										</div>
									</td>
									
								</template>
							</template>
						</template>
					</tr>
			</tbody>
		</table>
	</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';
const { mapState, mapGetters } = createNamespacedHelpers('tasks');
const usersState = createNamespacedHelpers('users');

export default{
	data (){
		return{
			taskCategoryName : 'Today'
		};
	},
	components : {
		'av-icon' : avIcon
	},
	created() {
        this.getAllTasks();
    },
	computed: {
		...mapState([
			'fields',
			'tasks'
		]),
		...mapGetters([
			'displayFields',
			'statusFieldObj'
		]),
		...usersState.mapState([
			'currentAuthUser'
		])
	},
	methods : {
        getAllTasks (){
			this.$store.dispatch('tasks/getAll');
		}
	}
};
</script>

<style>
.av-home-task-view{
	margin-bottom: 3rem;
	width: 40vw;
	height: 40vw;
}
.av-task-container{
	margin-left: 1rem;
	background-color: var(--color-white);
	border-color: var(--color-6);
	table-layout: fixed;
    width: 95%;
}

.taskpriorityContainer{
	margin-bottom: 1rem;
}
.taskpriorityContainer a{
	width: 50%;
}
.taskName{
	margin-top:1rem;
	margin-left: 3rem;
	display: inline-block;
}

.priority{
	background-color: #F55C5C;
	border-radius: 25rem;
	margin-top:1rem;
	margin-right: 1rem;
	height: fit-content;
}

.priority span{
	margin-left: 2rem;
	margin-right: 2rem;
	color: white;
}

.dropdown{
	margin-left:2rem;
	margin-top:1rem;
	margin-bottom: 1rem;
}
</style>