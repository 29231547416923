<template>
	<div class="richText">
		<QuillEditor :options="options" v-model:content="content" @blur="lossFocus" @editor-change="onEditorChange" contentType="html" ref="quillEditor">
		</QuillEditor>
		<span ref="customToolbar">
			<label for="file-input">
			</label>
			<input type="file" multiple id="file-input" ref="fileInput" style="display:none;" @input="handleFile">
		</span>

		<el-dialog :fullscreen="true" custom-class="image-preview-dialogue" width="75%" v-model="imagePreview" @close="closePreviewDial" title="">
			<div :style="[ zoomIn ? { 'width': '100%' } : { 'width': '70%' }]" class="pre-img-cont">
				<img @click="zoomIn = !zoomIn" :style="[ zoomIn ? { 'cursor': 'zoom-out' } : { 'cursor': 'zoom-in' }]" :src= previewImageSrc>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const orgState = createNamespacedHelpers('organizations');

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
Quill.register("modules/imageUploader", ImageUploader);
// import dedent from 'dedent';


let fontSizeArr = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px'];
let Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

import 'quill-mention';

export default {
	emits : [ 'getQuillEditor' ],
	components: {
		QuillEditor
	},

	props : {
		taskDesc : String,
		taskCreateForm : {
			type : Boolean,
			'default' : false
		}
	},

	mounted(){
		
		if(this.taskCreateForm){
			let getCustomBtn = document.querySelector(".ql-myCustomButton");
			getCustomBtn.style.setProperty('display', 'none', 'important');
		}
		this.content = this.taskDesc;

		this.customDataForMention = this.currentOrg.members.map((member) => {
			let arr = {};
			arr.id = member.user.userId;
			arr.value = member.user.firstName;
			return arr;
		});

	},

	computed : {
		...orgState.mapState([
			'currentOrg'
		])
	},

	data(){
		return {
			imagePreview : false,
			previewImageSrc : '',
			zoomIn : false,
			decodedHTMl : '',
			content : '',
			customDataForMention :  false,
			options: {
					modules: {
						
						short_name_emoji: true,
						toolbar_emoji: true,
						textarea_emoji: false,
						keyboard: {
							bindings: {
								tab: false
							}
						},
						toolbar:{
							container : [
							['bold', 'italic', 'underline', 'strike'],
							[{ size : fontSizeArr}],
							[{ color: [] }, { background: [] }],
							[{ align: [] }],
							[{ list : 'ordered' }, { list: 'bullet' }],
							[{ indent : '-1' }, { indent : '+1' }],
							['link', 'image'],
							['emoji'],
							[{ myCustomButton: {} }]
							],
							handlers: {
								myCustomButton: this.handleClick
							}
						},
						mention: {
							itemIndex : -1,
							allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
							mentionDenotationChars: ["@"],
							source: this.atValueFun,
							onSelect : this.selectItem,
							listItemClass: "ql-mention-list-item",
							mentionContainerClass: "ql-mention-list-container",
							mentionListClass: "ql-mention-list",
							onOpen() {
								return true;
							},
							onBeforeClose() {
								return true;
							},
							onClose() {
								return true;
							},
							renderItem(item) {
								return `${item.value}`;
							}
					}
				},
				placeholder: '...',
				theme: 'snow'
			}
		};
	},

	methods : {

		lossFocus(){
			// let emojiContainer = this.$refs.quillEditor.getQuill().container.querySelector('#emoji-palette');
			// emojiContainer.style.setProperty('display', 'none', 'important');
		},
		
		handleClick() {
			this.$refs.fileInput.click();
		},

		handleFile(event) {
			this.$emit("getQuillEditor", event);
		},

		onEditorReady(editor) {
			const emojiModule = editor.getModule('mention');
			console.log(emojiModule);
		},

		selectItem(item, renderItem){
			renderItem(item);
		},

		showMenu() {
			let quill = this.$refs.quillEditor.getQuill();
			let range = quill.getSelection(true);
			if (range) {
				const [leaf] = quill.getLeaf(range.index);
				if (leaf && leaf.domNode.tagName === "IMG") {
					console.log(range.index);
					let deleteText = quill.deleteText(range.index, 1);
					console.log(deleteText);
				}
			}
			console.log(quill.getContents());

			// console.log(quill.getContents());
			// const element = quill.root.innerHTML;
			// const encodedElement = encodeURI(element);
			// .replace(/'/g, '%27')
			// .replace(/"/g, '%22')
			// .replace(/</g, '%3C')
			// .replace(/>/g, '%3E')
			// .replace(/&/g, '%26')
			// .replace(/=/g, '%3D')
			// .replace(/:/g, '%3A')
			// .replace(/;/g, '%3B');
			// console.log(encodedElement);
			// let decodedElement = decodeURI(encodedElement);
			// .replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
			// this.decodedHTMl = decodedElement;
			// console.log(this.decodedHTMl);

		},

		onEditorChange() {

			const images = document.querySelectorAll('.ql-editor img');

			images.forEach((image) => {
				image.addEventListener('click', this.onImageClick);
			});

		},

		onImageClick(event) {
			this.imagePreview = true;
			this.previewImageSrc =  event.target.src;
		},

		closePreviewDial(){
			this.imagePreview = false;
			this.previewImageSrc =  '';
			this.zoomIn = false;
		},

		handleTab(range, context){
			console.log(range, context);
			return;
		},

		atValueFun(searchTerm, renderList, mentionChar){

			let mentionList;
			if(mentionChar === "@"){
				mentionList = this.customDataForMention;
			}
			if (searchTerm.length === 0) {
				renderList(mentionList, searchTerm);
			}
			else {
				let matches = [];
				matches = mentionList.filter(mentionList => mentionList.value.toLowerCase().startsWith(searchTerm.toLowerCase()));
				renderList(matches, searchTerm);
				
			}
		}

	}
};
</script>

<style>
.richText .el-dialog.image-preview-dialogue{
	background-color: #252628;
}
.richText .el-dialog.image-preview-dialogue .el-dialog__header{
	position: fixed;
    width: 100%;
    background-color: #252628;
	border-bottom: 1px solid #424244;
	height : 60px;
}
.richText .el-dialog.image-preview-dialogue .el-dialog__header .el-dialog__title{
	color: #ffffff;
}
.richText .image-preview-dialogue img{
	max-width: 100%;
}
.pre-img-cont{
    overflow: auto;
    margin: 0 auto;
}
.ap{
	margin-top: 3px;
}
.ql-toolbar.ql-snow{
	border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow{
	border-radius: 0 0 4px 4px;
}
.ql-toolbar.ql-snow .ql-formats{
	margin-right : 5px !important;
}
.ql-snow .ql-stroke{
	stroke-width: 1.5px;
}
.ql-myCustomButton{
	display: flex !important;
	align-items : center;
}
.ql-myCustomButton, .ql-myCustomButton:after {
	content: "\e226";
	font-family: "Material Icons";
	font-size : 1.1rem;
}
.decodedHTML img{
	width: 15%;
}
.ql-emojiblot{
	vertical-align: middle;
}
.image-uploading img {
	max-width: 15% !important;
}
.ql-snow .ql-editor img {
    max-height: 12rem;
    max-width: 100%;
	cursor: pointer;
}
.ql-editor strong{
	font-weight: bold;
}
.ql-editor em{
	font-style: italic;
}
.ql-editor u {
	text-decoration: underline;
}
.ql-mention{
	content: '\0040';
	display: flex;
    align-items: center;
	font-weight: bold;
}
.ql-mention::after{
	content: '\0040';
}
.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: '12px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
    content: '20px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: '22px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: '24px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
    content: '26px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
    content: '28px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
    content: '30px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value ="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: '32px';
}
/* .ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value);
} */
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    /* content: 'Normal'; */
    content: '10px';
  }

</style>