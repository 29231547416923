<template>
  <div class="av-tasks flex--col flex--one">
        <div class="hdr flex--row flex--ac">
            <av-task-cv-popover v-if="viewMode === 'list'" @OpenTasks="getOpenStatusTasks" @AllTasks="getAllTasks"></av-task-cv-popover>
            <div class="right-pnl flex--row flex--ac mlA">
                <template v-if="viewMode === 'kanban'">
                    <div class="kanban-options flex--row flex--ac" v-for="(meta, index) in kanbanOptionsMeta" :key="index">
                        <span class="txt14 lbl">{{ $t("av.tasks.view.kanban."+meta.key) }}:</span>
                        <el-dropdown trigger="click" @command="onKanbanMetaChange">
                            <div class="cp flex--ac flex--row">
                                <span class="itm-lbl">{{selectedKanbanOptions[meta.key].label}}</span>
                                <av-icon class="cp icon" name="triangle-arrow-down"></av-icon>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-for="(metaItm,index) in meta.items" :key="index" :command="metaItm.key"
                                        class="flex--row flex--ac txt14 cp" :data-meta-type="meta.key">
                                        <span :data-meta-type="meta.key">{{ metaItm.label }}</span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
					<!-- end -->
                </template>
				
				<!-- <el-button class="log-out" type="primary" size="medium">
					<a href="http://54.89.107.212:8080/logout">
							{{ $t("av.global.logout") }}
					</a>
				</el-button> -->

                <el-button-group  @click="openAddTaskForm = true ">
                    <el-button type="primary" size="medium">
                        {{ $t("av.global.create") }}
                    </el-button>
                    <el-button class="add-btn-icn" type="primary" size="medium">
                        <av-icon class="cp icon" name="triangle-arrow-down"></av-icon>
                    </el-button>
                </el-button-group>

				<el-tooltip :show-after=800 content="Filter" popper-class="tool-tip">
					<av-icon @click="filterView = true" class="cp pnl-icon " name="icon-filter-24"></av-icon>
				</el-tooltip>
				<el-drawer :withHeader="false" v-model="filterView" size="35%">
					<av-apply-filter></av-apply-filter>
				</el-drawer>

				<el-dropdown trigger="click" popper-class="user-filter-dd" @command="onViewModeChange" :hide-on-click="clickOnHide">
					<el-tooltip :show-after=800 content="Settings" popper-class="tool-tip">
						<av-icon class="cp pnl-icon" name="icon-settings-24"></av-icon>
					</el-tooltip>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item  class="flex--row txt14 cp flex--sbw">
                                <span class="flex--one" @click="onViewModeChange('list')">{{ $t("av.tasks.view.list") }}</span>
								<input class="star" v-model="starCheckBox" value="list" type="radio" title="set as default" @change="selectDefaultViewMode" >
                            </el-dropdown-item>
                            <el-dropdown-item class="flex--row flex--sbw txt14 cp bdr">
                                <span class="flex--one" @click="onViewModeChange('kanban')">{{ $t("av.tasks.view.board") }}</span>
								<input class="star" v-model="starCheckBox" value="kanban" type="radio" title="set as default" @change="selectDefaultViewMode" >
                            </el-dropdown-item>
							<template v-if="viewMode === 'kanban'">
									<el-dropdown-item  command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
										<span>Show priority</span>
										<el-switch @change="switchPriorityForBoardView" :loading="loadingSwitch" v-model="showPri" />
									</el-dropdown-item>
									<!-- <el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
										<span>Collapse empty columns</span>
										<el-switch  @change="switchEmptyColforBoardView" :loading="loadingSwitch" v-model="colEmpCol" />
									</el-dropdown-item> -->
									<el-dropdown-item  command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
										<span>Show Ids</span>
										<el-switch  @change="switchShowIdforBoardView" :loading="loadingSwitch" v-model="showId" />
									</el-dropdown-item>
									<!-- <el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
										<span>Show subtasks count</span>
										<el-switch  @change="switchSubtaskCountforBoardView" :loading="loadingSwitch" v-model="subTaskCount"/>
									</el-dropdown-item> -->
							</template>
							<template v-else>
								<el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Owners</span>
									<el-switch @change="switchingOwnerField" :loading="loadingSwitch" v-model="ownerModelVal"/>
								</el-dropdown-item>
								<!-- <el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Completion %</span>
									<el-switch @change="switchingCompletionField" :loading="loadingSwitch" v-model="completionModelVal" />
								</el-dropdown-item> -->
								<el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Status</span>
									<el-switch @change="switchingStatusField" :loading="loadingSwitch" v-model="statusModelVal"  />
								</el-dropdown-item>
								<el-dropdown-item  command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Start Date</span>
									<el-switch @change="switchingStartDateField" :loading="loadingSwitch" v-model="startDateModelVal" />
								</el-dropdown-item>
								<el-dropdown-item command="switch"  class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Due Date</span>
									<el-switch @change="switchingDueDateField" :loading="loadingSwitch" v-model="dueDateModelVal" />
								</el-dropdown-item>
								<el-dropdown-item command="switch"  class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Created Date</span>
									<el-switch @change="switchingCrtdDateField" :loading="loadingSwitch" v-model="createdDateModelVal" />
								</el-dropdown-item>
								<!-- <el-dropdown-item command="switch" class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Task List</span>
									<el-switch @change="switchingTaskListField" :loading="loadingSwitch" v-model="taskListModelVal" />
								</el-dropdown-item> -->
								<!-- <el-dropdown-item command="switch"  class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Created By</span>
									<el-switch @change="switchingCrtdByField" :loading="loadingSwitch" v-model="createdByModelVal" />
								</el-dropdown-item> -->
								<el-dropdown-item command="switch"  class="flex--row flex--ac flex--sbw txt14 cp">
									<span>Tags</span>
									<el-switch @change="switchingTagsField" :loading="loadingSwitch" v-model="tagsModelVal" />
								</el-dropdown-item>
							</template>
							</el-dropdown-menu>
                    </template>
                    
                </el-dropdown>
            </div>
        </div>
		<!-- <av-bulk-list-view></av-bulk-list-view> -->
        <component :is="'av-task-view-'+viewMode" :CTProp="ctProp" :Queryparams ="query"  ></component>

		<av-task-createform v-if="openAddTaskForm" @onClose="this.openAddTaskForm = false" @createTask="closeCreateForm"></av-task-createform>
	</div>
  <router-view/>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('tasks');
const usersState = createNamespacedHelpers('users');

import avIcon from '@/components/av-icon.vue';
import avTaskCVPopover from '@/components/av-task-cv-popover.vue';
import avTaskListView from '@/components/av-tasks-view-list.vue';
import avTaskListKanban from '@/components/av-tasks-view-kanban.vue';
import avTaskCreateForm from '@/components/av-task-createform.vue';
import avFilterView from '@/components/av-filterView.vue';
// import avBulklistView from '@/components/av-bulk-list.vue';


export default {
	components : {
		'av-icon' : avIcon,
		'av-task-cv-popover' : avTaskCVPopover,
		'av-task-view-list' : avTaskListView,
		'av-task-view-kanban' : avTaskListKanban,
		'av-task-createform' : avTaskCreateForm,
		'av-apply-filter' : avFilterView
		
	},

	computed: {
		...mapState([
			'fields',
			'tasks'
		]),
		...mapGetters([
			'displayFields',
			'statusFieldObj'
		]),

		...usersState.mapState([
			'currentAuthUser',
			'preferenceForUser'
		])
	},

	data() {
		return {
			// list, kanban
			filterView : false,
			starCheckBox : '',
			clickOnHide: false,

			loadingSwitch : false,
			ownerModelVal : false,
			completionModelVal: false,
			statusModelVal: false,
			startDateModelVal: false,
			dueDateModelVal: false,
			createdDateModelVal: false,
			taskListModelVal: false,
			createdByModelVal: false,
			tagsModelVal: false,
			showPri : false,
			colEmpCol : false,
			showId : false,
			subTaskCount : false,

			statusQryParams: {},
			searchTask : '',
			ctProp : false,
			viewMode : '',
			switchModel : false,
			selectedKanbanSortBy : {},
			selectedKanbanGrpBy : {},
			selectedKanbanOptions : {
				sortby : {},
				groupby : {}
			},
			kanbanOptionsMeta : [
				{
					key : 'sortby',
					items : [
						{
							label : 'Due Date',
							key : 'dueDate'
						},
						{
							label : 'Name',
							key : 'taskTitle'
						}
						// {
						// 	label : 'Priority : high to low',
						// 	key : 'priority'
						// },
						// {
						// 	label : 'Priority : low to high',
						// 	key : 'priorityLTH'
						// }
					]
				},
				{
					key : 'groupby',
					items : [
						{
							label : 'Status',
							key : 'status'
						}
					]
				}
			],
			openAddTaskForm : false,
			query : {}
		};
	},

	created (){
		this.$watch(
			() => this.$route.query,
			(toParams /*, previousParams */) => {
				this.viewMode = toParams.mode || this.viewMode;
			}
		);

		this.viewMode = this.$route.query.mode || this.preferenceForUser.userPreferences.taskPreference.mode;
		this.starCheckBox = this.preferenceForUser.userPreferences.taskPreference.mode;
		
		this.selectedKanbanOptions.sortby = this.getKanbanMetaItms('sortby');
		this.selectedKanbanOptions.groupby = this.getKanbanMetaItms('groupby')[0];

		this.preferenceForUser.userPreferences.taskColumns.some((field) => {
			if(field === 'owners'){
				this.ownerModelVal = true;
			}
			else if(field === 'completion'){
				this.completionModelVal = true;
			}
			else if(field === 'status'){
				this.statusModelVal = true;
			}
			else if(field === 'startDate'){
				this.startDateModelVal = true;
			}
			else if(field === 'dueDate'){
				this.dueDateModelVal = true;
			}
			else if(field === 'createdTime'){
				this.createdDateModelVal = true;
			}
			else if(field === 'taskList'){
				this.taskListModelVal = true;
			}
			else if(field === 'createdBy'){
				this.createdByModelVal = true;
			}
			else if(field === 'taskTags'){
				this.tagsModelVal = true;
			}
			else if(field === 'showPriority'){
				this.showPri = true;
			}
			else if(field === 'collapseEmptyColumns'){
				this.colEmpCol = true;
			}
			else if(field === 'showId'){
				this.showId = true;
			}
			else if(field === 'subTaskCount'){
				this.subTaskCount = true;
			}

		});
		
	},

	
	methods : {

		async switchingOwnerField(val){
			this.ownerModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('owners'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('owners');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingCompletionField(val){
			this.completionModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('completion'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('completion');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingStatusField(val){
			this.statusModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('status'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('status');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingStartDateField(val){
			this.startDateModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('startDate'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('startDate');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingDueDateField(val){
			this.dueDateModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('dueDate'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('dueDate');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingCrtdDateField(val){
			this.createdDateModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('createdTIme'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('createdTime');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingTaskListField(val){
			this.taskListModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('taskList'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('taskList');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingCrtdByField(val){
			this.createdByModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('createdBy'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('createdBy');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchingTagsField(val){
			this.tagsModelVal = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('taskTags'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('taskTags');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchPriorityForBoardView(val){
			this.showPri = val;
			this.loadingSwitch = true;

			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('showPriority'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('showPriority');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchEmptyColforBoardView(val){
			this.colEmpCol = val;
			this.loadingSwitch = true;
			
			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('collapseEmptyColumns'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('collapseEmptyColumns');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchShowIdforBoardView(val){
			this.showId = val;
			this.loadingSwitch = true;
			
			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('showId'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('showId');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		async switchSubtaskCountforBoardView(val){
			this.subTaskCount = val;
			this.loadingSwitch = true;
			
			if(val === false){
				this.preferenceForUser.userPreferences.taskColumns.splice(this.preferenceForUser.userPreferences.taskColumns.indexOf('subTaskCount'), 1);
			}
			else{
				this.preferenceForUser.userPreferences.taskColumns.push('subTaskCount');
			}
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			await this.$store.dispatch('users/updateUserPreference', userpreData);
			setTimeout(() => {
				this.loadingSwitch = false;
			}, 500);
		},

		selectDefaultViewMode(event){

			this.preferenceForUser.userPreferences.taskPreference.mode = event.target.value;
			let userpreData = [];
			userpreData.userId = this.currentAuthUser.userId;
			userpreData.preference = this.preferenceForUser;
			this.$store.dispatch('users/updateUserPreference', userpreData);
		},

		closeCreateForm(){
			this.ctProp = !this.ctProp;
		},

		getOpenStatusTasks(){
			this.statusQryParams.searchCriteria = `{"statusIds": [${1}]}`;
			this.$store.dispatch('tasks/getAll', {queryParams : this.statusQryParams});
			
		},
		
		getAllTasks(){
			this.$store.dispatch('tasks/getAll');
		},

		onViewModeChange(mode){

			if(!Object.entries(mode).length){
				return ;
			}

			if( mode !== "switch" ){
				let transitionObj = {
				path : this.$router.currentRoute.value.fullPath
				};
				transitionObj.query = {mode};
				this.$router.push(transitionObj);
				this.viewMode = mode;
			}


		},
		onKanbanMetaChange(itmKey, node, event){

			let metaKey = event.target.dataset.metaType;
			let metaOptions = this.getKanbanMetaItms(metaKey);
			this.selectedKanbanOptions[metaKey] = metaOptions.find(itm => itm.key === itmKey);

			if(metaKey === 'sortby'){
			let transitionObj = {
				path : this.$router.currentRoute.value.fullPath
			};

			let query = Object.assign({}, this.$route.query);

			let sortOrder = 'asc';

			if(itmKey === 'priorityLTH'){
				query.property = 'priority';
				query.sort = 'desc';
			}

			else{
				query.property = itmKey;
				query.sort = sortOrder;
			}

			transitionObj.query = query;
			this.$router.push(transitionObj);
			
			this.ctProp = !this.ctProp;
			this.query = transitionObj.query;

			}
			
		},
		getKanbanMetaItms(metaKey){
			return this.kanbanOptionsMeta.find(meta => meta.key === metaKey).items;
		}
	}
};
</script>

<style>
.user-filter-dd{
	width: 15rem;
}
.star {
    visibility:hidden;
    font-size: 20px;
    cursor:pointer;
	color: var(--color-1);
}
.star:before {
   content: "\2606";
   position: absolute;
   visibility: visible;
}
.star:checked:before {
   content: "\2605";
   position: absolute;
}
.tool-tip {
	padding: 5px 10px !important;
}
.bdr{
	border-bottom: 1px solid #D9D9D9;
}
.av-tasks{
    background-color: var(--color-5);
    padding: 0 1rem;
	overflow: auto;
}
.av-tasks > .hdr{
    height: 4rem;
    padding: 0 2rem;
}

.av-tasks > .hdr > .right-pnl .add-btn-icn{
    padding: 0.125rem;
}
.av-tasks > .hdr > .right-pnl .pnl-icon{
    height: 1.5rem;
    width: 1.5rem;
    color: var(--color-4);
    margin-left: 1rem;
}
.av-tasks > .hdr > .right-pnl >.pnl-icon:first-of-type{
    margin-left: 1rem;
}

.av-tasks > .hdr > .right-pnl >.kanban-options{
    padding-right: 1.25rem;
}
.av-tasks > .hdr > .right-pnl >.kanban-options > .lbl{
    color: var(--color-7);
}
.av-tasks > .hdr > .right-pnl >.kanban-options >.el-dropdown{
    padding-left: 0.25rem;
}
.av-tasks > .hdr .kanban-options >.el-dropdown .itm-lbl{
    color: var(--color-black);
}
.log-out{
	margin-right: 20px;
}

/* demoArr: {
                
                    taskTitle: "Sub task for main task updated",
                    description: null,
                    assignee: {
                        orgUserId: 1
                    },
                    attachments: null,
                    startDate: "2022-02-11",
                    dueDate: null,
                    priority: {
                        taskPriorityId: 2
                    },
                    recurringConfig: null,
                    taskReminder: null,
                    overdueStatus: false,
                    status: { taskStatusId: 2},
                    isParent: true,
                    parentId: 10,
                    accountId: null,
                    taskList: {taskListId: 1},
                    taskTags: [],
                    createdBy: {
                        orguserId: 1
                    },
                    modifiedTime: 1589281134929,
                    createdTime: 1589281134932,
                    deleted: false,
                    deletedBy: null,
                    deletedTime: null,
                    recurring: false
                } */
</style>

