<template>
		<div class="flex--row flex--ac flex--sbw">
			<router-link :to="'/tasks/'+taskInput.taskId" class="kbn-card-task-ttl">
				<span class="flex--one clip-txt dIB">{{taskInput.taskTitle}}</span>
			</router-link>
			<av-task-assignee :owners="taskInput.owners"></av-task-assignee>
		</div>
		<div class="flex--row flex--ac flex--sbw list-itm-misc">

			<el-date-picker :editable="false" v-if="editDateInp == taskInput.taskId ? true : false " popper-class="DDClass" class="datePicker cp" ref="dueDateInp"
				@blur="updatedueDateForTask(taskInput)"  @change="this.$refs.dueDateInp.focus()" format="DD/MM/YYYY" v-model="dueDatePicker" type="date" />
				
			<div v-else class="flex--row flex--ac">
				<span @click="openDueDateInp(taskInput)" v-if="taskInput.dueDate !== null" class="flex--one clip-txt cp">{{$lxnFormatDate(taskInput.dueDate)}}</span>
				<av-icon @click="openDueDateInp(taskInput)" v-else class ="calIcon cp icon medium" name="calendar-icon"></av-icon>
			</div>

			<div v-if="switchingPriorityField" class="pri-field">
				<img class="flag" src="../icons/flag.png" />
				<span v-if="taskInput.priority === null" class=" clip-txt">None</span>
				<span v-else class="flex--one clip-txt">{{taskInput.priority.priorityLabel}}</span>
			</div>

			<span v-if="switchingTaskIdField" class="flex--row flex--ac ">
				#{{ taskInput.taskId }}
			</span>
			<!-- <span v-if="switchingSubTaskCountField" class="flex--row flex--ac">
				<av-icon class="medium" name="hierarchy"></av-icon>
				{{taskVsSubtaskCount[taskInput.taskId]}}
			</span> -->
		</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const usersState = createNamespacedHelpers('users');

import avTaskAssignee from '@/components/av-task-assignee.vue';
import avIcon from '@/components/av-icon.vue';

export default {

	components : {
		'av-task-assignee' : avTaskAssignee,
		'av-icon' : avIcon
	},

	computed : {
		...usersState.mapState([
			'currentAuthUser',
			'preferenceForUser'
		]),

		switchingPriorityField(){
			return this.preferenceForUser.userPreferences.taskColumns.some((field) => {
				return field === 'showPriority';
			});
		},

		switchingTaskIdField(){
			return this.preferenceForUser.userPreferences.taskColumns.some((field) => {
				return field === 'showId';
			});
		},
		
		switchingSubTaskCountField(){
			return this.preferenceForUser.userPreferences.taskColumns.some((field) => {
				return field === 'subTaskCount';
			});
		}
		
	},
	
	created(){
		
	},

	props: {
		taskInput: {
			type: Object
		}
	},
	data() {
		return {
			tasksForStatusMap : {},
			taskVsSubtaskCount : {
				1 : 12,
				2 : 37
			},
			editDateInp : null,
			dueDatePicker : ''
		};
	},
	methods : {

		openDueDateInp(task){
            this.editDateInp = task.taskId;
            if(task.dueDate === null){
                this.dueDatePicker = '';
            }
            else{
                this.dueDatePicker = task.dueDate;
            }
            setTimeout(() => {
                this.$refs.dueDateInp.focus();
            }, 100);

        },

		updatedueDateForTask(task){
			let el = document.querySelector('.DDClass');
            setTimeout(() =>{
                let childEl = el.querySelector(":scope > .el-date-picker");
                let visible = childEl.getAttribute('actualvisible');

                if(visible === 'false'){
                    if(this.dueDatePicker === null || this.dueDatePicker === ''){
                        task.dueDate = null;
                    }
                    else{
                        task.dueDate = new Date(this.dueDatePicker).getTime();
                    }
                    this.$store.dispatch("tasks/updateTaskDetails", task);
                    this.editDateInp = false;
                    this.dueDatePicker = '';
                }
            }, 500);
		}
	}
};
</script>
<style>
.kbn-card-task-ttl{
	width: 70%;
}
.list-itm-misc{
	margin-top: 0.5rem;
}
.list-itm-misc .flag {
    margin-right: 10px;
    width: 16px;
}
.pri-field{
	margin: 0 10px;
}
.list-itm-misc .el-date-editor.el-input, .el-date-editor.el-input__inner{
	width: 50%;
	line-height: 30px;
	/* margin: 0 15px; */
}
.list-itm-misc .datePicker .el-input__inner{
	height: 30px;
	padding-left: 5px;
	padding-right: 5px;
}
.list-itm-misc .datePicker .el-input__prefix{
	display: none;
}
.list-itm-misc .datePicker .el-input__suffix{
	right: 10px;
}
.list-itm-misc .datePicker .el-input__suffix .el-input__icon{
	line-height: 25px;
}
</style>
