<template>
        <el-drawer custom-class="subtask-details-view" size="100%" v-model="drawer" :with-header="false" :destroy-on-close="true"
    :close-on-press-escape="false" @closed="afterSubtaskPageClosed">
                <div class="subview-ctr flex--row">
                    <div class="subtask-nav-bar txt14">
                        <div class="flex--row flex--ac hdr">
                            <span class="txt13">All subtasks in the tasks</span>
                            <av-icon class="cp icon mlA" name="hide-menu-24"></av-icon>
                        </div>
                        <ul>
                            <template  v-for="subtask in subTasks" :key="subtask.taskId">
                                <li  class="task-itms flex--row flex--ac cp clip-txt subtask-li" :class="{'selected' :selectedSubtask.taskId === subtask.taskId}" >
                                    <router-link :to="'/tasks/'+this.parentId+'/subtasks/'+subtask.taskId" class="subtask-name flex--one">
                                        <span class="status dIB" :style="{ backgroundColor: subtask.status.color }"></span>
                                        <span class="key-fld"> {{ subtask.taskTitle }} </span>
                                    </router-link>
                                </li>
                            </template>

                        </ul>
                    </div>
                    <!-- Middle -->
                    <transition name="fade-router" mode="out-in">
                        <div v-if="selectedSubtask.taskId" :v-model="selectedSubtask" class="subview-bdy flex--col flex--one-oflw" :key="selectedSubtask.taskId">
                            <div class="subtask sub-task-body flex--row flex--one-oflw">
                                <div class="sub-task-body-head flex--one-oflw flex--col">
                                    <div class="task-hdr">
                                            <span class="title" ref = "subtaskTitle" contenteditable @blur="editSelectedsubtask"> {{ selectedSubtask.taskTitle }} </span>
                                    </div>
                                    <div class="subtask-left-pnl flex--one-oflw ">
                                        <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                            <span class="lbl">Description</span>
                                        </div>
                                        <span v-if="subtaskDesc" @mouseenter="editIcon = true" @mouseleave="editIcon = false" class="txt14 desc dB">{{ selectedSubtask.description }}
                                            <img @click="subtaskDesc = false" v-if="editIcon" class="cp" src="../icons/edit-checklist.png">
                                        </span>
                                        <div v-else class="flex--row text-editor-pnl">
                                        <el-input class="desc-input" v-model="selectedSubtask.description" resize="none" @blur="editsubtaskDesc" autofocus autosize type="textarea" placeholder="Enter description" />
                                    </div>
                                        <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                            <span class="lbl">{{ $t("av.tasks.details.tag") }} {{selectedSubtask.taskTags.length ? `(${selectedSubtask.taskTags.length})` : ''}}</span>
                                            <av-icon class="cp icon medium" name="add-plus-24" @click="subTagdialogue"></av-icon>
                                        </div>
                                        <div v-if="selectedSubtask.taskTags && selectedSubtask.taskTags.length > 0" class="tag-chips-hldr">
                                            <el-tag v-for="tag in selectedSubtask.taskTags"  :key="tag.taskTagId" effect="dark" size="small" class="tag-chip"
                                                    :style="{ backgroundColor: tag.color }" >
                                                    {{tag.tagName}}
                                            </el-tag>
                                        </div>

                                         <el-dialog custom-class="sub-tag-dia" v-model="tagdialogue" title="Tags" width="30%">
                                                <div class="uxTagBox">
                                                    <el-tag class="tag-chip" v-for="tag in existsSubTaskTag" :key="tag" @close="closeTag(tag)" round effect="dark" closable size="small" >
                                                        {{tag.tagName}}
                                                    </el-tag>
                                                    <el-select ref="tagInput" v-model="sttagTitle" @change="changeFilteredTag" multiple filterable
                                                        allow-create
                                                        default-first-option
                                                        :reserve-keyword="false"
                                                        placeholder="Add or Choose tags for your task"
                                                    >
                                                        <el-option
                                                        v-for="tag in filteredTags"
                                                        :key="tag.taskTagId"
                                                        :label="tag.tagName"
                                                        :value="tag.taskTagId"
                                                        />
                                                    </el-select>
                                                </div>
                                                    <template #footer>
                                                    <span class="dialog-footer">
                                                        <el-button @click="tagdialogue = false">Cancel</el-button>
                                                        <el-button type="primary" @click="updateUXTags"
                                                        >Save</el-button
                                                        >
                                                    </span>
                                                    </template>
                                        </el-dialog>

                                        <div class="attach-tab sub-hdrs txt14 font-med flex--row flex--ac">
                                            <span class="lbl">{{ $t("av.tasks.details.attachments") }} {{subTaskAttachments.length ? `(${subTaskAttachments.length})` : ''}}</span>
                                             <label class="attach-upload-label" for="file-input">
                                                <av-icon class="cp icon medium" name="add-plus-24"></av-icon>
                                            </label>
                                            <input class="attach-upload-input" id="file-input" type="file" name="Test" multiple @input="attachmentUpload"/>
                                        </div>

                                        <div class="attachInp txt14 font-med flex--row flex--ac flex--wrap">
                                            <template v-if="subTaskAttachments && subTaskAttachments.length > 0"  >
                                                <div class="attachInp-cont" v-for="attachment in subTaskAttachments" :key="attachment.attachmentId">
                                                        <av-icon class="cp icon large" name="account-circle-24"></av-icon>
                                                    <div class="attachInp-data">
                                                        <span>{{ attachment.attachmentName }}</span>
                                                        <div class="flex--row">
                                                            <span>{{ bytesForHuman(attachment.size) }}.</span>
                                                            <span>{{ moment(attachment.createdTime).format('ll') }}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img src="../assets/delete.png" @click="deleteAttachment(attachment.attachmentId)"/>
                                                        <a @click="downloadAttachment(attachment.attachmentId)" :href='attachmentURL'><img src="../assets/download.png"/></a>
                                                        <!-- <img src="http://localhost:8080/attachments/2"/> -->
                                                    </div>
                                                
                                                </div>
                                            </template>
                                        </div>
                
                                        <div class="sub-hdrs txt14 font-med flex--row flex--ac">
                                            <span class="lbl">{{ $t("av.tasks.details.comments") }} {{subTaskcomment.length ? `(${subTaskcomment.length})` : ''}}</span>
                                            <!-- <av-icon class="cp icon medium" name="add-plus-24"></av-icon> -->
                                        </div>

                                        <!-- Subtask-comments -->

                                        <div class="flex--row" >
                                            <div class="flex--col commentSection">
                                                <div class="comment-chat-box flex--col">
                                                    <div class="flex--row comment-div" v-for="(comment, index) in subTaskcomment" :key="index" >
                                                        <img class="profile-pic-comments" src="http://loremflickr.com/100/50?img=2222"
                                                        />
                                                        <div class="flex--col commentText">
                                                            <span class="comment-title"
                                                                >{{ comment.createdUser.user.firstName }}
                                                                {{ comment.createdUser.user.lastName }}
                                                            </span>
                                                            <textarea  type="text" class="cmt-edit-input"
                                                                :ref="'commentSpan' + index"
                                                                v-if="editMode === index"
                                                                v-model="comment.comment"
                                                            ></textarea>

                                                            <span v-else class="comment-comments"> {{ comment.comment }}</span>
                                                            <div class="crud-tab flex--row flex--ac">
                                                                <template v-if="currentAuthUser.userId === comment.createdUser.user.userId">
                                                                    <el-button class="comment-btns" v-if="visible == index"  size="small"
                                                                    type="text"
                                                                    @click="updateComment(index)"
                                                                    >save</el-button >

                                                                    <el-button v-if="visible == index" class="comment-btns" @click="cancelComment"  size="small"
                                                                    type="text"
                                                                    >cancel</el-button>

                                                                    <el-button v-else class="comment-btns"  size="small" type="text"
                                                                    @click="editComment(index)"
                                                                    >edit</el-button>

                                                                    <el-button class="comment-btns" size="small" type="text" @click="delPopup(comment.taskCommentId)">delete</el-button>
                                                                </template>
                                                                <el-dropdown :show-arrow="false">
                                                                    <span class="el-dropdown-link">
                                                                        <span class="cmt-timeline" :ref='"commentTime" + index' >{{ this.time[this.subTaskcomment[index].taskCommentId]  }}</span>
                                                                    </span>
                                                                    <template #dropdown>
                                                                        <el-dropdown-menu class="timeStamp-menu">
                                                                            <el-dropdown-item>{{ moment(comment.createdTime).format("MMM DD YYYY, h:mm a") }}</el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </template>
                                                                </el-dropdown>

                                                                <el-dialog
                                                                v-if="dialogVisible === comment.taskCommentId"
                                                                v-model="dialogVisible"
                                                                title="Delete Comment"
                                                                width="30%"
                                                                >
                                                                    <span>Are you sure you want to delete this comment?</span>
                                                                    <template #footer>
                                                                        <span class="dialog-footer">
                                                                            <el-button @click="dialogVisible = false">Cancel</el-button>
                                                                            <el-button  type="primary" @click="deleteComment(index)"
                                                                                >Confirm</el-button
                                                                            >
                                                                        </span>
                                                                    </template>
                                                                </el-dialog>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex--row">
                                                    <div>
                                                        <img
                                                            class="profile-pic-comments"
                                                            src="http://loremflickr.com/100/50?img=2222"
                                                        />
                                                    </div>
                                                    <el-input class="commentText" type="text" size = "mini" :placeholder="$t('av.tasks.inlineaddcomment.placeholder.name')"
                                                        v-model="AddComment"
                                                        @keydown.enter="addComment"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            <div class="subtask-right-pnl">
                                <div class="subtask-popup-div flex--row">
                                    <!-- <div class="sbt-due-date">
                                        <el-date-picker format="DD MMM" @change="dueDate" v-model ="inlineSubtaskDuedate" type="number"></el-date-picker>
                                    </div> -->
                                        <div class="subtask-pri-popup">
                                            <div class="drp-dwn-opt flex--row flex--ac">
                                                <el-dropdown trigger="click">
                                                        <div v-if ="selectedSubtask.priority === null">
                                                           <span class="txt14 lbl"> <img class="flag" src="../icons/flag.png" />None</span>
                                                        </div>
                                                        <div v-else>
                                                             <span class="txt14 lbl"> <img class="flag" src="../icons/flag.png" />{{ selectedSubtask.priority.priorityLabel }}</span>
                                                        </div>
                                                    <template #dropdown>
                                                        <el-dropdown-menu>
                                                            <el-dropdown-item class="flex--row flex--ac txt14 cp" v-for="(priority, index) in priorities" :key="index">
                                                                <span :ref="'priority' + index" @click="setPriority(priority.taskPriorityId)"> <img class="flag" src="../icons/flag.png" />{{ priority.priorityLabel }}</span>
                                                            </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </template>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    <div class="flex--row">
                                        <div class="subtask-sts-popup">
                                            <div class="drp-dwn-opt flex--row flex--ac">
                                                <el-dropdown trigger="click">
                                                    <div class="cp flex--ac flex--row">
                                                        <span class="status dIB" :style="{ backgroundColor: selectedSubtask.status.color }"></span>
                                                        <span class="txt14">{{ selectedSubtask.status.statusName }}</span>
                                                    </div>
                                                    <template #dropdown>
                                                        <el-dropdown-menu>
                                                            <el-dropdown-item class="flex--row flex--ac txt14 cp" v-for="(status, index) in statuses" :key="index">
                                                                <span class="status dIB" :style="{ backgroundColor: status.color }"></span>
                                                                <span :ref ="'status'+index" @click="setStatus(status.taskStatusId)">{{ status.statusName }}</span>
                                                            </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </template>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                        <div class="rght-arrow"><img src="../icons/right-arrow.png" /></div>
                                    </div>
                                        <span><av-icon class="cp rpnl-itms rpnl-icon regular" name="more-horizontal"></av-icon></span>
                                        <div class="close-btn">
                                            <button class="close-btn cp" type="button" @click ="closeTasks()">X</button>
                                        </div>
                                    </div>
                                    <div class="subtask-right-pnl-content">
                                        <av-task-details-rightpanel :task-obj="selectedSubtask" :isTasks="false" @subtaskStDate="stStartdate" @subtaskDueDate="stDueDate"
                                            @updateTaskCollab="updateSubTaskCollaborator" @updateTaskFollower="updateSubTaskFollowers" ></av-task-details-rightpanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
        </el-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('tasks');
const prioritiesState = createNamespacedHelpers('taskpriorities');
const statusesState = createNamespacedHelpers('taskstatuses');
const taskTagsState = createNamespacedHelpers('tasktags');
const usersState = createNamespacedHelpers('users');

import { ElMessage } from 'element-plus';
import avIcon from '@/components/av-icon.vue';
import moment from 'moment';
import AvTasksDetailsRightPanel from '@/components/av-task-details-rightpanel.vue';

export default {
	components : {
		'av-icon' : avIcon,
		'av-task-details-rightpanel' : AvTasksDetailsRightPanel
	},
	data: function () {
		return {
            parentId : '',
            subtaskDesc : true,
            editIcon : false,
            inlineSubtaskDuedate : '',
            commentsObj : {},
            subTaskObj : {},
            idsObj : {},
            AddComment : "",
            editMode: null,
            visible: null,
            dialogVisible: null,
            drawer : true,
            selectedSubtask : {},
            newArr : {
                priority : {}
            },
            existsSubTaskTag: [],
            tagdialogue : false,
            sttagTitle : [],
            newTagObj : {},
            generatenewTags : [],
            taskAttach : {},
            attachmentURL: '',
            time:{}

		};
	},
	mounted(){
		// $$avQS('.el-drawer.subtask-details-view > .el-drawer__header').remove();
	},
	created() {
		this.$watch(
			() => this.$route.params,
			(toParams /*, previousParams */) => {
                if(toParams.subtaskId){
                    this.getSelectedSubtaskObj(toParams.taskId, toParams.subtaskId);
                    
                }
			}
		);
		this.getSelectedSubtaskObj(this.$route.params.taskId, this.$route.params.subtaskId);
        this.moment = moment;
	},
		computed: {
		...mapState([
			'tasks',
            'subTasks',
            'subTaskcomment',
            'subTaskAttachments'
		]),
        ...prioritiesState.mapState([
			'priorities'
		]),
        ...statusesState.mapState([
			'statuses'
		]),
        ...taskTagsState.mapState([
			'taskTags'
		]),
        ...usersState.mapState([
			'currentAuthUser'
		]),

        filteredTags(){

            // taskTags
            let existsUxTag = this.taskTags.filter((tag) => { return tag; });

            let iterateTag = existsUxTag.filter(uxTag => !this.existsSubTaskTag.some(tag => uxTag.taskTagId === tag.taskTagId));

            return iterateTag;
        }
	},
	methods : {
        closeTasks(){
            this.drawer = false;
        },

        afterSubtaskPageClosed(){
            this.$router.push('/tasks/' + this.parentId);
         },

		async getSelectedSubtaskObj(taskId, subtaskId){
            this.parentId = taskId;
            await this.$store.dispatch('tasks/getsubTask', taskId);
            this.idsObj.parentTaskId = taskId;
            this.idsObj.taskId = subtaskId;
            this.selectedSubtask = await this.$store.dispatch('tasks/selectedsubTask', this.idsObj);
            this.$store.dispatch('tasks/getSubTaskAttachment', this.idsObj);
            this.subTaskComments(taskId, subtaskId);
            // this.inlineSubtaskDuedate = this.selectedSubtask.dueDate;
            
		},


        editSelectedsubtask(){
            let title =  this.$refs.subtaskTitle.innerText.trim();
            this.selectedSubtask.taskTitle = title;
            this.selectedSubtask.parentTaskId = this.parentId;
            this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);

        },


        editsubtaskDesc(){
            this.subtaskDesc = true;
            // this.selectedSubtask.taskTitle = title;
            this.selectedSubtask.parentTaskId = this.parentId;
            this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        },

        subTagdialogue(){
            this.tagdialogue = true;
            this.existsSubTaskTag = this.selectedSubtask.taskTags.filter((tag) => { return tag; });
            this.$nextTick(() => {
                this.$refs.tagInput.focus();
            });
        },


        changeFilteredTag(value){

            if(this.sttagTitle !== [] && typeof value[0] !== 'number' ){

                    let val = value.toString().replace(/\s+/g, "").toLowerCase();
                    this.sttagTitle = [];

                    if(!this.existsSubTaskTag.some(item => item.tagName === val)){
                        this.newTagObj.tagName = val.toString();
                        this.newTagObj.color = "#cccccc";
                        this.existsSubTaskTag.push(this.newTagObj);
                        this.newTagObj = {};
                        
                    }
                    else{
                        ElMessage({
                            message: `The Tag ${val} already exists.`,
                            type: 'warning'
                        });
                    }

            }

            else{
                this.existsSubTaskTag.push(this.taskTags.find( tag => tag.taskTagId === value[0]));
                this.sttagTitle = [];
            }

            this.createTagsForTask();
        },

        createTagsForTask(){
            this.generatenewTags = this.existsSubTaskTag.filter(a => !this.taskTags.some(tag => tag.tagName === a.tagName));
        },

        async updateUXTags(){
            let existsTag = this.existsSubTaskTag.filter(a => !this.generatenewTags.some(tag => tag.tagName === a.tagName));
            for(let i = 0; i < this.generatenewTags.length; i++){
                let newTagRes = await this.$store.dispatch("tasktags/createTaskTag", this.generatenewTags[i]);
                existsTag.push(newTagRes);
            }
            this.tagdialogue = false;
            this.selectedSubtask.taskTags = existsTag;
            this.selectedSubtask.parentTaskId = this.parentId;
            let response = await this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
            this.selectedSubtask = response;

        },

        closeTag(tag){
            this.existsSubTaskTag.splice(this.existsSubTaskTag.indexOf(tag), 1 );
            this.createTagsForTask();
            this.$nextTick(() => {
                this.$refs.tagInput.focus();
            });
        },

        attachmentUpload(event){
            this.taskAttach.parentId = this.parentId;
            this.taskAttach.taskId = this.selectedSubtask.taskId;
            for(let i = 0; i < event.target.files.length; i++){
                if(!this.subTaskAttachments.some(attach => attach.attachmentName === event.target.files[i].name)){
                        this.taskAttach.file = event.target.files[i];
                        this.$store.dispatch('tasks/subTaskAttachmentUpload', this.taskAttach);
                }
                else{
                      ElMessage({
                        message: `The file ${event.target.files[i].name} already exists.`,
                        type: 'warning'
                    });
                }
            }
        },

        downloadAttachment(attachmentId){
            this.attachmentURL = window.location.origin + "/attachments/" + attachmentId;
        },

        async deleteAttachment(attachmentId){
            this.taskAttach.parentTaskId = this.parentId;
            this.taskAttach.taskId = this.selectedSubtask.taskId;
            this.taskAttach.attachmentId = attachmentId;
            await this.$store.dispatch('tasks/deletesubTaskAttachments', this.taskAttach);
            await this.$store.dispatch('tasks/getSubTaskAttachment', this.taskAttach);
        },

        bytesForHuman(bytes) {
            let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

            let i = 0;
            
            for (i; bytes > 1024; i++) {
                bytes /= 1024;
            }

            return bytes.toFixed(1) + ' ' + units[i];
        },

        dueDate(){
            this.selectedSubtask.parentTaskId = this.parentId;
            let date = new Date(this.inlineSubtaskDuedate).getTime();
            this.selectedSubtask.dueDate = date;
            this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        },

        async setPriority(priorityId) {
            this.selectedSubtask.parentTaskId = this.parentId;
            this.selectedSubtask.priority = this.priorities.find(p => p.taskPriorityId === priorityId);
            let response = await this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
            this.selectedSubtask = response;
        },

        async setStatus(statusId){
            this.selectedSubtask.parentTaskId = this.parentId;
            this.selectedSubtask.status = this.statuses.find(p => p.taskStatusId === statusId);
            let response = await this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
            this.selectedSubtask = response;

        },


        realtimeStamp(){

            setInterval(() =>  {
                
                for(let i = 0; i < this.subTaskcomment.length; i++){
                    this.time[this.subTaskcomment[i].taskCommentId] = moment(this.subTaskcomment[i].createdTime).fromNow();
                }
             }, 100);
               
            
        },

        async subTaskComments(taskId, subtaskId){
            this.commentsObj.taskId = taskId;
            this.commentsObj.subtaskId = subtaskId;
            await this.$store.dispatch('tasks/getsubTaskcomments', this.commentsObj);
            this.realtimeStamp();
        },

        async addComment(){
            let content =  this.AddComment.trim();
            if( content === ""){
                this.AddComment = "";
            }
            else{
                this.commentsObj.comment = this.AddComment.trim();
                this.commentsObj.parentTaskId = this.parentId;
                this.commentsObj.taskId =  this.selectedSubtask.taskId;
                await this.$store.dispatch("tasks/subTaskcreateComment", this.commentsObj);
                this.AddComment = "";

            }

        },

        delPopup(commentId){
            this.dialogVisible = commentId;
        },
        async deleteComment(index) {
            this.commentsObj.taskId = this.parentId;
            this.commentsObj.subtaskId =  this.selectedSubtask.taskId;
            this.commentsObj.taskCommentId = this.subTaskcomment[index].taskCommentId;
            this.dialogVisible = false;
            await this.$store.dispatch("tasks/subTaskdeleteComment", this.commentsObj);
            this.$store.dispatch(
                "tasks/getsubTaskcomments",
                this.commentsObj
            );
        },
        editComment(index) {
            let refVal = 'commentSpan';
            refVal += index;
   
            this.editMode = index;
            this.visible = index;
            setTimeout(() => {
                this.$refs[refVal].focus();
            }, 100);
            
        },

        cancelComment(){
            this.editMode = null;
            this.visible = null;
        },

        async updateComment(index) {
   
            // if( content === ""){
            //     this.$alert("Please fill the comment");
            // }
            // else{
            let refVal = "commentSpan";
            refVal += index;
            let content = this.$refs[refVal].value.trim();
            this.editMode = null;
            this.visible = null;
            this.subTaskcomment[index].comment = content;
            this.subTaskcomment[index].parentId = this.parentId;
            this.subTaskcomment[index].taskId =  this.selectedSubtask.taskId;
            // this.subTaskcomment[index].taskCommentId = this.subTaskcomment[index].taskCommentId;
            await this.$store.dispatch("tasks/subTaskupdateComment", this.subTaskcomment[index]);
  
            // }
        },

        async updateSubTaskCollaborator(newValue){
            this.selectedSubtask.owners = newValue;
            this.selectedSubtask.parentTaskId = this.parentId;
            this.selectedSubtask = await this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        },

        async updateSubTaskFollowers(newValue){
            this.selectedSubtask.followers = newValue;
            this.selectedSubtask.parentTaskId = this.parentId;
            this.selectedSubtask = await this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        },

        stStartdate(newValue){
            this.selectedSubtask.startDate = new Date(newValue).getTime();
            this.selectedSubtask.parentTaskId = this.parentId;
            this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        },

        stDueDate(newValue){
            let date = new Date(newValue).getTime();
            this.selectedSubtask.dueDate = date;
            this.selectedSubtask.parentTaskId = this.parentId;
            this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubtask);
        }

	}

};
</script>
<style>
.fade-router-enter-from,
.fade-router-leave-to
 {
    opacity: 0;
}
.fade-router-enter-active{
    transition: opacity 0.2s ease-out;
}
.fade-router-leave-active{
    transition: opacity 0.2s ease-in;
}
.fade-router-enter-to,
.fade-router-leave-from{
    opacity: 1;
}
.subview-ctr .sub-task-body{
    width: 100%;
}
.subtask > .sub-task-body-head > .prnt-task-ttl{
    padding: 1px 7px;
    border-radius: 32px;
    background: lightblue;
    color: #2c2a2a;
}
.subtask > .sub-task-body-head > .task-hdr{
    padding: 0.7rem 0;
}
.subtask > .sub-task-body-head > .task-hdr > .title{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.subtask > .sub-task-body-head > .task-hdr > .title:hover{
    background-color: var(--color-22);
    border: solid 0.1rem #5cacf5;
}
.sub-task-body-head .sub-tag-dia .uxTagBox{
    border: 1px #eee solid;
    max-width: 80%;
    padding: 5px;
}
.sub-task-body-head .sub-tag-dia .uxTagBox .el-select {
    width: 100%;
}
.sub-task-body-head .sub-tag-dia .uxTagBox .el-input--suffix .el-input__inner, .sub-task-body-head .sub-tag-dia .uxTagBox .el-select .el-input.is-focus .el-input__inner{
    border: none;
}
.sub-task-body-head .sub-tag-dia .uxTagBox .el-input__suffix {
    display: none;
}
.sub-task-body-head > .subtask-left-pnl .tag-chips-hldr > .tag-chip{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
}
.sub-task-body-head > .subtask-left-pnl > .attach-tab .attach-upload-label{
    display: flex;
    margin-bottom: 0;
}

.sub-task-body-head > .subtask-left-pnl > .attach-tab .attach-upload-label > .icon{
    color: var(--color-6);
}
.sub-task-body-head > .subtask-left-pnl > .attach-tab .attach-upload-input{
    display: none;
}

.sub-task-body-head > .subtask-left-pnl > .attachInp > .attachInp-cont > .icon{
    color: var(--color-6);
    margin-right: 7px;
}

.sub-task-body-head > .subtask-left-pnl > .attachInp > .attachInp-cont{
    display: flex;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.sub-task-body-head > .subtask-left-pnl > .attachInp > .attachInp-cont > .attachInp-data{
    margin-right: 5px;
    width: 200px;
}
.sub-task-body-head > .subtask-left-pnl > .attachInp > .attachInp-cont > .attachInp-data span{
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    word-spacing: 3px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
}

.task-itms > .subtask-name{
    padding: 1rem 1.5rem;
}

.txt13{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.subtask-li{
    width: 100%;
    cursor: pointer;
    height: 3.25rem;
}
.subtask-li:hover{
    background: var(--color-9);
    opacity: 1;
}
.status{
    height: 1rem;
    width: 1rem;
    border-radius: 0.225rem;
    margin-right: 5px;
}
.ylw{
    height: 0.875rem;
    width: 0.875rem;
    border-radius: 0.125rem;
}
.task-itms .key-fld{
    padding-left: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    display: inline-block;
}

.sub-task-body-head > .subtask-left-pnl .commentSection {
  width: 100%;
}

.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box{
  margin-bottom: 10px;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div{
    padding: 5px 10px;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div:hover{
    background: var(--color-9);
    opacity: 1;
    box-shadow: inset 0px -1px 0px #f3f3f3;
    cursor: pointer;
    /* color: #0056b3; */
}

.sub-task-body-head > .subtask-left-pnl .commentSection .profile-pic-comments {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText {
  /* margin-left: 14px; */
    width: 100%;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText .crud-tab{
    padding: 5px 0;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText .el-button--small{
    padding: 0px 0;
    min-height: 0;
    margin-right: 10px;
    margin-left: 0;

}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div > .commentText > .comment-title{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3F3F3F;
    margin-bottom: 5px;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText > .comment-comments{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    word-spacing: 3px;
}
.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText .comment-btns > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6F6F6F;
}

.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div .cmt-edit-input{
    border: 1px solid lightblue;
    border-radius: 4px;
    padding: 3px 5px;
    height: 80px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
}

.sub-task-body-head > .subtask-left-pnl .commentSection > .comment-chat-box > .comment-div >  .commentText .cmt-timeline{

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6F6F6F;
}
.timeStamp-menu{
    background-color: transparent;
    padding: 1px 0;

}
.timeStamp-menu > .el-dropdown-menu__item{
    font-size: 13px;
    padding: 0 7px;
    line-height: 26px;
    color: var(--color-2);
}

.close-btn > button {
    background: none;
    cursor: pointer;
    padding: 2px 8px;
}
.close-btn > button:hover {
    padding: 2px 8px;
    border-radius: 5px;
    border: none;
    background-color: #ededed;
}
.el-drawer.subtask-details-view > .el-drawer__body{
    height: 100%;
    width: 100%
}

.el-drawe.subtask-details-view > .el-drawer__body,
.subtask-details-view .subview-ctr{
    height: 100%;
    width: 100%
}

.subtask-details-view .subtask-nav-bar{
    width: 20%;
    height: 100%;
    overflow: auto;
    /* border: 0.0625rem solid var(--color-9); */
}
.subtask-details-view .subtask-nav-bar > .hdr{
    height: 4rem;
    padding: 0 1.5rem;
}
.subtask-details-view .subtask-nav-bar > .hdr > .icon{
    color: var(--color-6);
}
.subtask-details-view .subtask-nav-bar .selected{
    background: var(--color-9);
    opacity: 1;
}
.subtask-details-view .subview-bdy{
    width: 80%;
    padding: 0 1.5rem;
    border: 0.0625rem solid var(--color-9);
    
}
.subtask-details-view .subview-bdy > .task-hdr{
    height: 3.375rem;
}
.subtask-details-view .sub-task-body > .sub-task-body-head{
    width: 70%;
}
.subtask-details-view .sub-task-body > .sub-task-body-head > .subtask-left-pnl{
    padding-right: 2rem;
    border-right: 0.0625rem solid var(--color-9);
}
.subtask-details-view .sub-task-body > .sub-task-body-head > .subtask-left-pnl > .desc{
    color: var(--color-11);
    /* padding-bottom: 0.75rem; */
}
.subtask-details-view .sub-task-body > .sub-task-body-head > .subtask-left-pnl > .sub-hdrs{
    height: 3rem;
}
.subtask-details-view .sub-task-body > .sub-task-body-head > .subtask-left-pnl > .sub-hdrs > .lbl{
    padding-right: 0.5rem;
}
.subtask-details-view .sub-task-body > .sub-task-body-head > .subtask-left-pnl > .sub-hdrs > .icon{
    color: var(--color-6);
}
.subtask-details-view .subview-bdy > .sub-task-body > .subtask-right-pnl{
    width: 35%;
    overflow: auto;
}
.subtask-details-view .subview-bdy > .sub-task-body > .subtask-right-pnl > .subtask-popup-div{
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1.5rem;
    padding-top: 0.5rem;

}
.subtask-details-view .subview-bdy > .sub-task-body > .subtask-right-pnl > .subtask-popup-div > .sbt-due-date{
    width: 23%;
}
.sbt-due-date .el-date-editor.el-input{
    width: 100%;
}
.sbt-due-date > .el-input > .el-input__inner{
    border-radius: 1px;
    border: 1px solid #5964DD;
    height: 25px;
    padding-right: 2px;
}
.subtask-pri-popup > .drp-dwn-opt .el-dropdown-selfdefine{
    border: 1px solid #5964DD;
    border-radius: 1px;
    padding: 5px 15px;
    /* height: 25px; */
}
/* .subtask-pri-popup > .drp-dwn-opt .txt14 > img {
    margin-right: 10px;
    width: 16px;
} */
.flag {
    margin-right: 10px;
    width: 16px;
}

.subtask-sts-popup > .drp-dwn-opt .el-dropdown-selfdefine{
    border: 1px solid #5964DD;
    border-radius: 1px;
    padding: 5px 15px;
}

.subtask-popup-div .rght-arrow{
    padding: 1px 5px;
    height: 100%;
    border: 1px solid #545CA5;
    border-left: none;
}

.subtask-popup-div .rght-arrow > img{
    height: 12px;
    width: 7px;
}
.subtask-right-pnl > .subtask-popup-div svg.av-icon.regular{
    height: 1.55rem;
    width: 1.55rem;
}
.subtask-right-pnl-content{
    padding: 1rem 1.5rem;
}
</style>