<template>
    <ul id="subtaskprevw-nav" class="subtaskprevw-nav txt14" >
        <template v-for="(subtask, index) in subtasks" :key="subtask.taskId">
            <li class="task-itms flex--row flex--ac cp clip-txt"  @mouseleave="subtaskDeleteIcon = null"
                  @mouseover="subtaskDeleteIcon = subtask.taskId">
                <router-link :to="'/tasks/'+selectedParentTask.taskId+'/subtasks/'+subtask.taskId">
                    <span class="status dIB" :style="{ backgroundColor: subtask.status.color }"></span>
                    <span class="key-fld"> {{ subtask.taskTitle }}</span>
                </router-link>

                <el-date-picker class="sbt-due-date" :editable="false" format="DD-MMM" :disabled-date="disableDate" v-model="subtask.dueDate" @change="updateSubtaskDueDate(subtask)" type="number" size="small" />

                <el-dropdown trigger="click" :hide-on-click="false" popper-class="search-own-dd" @visible-change="updateOwnForSubtask">
                    <av-task-assignee class="sbt-ownwers" :is-sub-tasks="true" :owners="subtask.owners" ></av-task-assignee>

                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-input v-model="searchOwner" class="searchInp"></el-input>
                        <p class="ttle">Org members</p>
                        
                        <p v-if="!filteredOrgMembers.length" class="nf-msg">Not Found</p>
                        <template v-for="orgMember in filteredOrgMembers" :key="orgMember.user">
                            <el-dropdown-item class="flex--row" @click="setOwnForSubtask(orgMember, subtask)" >
                                <span class="own-name">{{ orgMember.user.firstName }}</span>
                                <template v-for="owner in subtask.owners" :key="owner.user.userId">
                                    <img v-if="orgMember.user.userId === owner.user.userId" src="../icons/Shape.png">
                                </template>
                            </el-dropdown-item>
                        </template>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
                
                <img class="mlA" src="../assets/delete.png" v-if="subtaskDeleteIcon === subtask.taskId" @click="delSubtask(subtask.taskId)" />

                <el-dialog v-if="delSubVisible == subtask.taskId" v-model="delSubVisible" :before-close="cancelSubtask" title="Delete Subtask" width="30%">
                      <span>Are you sure you want to delete this subtask?</span>
                      <template #footer>
                        <span class="dialog-footer">
                          <el-button @click="cancelSubtask(index)">Cancel</el-button>
                          <el-button type="primary" @click="subTaskDelete(subtask.taskId)">Confirm</el-button>
                        </span>
                      </template>
                </el-dialog>

            </li>
        </template>
    </ul>
</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('tasks');
const orgState = createNamespacedHelpers('organizations');
import avTaskAssignee from '@/components/av-task-assignee.vue';

export default {
	components: {
		'av-task-assignee' : avTaskAssignee
	},
    props : {
		subtasks : Array,
		selectedParentTask : Object

	},
    data(){
        return{
            subtaskDeleteIcon: null,
            delSubVisible: null,
            subtaskObj: {},
            subtaskDueDate : [],
            searchOwner : '',
            selectedSubTask : {}
        };
    },

    mounted(){
        
    },

    computed: {
        ...mapState([
            'subTasks'
        ]),
        ...orgState.mapState([
			'currentOrg'
		]),
        filteredOrgMembers(){
			let filtMembers = this.currentOrg.members;

				if (this.searchOwner !== '' && this.searchOwner) {

					filtMembers = filtMembers.filter((memb) => {
                        return memb.user.firstName.toUpperCase().startsWith(this.searchOwner.toUpperCase());
					});
				}
				return filtMembers;
		}
    },

    methods:{

        disableDate(date){
            if(new Date(date).getTime() > new Date(this.selectedParentTask.dueDate).getTime() && this.selectedParentTask.dueDate !== null){
                return true;
            }
        },

        updateSubtaskDueDate(subtask){
            subtask.dueDate = new Date(subtask.dueDate).getTime();
            subtask.parentTaskId = this.selectedParentTask.taskId;
            console.log(subtask);
            this.$store.dispatch("tasks/updateSelectedsubTask", subtask);
        },

        setOwnForSubtask(orgMember, subtask){
            if(subtask.owners.find(owner => owner.user.userId === orgMember.user.userId)){
                subtask.owners.splice(subtask.owners.findIndex(owner => owner.user.userId === orgMember.user.userId), 1);
            }
            else{
                subtask.owners.push(orgMember);
            }
            this.selectedSubTask = subtask;
        },

        updateOwnForSubtask(val){
            if(val === false){
                this.selectedSubTask.parentTaskId = this.selectedParentTask.taskId;
                this.$store.dispatch("tasks/updateSelectedsubTask", this.selectedSubTask);
                this.selectedSubTask = {};
            }
        },

        delSubtask(taskId){
            this.delSubVisible = taskId;
        },
        cancelSubtask(){
            this.delSubVisible = false;
            this.subtaskDeleteIcon = null;

        },
        async subTaskDelete(taskId){

            this.subtaskObj.parentTaskId = this.selectedParentTask.taskId;
            this.subtaskObj.taskId = taskId;
            console.log(this.subTasks);
            this.subTasks.splice(this.subtasks.findIndex(a => a.id === taskId), 1);
            await this.$store.dispatch("tasks/deleteSubtask", this.subtaskObj);
            this.delSubVisible = false;

        }

    }
};
</script>
<style>
.subtaskprevw-nav > li.task-itms{
    width: 100%;
    border-bottom: 0.0625rem solid #e9e9e99c;
    padding: 6px 20px;
}
/* .subtaskprevw-nav:not(.subtasks) > li.task-itms{
    opacity: 0.6;
} */
/* .subtaskprevw-nav.subtasks > li{
    border-bottom: 0.0625rem solid var(--color-9);
} */
.subtaskprevw-nav.subtasks > li.add-sub-task{
    height: 2.25rem;
    padding-left: 1.5rem;
    color: var(--color-7);
}
.subtaskprevw-nav > li.task-itms.selected,
.subtaskprevw-nav > li.task-itms:hover{
    background : var(--color-9);
    opacity: 1;
}
.subtaskprevw-nav > li.task-itms > a[href]{
    /* padding: 1rem 1.5rem; */
    width: 60%;
    display: flex;
    text-decoration: none;
}
.subtaskprevw-nav > li.task-itms > a[href] > .status{
    height: 0.875rem;
    width: 0.875rem;
    border-radius : 0.125rem;
}
.subtaskprevw-nav > li.task-itms > a[href] > .key-fld {
    padding-left: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    width: 70%;
    word-spacing: 2px;
}
.subtaskprevw-nav > li.task-itms:hover .sbt-due-date .el-input__inner{
    background-color: var(--color-9);
}
.subtaskprevw-nav .sbt-due-date.el-date-editor.el-input{
    width: 16%;
    margin-right: 5px;
    height: 29px;
}
.subtaskprevw-nav .sbt-due-date.el-date-editor.el-input--small, .subtaskprevw-nav .sbt-due-date.el-date-editor.el-input--small .el-input__icon{
    line-height: 28px;
    color: #4b4e50a6;
}
.subtaskprevw-nav .sbt-due-date .el-input__inner{
    border: 1px solid transparent;
    padding: 0;
    padding-left: 25px;
    height: 25px;
}

.subtaskprevw-nav .sbt-due-date{
    border: 1px solid transparent;
    border-radius: 4px;
    border: 1px solid #100f0f47
}
.subtaskprevw-nav .sbt-due-date:hover{
    border: 1px solid #100f0fab
}
.sbt-ownwers{
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 1px 5px;
    
}
.sbt-ownwers:hover{
    border: 1px solid #acafb3;
    /* background-color:#fff ; */
}
.subtaskprevw-nav .sbt-due-date .el-input__prefix{
    left: 2px;
}
.subtaskprevw-nav .sbt-due-date .el-input__prefix .el-input__icon{
    font-size: 15px;
    line-height: 28px;
}
.subtaskprevw-nav .sbt-due-date .el-input__suffix{
    right: 0;
}
.search-own-dd .searchInp{
    padding: 5px 10px;
}
.search-own-dd .ttle{
    font-size: 15px;
    margin: 2px 12px;
}
.search-own-dd .nf-msg{
    text-align: center;
    margin: 10px 10px;
    font-size: 16px;
    color: #5e6c84de;
}
.search-own-dd .own-name{
    margin-right: auto;
}
.search-own-dd img{
    object-fit: none;
}
</style>