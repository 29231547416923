<template>
    <div class="search-container">
		<div class="search-box">
			<!-- <input class="srch-input" type="text" placeholder="search task" v-model="searchTask" /><span>{{ this.filteredTasks.length }} Results found </span> -->
				<el-input style="width: 60%" v-model="searchTask" :autofocus="true" placeholder="search task">
					<template #prepend>
						<el-select v-model="selectSetData"  placeholder="Select" style="width: 115px">
							<el-option label="All" value="1" />
							<el-option label="Tasks" value="2" />
						</el-select>
					</template>
				</el-input>
				<span style="margin-left : 15px">{{ this.filteredTasks.length }} Results found </span>

		</div>
		<table class="result-tab">
			<thead>
				<tr>
					<th>Results</th>
					<th>Type</th>
					<th>Last modified</th>
				</tr>
			</thead>
			<tbody>
				<tr class="txt14" v-for="task in filteredTasks" :key="task.taskId">
					<td><span v-html="$options.filters.highlight(task.taskTitle, searchTask)"></span></td>
					<td>Task</td>
					<td>{{ $lxnFormatDate(task.modifiedTime) }}</td>
				</tr>
			</tbody>
		</table>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapGetters } = createNamespacedHelpers('tasks');
export default {
	data () {
		return {
			searchTask : '',
			selectSetData : '1',
			filterArray : []

		};
	},
    created() {
        this.getAllTasks();
    },

	filters: {
		highlight(words, query) {
			let reg = new RegExp(query, 'gi');
			return words.replace(reg, (str) => {
				return '<span style=" font-weight: bold;">' + str + '</span>';
				});
		}
	},
    computed: {
		...mapState([
			'fields',
			'tasks'
		]),
		...mapGetters([
			'displayFields',
			'statusFieldObj'
		]),

		filteredTasks(){
			let filtTask = this.tasks;

				if (this.searchTask !== '' && this.searchTask) {
						filtTask = filtTask.filter((task) => {
						return task.taskTitle.toUpperCase().startsWith(this.searchTask.toUpperCase());
					});
				}
				// if (this.searchTask !== '' && this.searchTask) {
				// 	filtTask = filtTask.sort((a, b) => {
				// 			let fa = a.taskTitle.toUpperCase();
				// 			let fb = b.taskTitle.toUpperCase();
						
				// 			if (fa < fb) {
				// 				return -1;
				// 			}
				// 			if (fa > fb) {
				// 				return 1;
				// 			}
				// 			return 0;
							
				// 		});
				// }
					return filtTask;
		}

	},
	methods : {

        getAllTasks (){
			this.$store.dispatch('tasks/getAll');
		}

		// filteredTasks(){
		// 	console.log(this.searchTask.trim());
			// return this.tasks.filter((task)=>{
			// 	return task.taskTitle.toLowerCase().match(this.searchTask.toLowerCase().trim());

			// });
		// }
	}
};
</script>
<style>
.search-container{
	width: 100%;
	background-color: var(--color-5);
    padding: 0 1rem;
}
.search-container > .result-tab{
	border-collapse: collapse;
    table-layout: fixed;
    min-width: 99%;
    background-color: var(--color-white);

}
.search-container > .result-tab > thead{
	height: 3rem;
    /* position: sticky; */
    top: 0;
    background-color: var(--color-white);
    box-shadow: 0rem 0.1875rem 0.1875rem var(--color-5);
}
.search-container > .result-tab tr{
	height: 3.5rem;
}
.search-container > .result-tab th{
	width: auto;
	padding: 1rem;
}
.search-container > .result-tab td{
	width: auto;
	padding: 1rem;
}
.search-container > .result-tab > tbody tr:hover{
	background: #F4F4F4;
	box-shadow: 0px 1px 3px rgba(165, 165, 165, 0.5);
}
</style>
