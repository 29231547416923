<template>
    <div class="users-ctr flex--row flex--acjc">
        <div v-for="owner in owners" :key="owner.userId">
            <!-- <template v-if="owner.user !== null"> -->
                <!-- <el-tooltip :show-after=500 :content="owner.user.firstName" popper-class="tool-tip"> -->
                    <!-- <template v-if="owner.user.profilePicURL === null" >
                        <span :style="{ 'background-color': colorObj[index].color}"  class = "owner-icon flex--row flex--acjc cp">{{ owner.user.firstName.charAt(0).toUpperCase() }}</span>
                    </template>
                    <template v-else>
                        <img v-if="owner.user.provider !== 'local' && owner.user.profilePicName === null " class="user cp" :src='owner.user.profilePicURL'/>
                        <img v-else class="user cp" :src='origin + "/profilePic/" + owner.user.profilePicURL'/>
                    </template> -->
                <!-- </el-tooltip> -->

                <el-popover trigger="hover" placement="right-start" :show-after=300 :show-arrow="false" :width="250">
                    <div class="memb-pro-div">
                        <div class="memb-pro">
                            <template v-if="owner.user.profilePicURL === null" >
                                <span :style="{ 'background-color': owner.user.profilePicColor, 'color' : checkColorluminance(owner.user.profilePicColor)}"  class = "memb-color flex--row flex--acjc cp"></span>
                            </template>
                            <template v-else>
                                <img v-if="owner.user.provider !== 'local' && owner.user.profilePicName === null " class="user-icon  cp" :src='owner.user.profilePicURL'/>
                                <img v-else class="user-icon cp" :src='origin + "/profilePic/" + owner.user.profilePicURL'/>
                            </template>
                        </div>
                        <div class="memb-detail">
                            <p class="flex--row flex--ac">{{ owner.user.firstName[0].toUpperCase() + owner.user.firstName.slice(1) }}</p>
                            <p class="flex--row flex--ac"><av-icon class="cp medium" name="mail-icon"></av-icon>{{ owner.user.email }}</p>
                            <p v-if="!isSubTasks" class="flex--row flex--ac cp" @click="removeMember(taskId, owner.orgUserId)">remove from task</p>
                        </div>
                    </div>
                    <template #reference>
                        <span @mouseover="visible=true" @mouseleave="visible=true">
                            <template v-if="owner.user.profilePicURL === null" >
                                <span :style="{'background-color': owner.user.profilePicColor, 'color' : checkColorluminance(owner.user.profilePicColor)}"  class = "owner-icon flex--row flex--acjc cp">{{ owner.user.firstName.charAt(0).toUpperCase() }}</span>
                            </template>
                            <template v-else>
                                <img v-if="owner.user.provider !== 'local' && owner.user.profilePicName === null " class="user cp" :src='owner.user.profilePicURL'/>
                                <img v-else class="user cp" :src='origin + "/profilePic/" + owner.user.profilePicURL'/>
                            </template>
                        </span>
                    </template>
                </el-popover>
            <!-- </template> -->
        </div>
    </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';
const { mapState } = createNamespacedHelpers('tasks');
const orgState = createNamespacedHelpers('organizations');

export default {
    created(){
        
    },

    components : {
		'av-icon' : avIcon
	},

    data(){
        return {
            origin : window.location.origin,
            visible : false
        };
    },

	props : {
		owners : Array,
        taskId : String,
        isSubTasks : Boolean,
        isFollower : {
            type : Boolean,
			'default' : false
        }
	},

    computed: {
        ...mapState([
			'tasks'
		]),
        ...orgState.mapState([
			'currentOrg'
		])
    },

    methods: {

        checkColorluminance(color){
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);

            const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;
            
            if (relativeLuminance > 0.5) {
                return '#000000';
            }
                return '#FFFFFF';
        },

        async removeMember(taskId, orgUserId){

            let selectedTask = this.tasks.find((task) => {
                return task.taskId === taskId;
            });
            if(this.isFollower){
                selectedTask.followers.splice(selectedTask.followers.findIndex(owner => owner.orgUserId === orgUserId), 1);
            }
            else{
                selectedTask.owners.splice(selectedTask.owners.findIndex(owner => owner.orgUserId === orgUserId), 1);
            }

            await this.$store.dispatch("tasks/updateTaskDetails", selectedTask);
        }

    }
};
</script>
<style scoped>
.tool-tip {
	padding: 5px 10px !important;
}
.users-ctr .no-usr-pic{
    height: 2.3rem;
    width: 2.3rem;
    margin-top: 5px;
    color: var(--color-15);
}
.users-ctr .user{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
}
.users-ctr div:not(:first-of-type){
    margin-left: -0.5rem;
}
.memb-pro-div .memb-detail{
    padding: 5px 10px;
}
.memb-pro-div p{
    color: #172b4d;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}
.memb-pro-div p:first-of-type{
    font-size: 18px;
}
.memb-pro-div svg{
    margin-right: 0.5rem;
}
.memb-pro .memb-color {
    height: 8rem;
    /* width: 2rem; */
}
.memb-pro  .user-icon{
    height: 8rem;
    width: 100%;
    object-fit: cover;
}
.owner-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    color: #fff;
    box-shadow: 0 0 1px 1px #fff;
}
</style>